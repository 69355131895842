// React imports
import { useState, useEffect } from 'react'

//App imports
import { Button } from "../button/button";
import { AutoCloser } from "../autoCloser/autoCloser";

const _ = require("lodash");

export function MenuButton({className, tooltip, align, theme, size, icon, text, items, itemsDataSource, showDropDown, disabled, active, showIcon, parentContainer, scrollMenu = false, notLicensed = false}) {
    
    const menuItemHeight = 38.5;

    const [visible, setVisible] = useState(false);
    const [boundItems, setBoundItems] = useState(items ?? []);
    const [selectedIcon, setSelectedIcon] = useState(icon ? icon : boundItems?.filter(x => x.header !== true).length > 0 ? boundItems.filter(x => x.header !== true)[0].icon : null);
    const [top, setTop] = useState(0);

    var classes = ['app-button-menu-container'];

    showIcon = showIcon === undefined ? true : showIcon;

    var menuClasses = [];

    if (align === 'right')
        menuClasses.push('app-button-menu-right');
    else
        menuClasses.push('app-button-menu');

    if (scrollMenu)
        menuClasses.push('app-button-menu-scroll'); 

    var buttonClasses = [];

    if (className)
        buttonClasses.push(className);

    if (visible)
        buttonClasses.push('app-button-menu-open');

    useEffect(()=>
    {
        async function bindItems() {
            if (visible)
            {
                if (_.isFunction(itemsDataSource))
                {
                    setBoundItems(await itemsDataSource());
                    setSelectedIcon(icon ? icon : boundItems?.filter(x => x.header !== true).length > 0 ? boundItems.filter(x => x.header !== true)[0].icon : null);
                } 
                else 
                {
                    setBoundItems(items);
                }
            }
        };

        bindItems();
    }, [visible]);

    const setMenuPosition = (isVisible) =>
    {
        if (!isVisible || !_.isString(parentContainer) || boundItems.length == 0)
            return;

        const appHeight = document.querySelector('#root').offsetHeight;
        const parentRect = document.querySelector(parentContainer).getBoundingClientRect();

        // If scroll menu is enabled, then only 8 menu items are visible.
        // 8 items * menuItemHeight should equal the app-button-menu-scroll max-height value in the scss file
        const availableHeight = appHeight - parentRect.bottom;
        const menuItemCount = scrollMenu && boundItems.length > 8 ? 8 : boundItems.length;
        const menuHeight = menuItemCount * menuItemHeight;

        if (availableHeight < menuHeight)
            setTop(menuItemCount * menuItemHeight * -1);
        else 
            setTop(0);

    };
    
	return <AutoCloser className={classes.join(' ')} open={visible} onClose={() => { setVisible(false); }}>
        <Button {...{disabled, active, theme, size, tooltip, text}} className={buttonClasses.join(' ')} icon={showIcon ? selectedIcon : null} showDropDown={showDropDown === undefined ? true : showDropDown} 
            onClick={() => { setVisible(!visible); setMenuPosition(!visible); }}
            notLicensed={notLicensed} 
        />
        {
			boundItems && visible ? <div className={menuClasses.join(' ')} style={ top !== 0 ? {top: top + 'px'} : {}}>
			{
				boundItems.map((item, i) =>{
                    if (item.header)
                        return <div key={i} className='app-button-menu-title'>
                            {item.text}
                        </div>
                    else
                        return <Button key={i} theme='row' icon={item.icon} text={item.text} image={item.image} disabled={item.disabled} selected={item.selected} onClick={(e) =>{

                            if (item.onClick)
                                item.onClick({e, item});

                            setVisible(false);

                            if (!icon)
                                setSelectedIcon(item.icon);
                        }} />
				})
			}
			</div> : ''
		}
    </AutoCloser>         
}