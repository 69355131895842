// React imports
import { useState, useEffect, useCallback} from 'react';

// App imports
import { icons } from '../../../../base/icon/icon';
import { translate } from '../../../../../utils/translation';
import { Button } from '../../../../base/button/button';
import { TextBox } from '../../../../base/textBox/textBox';
import { TextArea } from '../../../../base/textArea/textArea';
import { successToast, errorToast } from '../../../../base/toast/toast';
import { competitiveInsights as competitiveInsightsModule } from '../../../../../modules/competitiveInsights';
import { legacyEndpoints } from '../../../../../services/legacyEndpoints';
import { helpers } from '../../../../../utils/helpers';
import { LargePanel } from '../../../../base/largePanel/largePanel';
import { CustomChannelSelector } from './customChannelSelector';

const _ = require("lodash");

export function CustomChannelEditor({text, onClose, customChannelId}) {   
    const [loaded, setLoaded] = useState(true);
    const [generating, setGenerating] = useState(false);
    const [customChannel, setCustomChannel] = useState(null);
    const [selectedChannels, setSelectedChannels] = useState([]);
	const [selectedChains, setSelectedChains] = useState([]);

	const setChannels = useCallback(
        (channels)=>{
            const newChannels = _.cloneDeep(channels);
            setCustomChannel(newChannels);
            setSelectedChannels(newChannels.channels);
            setSelectedChains(newChannels.chains);
        },
        [setCustomChannel, setSelectedChannels, setSelectedChains]
    );    

	const load = useCallback(
        ()=>{
            setLoaded(false);
            legacyEndpoints.service({
                name: 'GetCompetitiveInsightsChannelsForUser',
                parameters: {
                    id: customChannelId ?? helpers.emptyGuid()
                },
                success: (result) => {
                    setLoaded(true);
                    setChannels(result);
                }
            });		
        },
        [setChannels, customChannelId]
    );    

    useEffect(()=>{
        load();
    }, [load]);

   const saveCustomChannel = async () => {
        setGenerating(true);
        var success = false;

        const newChannels = _.cloneDeep(customChannel);
        newChannels.reprocess = true;
        newChannels.chains = selectedChains.filter(chain => chain.isSelected);
        newChannels.channels = selectedChannels.filter(channel => channel.isSelected);

        if (selectedChains.filter(chain => chain.isSelected)?.length > 0 || selectedChannels.filter(channel => channel.isSelected)?.length > 0) {
            var response = await competitiveInsightsModule.updateCompetitiveInsightsCustomChannel(newChannels);
        
            if (response === null) {
                successToast(translate('successfully_saved'));
                success = true;  
            }
            else {   
                errorToast(response);
            }
        } else {
            errorToast(translate('channel_or_chain_must_be_selected'));
        }

        setGenerating(false);
        return(success);
   }

   const onSave = async () => {
        var success = await saveCustomChannel();
        if(success)
            onClose();
   }

    return <LargePanel className='app-tools-custom-channels-editor-medium-panel' text={text} loaded={loaded} generating={generating} onClose={onClose}>
            {
                !customChannel ? '' :
                <div>
                    <div className='app-tools-custom-channels-editor-button-container'>
                        <Button theme='secondary' className='app-tools-custom-channels-editor-action-button' size={'small'} tooltip={translate('cancel')} icon={icons.x} onClick={() => {onClose();}}/> 
                        <Button theme='primary' className='app-tools-custom-channels-editor-action-button' size={'small'} tooltip={translate('save')} icon={icons.check} onClick={() => {onSave();}}/>
                    </div>
                    <div className='app-tools-custom-channels-editor-container'>
                        <div className='app-tools-custom-channels-editor-panel-container'>
                            <div className='app-tools-custom-channels-editor-panel-row'>
                                <TextBox className={'app-tools-custom-channels-editor-panel-stretch'}
                                    height='40px' label={translate('name')}
                                    value={customChannel.name}
                                    onChange={(o) => {customChannel.name = o.value; setCustomChannel(_.cloneDeep(customChannel));}} 
                                />
                            </div>
                            <div className='app-tools-custom-channels-editor-panel-row'>
                                <TextArea label={translate('description')} 
                                    className={'app-tools-custom-channels-editor-panel-stretch'}
                                    value={customChannel.description} minHeight={100} valueChangeEvent={'change keyup'} 
                                    onChange={(o) => {customChannel.description = o.value; setCustomChannel(_.cloneDeep(customChannel));}} 
                                />  
                            </div>
                        </div>   
                        <div className='app-competitive-insights-channel-filter'>
                            <CustomChannelSelector 
                                selectedChannels={selectedChannels} 
                                updateSelectedChannels={(items) => { setSelectedChannels(items); }} 
                                selectedChains={selectedChains} 
                                updateSelectedChains={(items) => { setSelectedChains(items); }} 
                                displayShowOnlySelected={true}
                            />
                        </div>		                                        
                    </div>
                </div>            
            }
        </LargePanel> 

}