// App imports
import { Analogs } from '../components/app/analogs/analogs';
import { analogs as analogsHelper } from '../components/app/analogs/analogs';
import { legacyEndpoints } from '../services/legacyEndpoints';
import { workBench } from '../components/base/workBench/workBench';
import { Loader } from '../components/base/loader/loader';
import { translate } from '../utils/translation';

const _ = require("lodash");

export const analogs = {
    generate: async (o) =>{

        const result = workBench.setContent({ 
            title: translate('analogs'),
            content: <Loader />,
            height: '700px',
            maxHeight: '1000px',
            startMinimized: true,
            dragEnabled: false
        });

        if (!result)
            return null;

        var processedResults = await analogs.generateAnalog(o);

        workBench.setContent({ 
            title: `${processedResults.settings.title} ${translate('analogs')}`,
            content: <Analogs 
                data={processedResults.data} 
                columns={processedResults.columns} 
                settings={processedResults.settings}
                filters={processedResults.filters}
            />,
            height: '700px',
            maxHeight: '1000px',
            startMinimized: true,
            preventClose: true,
            onClose: () =>{
                analogsHelper.purgeNotes();
            }
        });
    
        return processedResults;
    },
    generateAnalog: async (o) =>{

        var analogResults = await analogs.getAnalogResults(o);

        var processedResults = analogs.processResultRecords(analogResults.DetailRecords);
        processedResults.filters = analogs.processResultFilters(analogResults.Filters);

        processedResults.settings = {
            templateId: o.templateId,
            customQueryId: o.customQueryId,
            pointId: o.pointId,
            densityClass: o.densityClass,
            densityParameters: o.densityParameters,
            allowManualStore: analogResults.AllowManualStore,
            allowPrimaryFilterUpdate: analogResults.AllowPrimaryFilterUpdate,
            defaultQueryID: analogResults.DefaultQueryID,
            defaultQuerySearch: analogResults.DefaultQuerySearch,
            detailRecords: analogResults.DetailRecords,
            filters: analogResults.Filters,
            id: analogResults.Id,
            isLocked: analogResults.IsLocked,
            isSavedAnalog: analogResults.IsSavedAnalog,
            reportList: analogResults.ReportList,
            subjectSite: analogResults.SubjectSite,
            title: analogResults.Title
        }

        return processedResults;
    },
    renderScore: (o) =>{
        return (
            _.isObject(o.score) ?
                <span style={{ color: `rgb(${o.score.color.R}, ${o.score.color.G}, ${o.score.color.B})` }}><b>{o.score.score}</b></span>
            : _.isString(o.score) ?
                <span>{o.score}</span>
            : '' )
    },
    processResultFilters: (filters) =>{
        var activeFilters = [];

        if (!_.isArray(filters) || filters.length == 0)
            return activeFilters;

        filters[0].PredicateFields.forEach(item => {
            if (item.IsChecked == true) {

                if ((item.Type == "L") || (item.Type == "D")) {
                    var valueList = [];

                    item.ValueList.forEach(value => {
                        if (value.IsChecked == true)
                            valueList.push(value.Value);
                    });
                
                    activeFilters.push({
                        ID: item.ID,
                        LowValue: 0,
                        HighValue: 0,
                        Value: null,
                        ValueList: valueList
                    });
                }
                else if (item.Type == "C") {
                    activeFilters.push({
                        ID: item.ID,
                        LowValue: item.LowValue,
                        HighValue: item.HighValue,
                        Value: null,
                        ValueList: null
                    });
                }
                else {
                    activeFilters.push({
                        ID: item.ID,
                        LowValue: 0,
                        HighValue: 0,
                        Value: item.Value,
                        ValueList: null
                    });
                }           
            }
        });

        return activeFilters;
    },
    processResultRecords: (detailRecords) =>{

        var detailData = { 
            data: [], 
            columns: [
                { name: 'key', caption: '', visible: false },
                { name: 'metaData', caption: '', visible: false },
                { name: 'score', caption: translate('score'), fixed: true, fixedPosition: "left", alignment: "center", 
                    onRender: (o) => { 
                        return analogs.renderScore(o); 
                    },
                    calculateSortValue: (o) => {
                        return  _.isObject(o.score) ? o.score.score : o.score;
                    }
                }
            ],
            settings: {},
            filters: []
        };

        detailRecords.forEach((record, i) => {
            var detailRecord = { 
                key: record.ID,
                metaData: {
                    applyCustomActions: record.ApplyCustomActions,
                    drawCosmeticPoint: record.DrawCosmeticPoint,
                    isSubjectSite: i === 0,
                    isChecked: record.IsChecked,
                    isManualEntry: record.IsManualEntry,
                    reasonForManualEntry: record.ReasonForManualEntry,
                    reasonForUnchecked: record.ReasonForUnchecked,
                    location: _.isObject(record.Location) ? {lat: record.Location.Lat, lon: record.Location.Lon} : null
                },
                score: _.isObject(record.Score) ? {score: record.Score.Score, color: record.Score.Color} : '-'
            };

            if (record.ApplyCustomActions) {
                // TBD: custom actions were implemented in KLI 1.0 for a specific client - need to determine if they are needed in KLI 2.0
            }

            record.Columns.forEach(column => {
                var columnValue = column.Value;

                if (columnValue.IsVisible == true) {
                    detailRecord[column.Key] = columnValue;

                    // To lock in the subject site as the first row when sorting, set the distance raw value to an empty string
                    // (like the other columns with no value). Then the sorting logic in calculateSortValue below will work.
                    if (record.ID.length == 0 && column.Key.toLowerCase() === 'distance') {
                        columnValue.RawValue = '';
                    }

                    if (i === 0)
                        detailData.columns.push({ 
                            name: column.Key, 
                            caption: columnValue.Label, 
                            onRender: (o) => { 
                                return o[column.Key].Value; 
                            },
                            calculateSortValue: (o) => {
                                // Sort on raw values and convert numbers. To lock in the subject site as the first row, 
                                // return a dash for numbers and zero for strings (if we knew the sort order we could set
                                // high/low values, but currently not able to determine sort order from the data grid)
                                if (!_.isNaN(_.toNumber(o[column.Key].RawValue)))
                                    return o.metaData.isSubjectSite ? '-' : _.toNumber(o[column.Key].RawValue);
                                else
                                    return o.metaData.isSubjectSite ? 0 : o[column.Key].RawValue;
                            }
                        });
                }
            });
          
            detailData.data.push(detailRecord);
        });

        return detailData;
    },
    getAvailableTemplates: async function (o) {
        return await legacyEndpoints.service({
			name:'GetAvailableTemplates',
			parameters: {
				aQueryID: o.customQueryId,
				aPointID:  o.pointId
			}
		});
    },
    getSavedAnalogs: async function (o) {
        return await legacyEndpoints.service({
            name:'GetSavedAnalogs',
            parameters: {
                QueryId: o.customQueryId,
                PointId:  o.pointId,
                aTemplateID: o.templateId
            }
        });
    },
    getAnalogParameters: async function (o) {
        var results = await legacyEndpoints.service({
            name:'GetAnalogParameters',
            parameters: {
                SavedAnalogId: o.savedAnalogId
            }
        })

        // TBD: the service layer seems to be returning duplicate values in the ValueList arrays,
        // so we are removing duplicates here. Need to determine if this is a bug in the service layer
        results.Filters.forEach(filter => { 
            if (_.isArray(filter.ValueList) && filter.ValueList.length > 0) {
                filter.ValueList = _.uniq(filter.ValueList);
            }
        });

        return results;
    },
    getAnalogResults: async function (o) {

        if (o.locked) {
            return await legacyEndpoints.service({
                name: 'GetLockedAnalog',
                parameters: {
                    SavedAnalogId: o.savedAnalogId
                }
            });
        }
        else if (o.savedAnalogId != null) {

            var results;
            if (o.subjectSite != null) {
                results = await legacyEndpoints.service({
                    name: 'GetSavedAnalog',
                    parameters: {
                        aTemplateID: o.templateId,
                        aFilters: o.filters,
                        aForcedStores: o.forcedStores,
                        SavedAnalogId: o.savedAnalogId,
                        aSubjectSite: o.subjectSite
                    }
                });
            }
            else {
                var analogParameters = await analogs.getAnalogParameters({ savedAnalogId: o.savedAnalogId });
                o.filters = analogParameters.Filters;
                o.forcedStores = analogParameters.ManualStores;

                results = await legacyEndpoints.service({
                    name: 'GetSavedAnalogWithoutSubjectSite',
                    parameters: {
                        aTemplateID: o.templateId,
                        aQueryID: o.customQueryId,
                        aPointID: o.pointId,
                        aFilters: o.filters,
                        aForcedStores: o.forcedStores,
                        aSavedAnalogId: o.savedAnalogId
                    }
                });

                analogParameters.Stores.forEach(store => {
                    var detail = results.DetailRecords.find(o => o.ID === store.Id);
                    if (_.isObject(detail)) {
                        detail.IsChecked = false;
                        detail.ReasonForUnchecked = store.Reason;
                    }
                });
    
                results.Filters[0].PredicateFields.forEach(item => {
                    item.IsChecked = false;
                });
    
                o.filters.forEach(filter => {
                    var field = results.Filters[0].PredicateFields.find(o => o.ID === filter.ID);
                    if (_.isObject(field)) {
                        field.HighValue = filter.HighValue;
                        field.LowValue = filter.LowValue;
                        field.Value = filter.Value;
                        field.IsChecked = true;
    
                        if (filter.ValueList != null) {
                            field.ValueList.forEach(item => {
                                if (_.indexOf(filter.ValueList, item.Value) != -1)
                                    item.IsChecked = true;
                                else
                                    item.IsChecked = false;
                            });
                        }
                    }
                });
            }

            return results;
        }
        else {
            if (o.subjectSite != null) {
                return await legacyEndpoints.service({
                    name: 'GenerateAnalogWithSubjectSite',
                    parameters: {
                        aTemplateID: o.templateId,
                        aFilters: o.filters == null ? [] : o.filters,
                        aForcedStores: o.forcedStores == null ? [] : o.forcedStores,
                        aSubjectSite: o.subjectSite
                    }
                });
            }
            else {
                return await legacyEndpoints.service({
                    name: 'GenerateAnalog',
                    parameters: {
                        QueryId: o.customQueryId,
                        PointId: o.pointId,
                        TemplateId: o.templateId,
                        Filters: o.filters == null ? [] : o.filters,
                        ForcedStores: o.forcedStores == null ? [] : o.forcedStores,
                        DensityClass: o.densityClass == null ? null : o.densityClass,
                        DensityParameters: o.densityParameters == null ? [] : o.densityParameters
                    }
                });
            }
        }
    },
    saveAnalog: async (o) =>{
        return await legacyEndpoints.service({
            name: 'SaveAnalog',
            parameters: {
                aID: o.id,
                aTemplateID: o.templateId,
                aFilters: o.filters,
                aForcedStores: o.forcedStores,
                aSubjectSite: o.subjectSite,
                aTAEncodedString: o.taEncodedString,
                aStores: o.stores,
                aReportList: o.reportList,
                aLock: o.lock
            }
        });
    },
    getAnalogNotes: async function (o) {
        if (o.pointId != null) {
            return await legacyEndpoints.service({
                name: 'GetAnalogStoreNotes',
                parameters: {
                    SavedAnalogId: o.savedAnalogId,
                    PointId: o.pointId
                }
            });
        }

        return await legacyEndpoints.service({
            name: 'GetAnalogNotes',
            parameters: {
                SavedAnalogId: o.savedAnalogId
            }
        });
    },
    addAnalogNote: async function (o) {
        if (o.pointId != null) {
            return await legacyEndpoints.service({
                name: 'AddAnalogStoreNote',
                parameters: {
                    SavedAnalogId: o.savedAnalogId,
                    PointId: o.pointId,
                    Note: o.note
                }
            });
        }

        return await legacyEndpoints.service({
            name: 'AddAnalogNote',
            parameters: {
                SavedAnalogId: o.savedAnalogId,
                Note: o.note
            }
        });
    },
    updateAnalogNote: async function (o) {
        if (o.pointId != null) {
            return await legacyEndpoints.service({
                name: 'UpdateAnalogStoreNote',
                parameters: {
                    NoteId: o.noteId,
                    Note: o.note
                }
            });
        }
        return await legacyEndpoints.service({
            name: 'UpdateAnalogNote',
            parameters: {
                NoteId: o.noteId,
                Note: o.note
            }
        });
    },
    deleteAnalogNote: async function (o) {
        if (o.pointId != null) {
            return await legacyEndpoints.service({
                name: 'DeleteAnalogStoreNote',
                parameters: {
                    NoteId: o.noteId
                }
            });
        }

        return await legacyEndpoints.service({
            name: 'DeleteAnalogNote',
            parameters: {
                NoteId: o.noteId
            }
        });
    },
    purgeAnalogNotes: async (o) =>{
        if (!o.isSavedAnalog && o.savedAnalogId != null)
            return await legacyEndpoints.service({
                name: 'PurgeAnalogNotes',
                parameters: {
                    SavedAnalogId: o.savedAnalogId
                }
            });
    },
    createReportOutput: (o) =>{
        var reportOutput = {
            evaluatedAnalogs: [],
            filterCriteria: []
        };

        o.detailRecords.forEach(record => {
            var clone = _.clone(record);

            var store = o.removedStores.find(o => o.Id === clone.ID);
            if (_.isObject(store)) {
                clone.IsChecked = false;
                clone.ReasonForUnchecked = store.Reason;
                reportOutput.evaluatedAnalogs.push(clone);
            }
            else if (clone.IsManualEntry == true) {
                store = o.forcedStores.find(o => o.Id === clone.ID);
                if (_.isObject(store)) {
                    clone.IsChecked = true;
                    clone.ReasonForManualEntry = store.Reason;
                    reportOutput.evaluatedAnalogs.push(clone);
                }
            }
            else
                reportOutput.evaluatedAnalogs.push(clone);

        });

        if (_.isArray(o.filters) && o.filters.length > 0) {

            var variableFields = o.filters[0].PredicateFields;

            if (o.activeFilters.length > 0) {
                variableFields.forEach(field => {
                    field.IsChecked = false;
                });

                o.activeFilters.forEach(filter => {
                    var field = variableFields.find(o => o.ID === filter.ID);
                    if (_.isObject(field)) {
                        field.currentMax = filter.HighValue;
                        field.currentMin = filter.LowValue;
                        field.currentValue = filter.Value;
                        field.IsChecked = true;
                    }

                    if (filter.ValueList != null) {
                        field.ValueList.forEach(item => {
                            if (_.indexOf(filter.ValueList, item.Value) != -1)
                                item.IsChecked = true;
                            else
                                item.IsChecked = false;
                        });
                    }
                });
            }

            variableFields.forEach(variable => {

                const filter = o.activeFilters.find(o => o.ID === variable.ID);
                if (_.isObject(filter)) {

                    var minVal = null;
                    var maxVal = null;

                    switch (variable.Type)
                    {
                        case "C":
                            minVal = variable.currentMin;
                            maxVal = variable.currentMax;

                            break;
                        case "L":
                        case "D":
                            var myNewArray = [];

                            variable.ValueList.forEach(item =>{
                                if (item.IsChecked == true)
                                    myNewArray.push(item);
                            });

                            if (myNewArray.length == 1)
                                minVal = maxVal = myNewArray[0].Value;
                            else if (myNewArray.length > 1) {
                                minVal = myNewArray[0].Value;
                                maxVal = myNewArray.pop().Value;
                            } else
                                minVal = maxVal = 0;

                            break;
                        case "T":
                            minVal = 0;
                            maxVal = variable.Value;

                            break;
                        default:
                            break;
                    }

                    reportOutput.filterCriteria.push({
                        Label: variable.Label,
                        MinValue: minVal,
                        MaxValue: maxVal,
                        Type: variable.Format
                    });
                }
            });
        }

        return reportOutput;
    },
    generateAnalogReport: async (o) =>{
        if (o.format == null)
            return await legacyEndpoints.service({
                name: 'GenerateAnalogReport',
                parameters: {
                    TemplateId: o.templateId,
                    AnalogId: o.id,
                    EvaluatedStores: o.reportOutput.evaluatedAnalogs,
                    FilterCriteria: o.reportOutput.filterCriteria,
                    SubjectSite: o.subjectSite
                }
            });
        else
            return await legacyEndpoints.service({
                name: 'GenerateAnalogReportWithFormat',
                parameters: {
                    TemplateId: o.templateId,
                    AnalogId: o.id,
                    EvaluatedStores: o.reportOutput.evaluatedAnalogs,
                    FilterCriteria: o.reportOutput.filterCriteria,
                    SubjectSite: o.subjectSite,
                    Format: o.format
                }
            });
    },
    getRawAndWeightedColumnDistribution: async (o) =>{
        return await legacyEndpoints.service({
            name: 'GetRawAndWeightedColumnDistribution',
            parameters: {
                TemplateId: o.templateId,
                FieldId: o.id,
                Latitude: o.lat,
                Longitude: o.lon,
                FieldList: o.valueList
            }
        });
    }
};