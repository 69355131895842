import { legacyEndpoints } from '../services/legacyEndpoints';

const _ = require("lodash");

export const photos = {    
    get: async function(o) {
		var data = await legacyEndpoints.service({
			name: 'GetPhotoIDsForPoint',  //todo GetPhotoThumbnail
            parameters: {
                aServiceID: o.aServiceID,
                sPointID:  o.sPointID
            }});

        return data.map((x) => { 
            return {
                id: x.ID, 
                userName: x.UserName,
                userId: x.UserId,
                original: legacyEndpoints.handlers.getPhotoUrl({ id: x.ID, isUserPhoto: false }) +'&dummy=' + crypto.randomUUID(), //todo since url dose not change, force
                thumbnail: legacyEndpoints.handlers.getPhotoUrl({ id: x.ID, isUserPhoto: false }) +'&dummy=' + crypto.randomUUID(),
                date: x.Date,
                photoDescription: x.Description,
                title: x.Title,
                guid: x.GUID
            }
        })     	         
    },
    save: async function(o) {
		var data = legacyEndpoints.service({
			name: 'EditPhotoInfoWithAdditionalData', 
            parameters: {
                aID: o.id,
                aDescription: o.photoDescription,
                aDate: o.date,
                aTitle: o.title                
            }});    

        return data;
    }, 
    updatePhoto: async function(o) {
		var data = legacyEndpoints.service({
			name: 'UpdatePhotoFromBase64', 
            parameters: {
                aPhotoId: o.photoId,           
                aContentType: o.contentType,
                aImage: o.image,
            }});    

        return data;
    },          
    updateQueueFromTempDirectory: async function(o) {
		var data = legacyEndpoints.service({
			name: 'UpdateQueueFromTempDirectory', 
            parameters: {
                uploadQueueId: o.uploadQueueId,
                fileId: o.fileId,
                tempFileId: o.tempFileId, // from updateQueuePhoto post
                contentType: o.contentType
            }});              

        return data;
    },   
    delete: async function(o) {
		var data = legacyEndpoints.service({
			name: 'DeletePhoto', 
            parameters: {
                aID: o.id               
            }});    
            
        return data;
    },       
    publish: async function(o) {
		var data = await legacyEndpoints.service({
			name: 'PublishQueue', 
            parameters: {
                uploadQueueId: o.uploadQueueId,
                data: o.data,
                dataSourceId: null,
                pointId: null,
                tempPointId: null,                           
            }});    

        return data;
    },      
    clearUploadQueue: async function(o) {
		var data = legacyEndpoints.service({
			name: 'ClearUploadQueue', 
            parameters: {
                uploadQueueId: o.uploadQueueId                          
            }});    

        return data;
    },         
    addPhotoToQueueFromTempDirectory: async function(o) {
        var queueId = await legacyEndpoints.service({
            name: 'AddPhotoToQueueFromTempDirectory', 
                  parameters: {                      
                      uploadQueueId: o.uploadQueueId,
                      serviceId: o.serviceId,
                      pointId: o.pointId,
                      fileId: o.fileId,
                      contentType: o.contentType,
                      sequence: o.sequence                             
                  }});  

        return queueId;
    },        
    addMultimediaFileToQueueFromTempDirectory: async function(o) {
        var queueId = await legacyEndpoints.service({
            name: 'AddMultimediaFileToQueueFromTempDirectory', 
                  parameters: {    
                        uploadQueueId: o.uploadQueueId,                          
                        originalFileId: o.originalFileId, //?? null
                        fileId: o.fileId,
                        sourceId: o.sourceId,
                        dataSourceId: o.dataSourceId,
                        fileName: o.fileName,
                        managerType: o.managerType
                  }});  

        return queueId;
    },   
    addFileToQueueFromTempDirectory: async function(o) {
        var queueId = await legacyEndpoints.service({
            name: 'AddFileToQueueFromTempDirectory', 
                  parameters: {    
                    uploadQueueId: o.uploadQueueId,
                    fileId: o.fileId,
                    contentType: 'application/rss+xml',
                    fileName: o.fileName
                  }});  

        return queueId;
    },      

};