// React imports
import { useState, useEffect, useCallback } from 'react';

// App imports
import { DropDown } from '../../base/dropDown/dropDown';
import { Button } from '../../base/button/button'
import { DataGrid } from '../../base/dataGrid/dataGrid';
import { icons } from '../../base/icon/icon';
import { translate } from '../../../utils/translation';
import { helpers } from '../../../utils/helpers';
import { legacyEndpoints } from '../../../services/legacyEndpoints';
import { bulkInfo } from '../../../modules/bulkInfo';
import { map } from '../map/map';

// 3rd party imports
import SelectBox from 'devextreme-react/select-box';

const _ = require("lodash");

export function BulkEdit({ customQueryId, form, columns, editableLayers }) {

    const [infoColumns, setInfoColumns] = useState([]);
    const [selectedKey, setSelectedKey] = useState(2);

    const createKeyDropDown = useCallback(
        (options) =>{

            var keyOptions = [
                { text: translate('point_id'), id: 0},
                { text: translate('map_label'), id: 1},
                { text: translate('infobox_title'), id: 2}
            ]

            return (
                _.isObject(options) ?
                    <DropDown items={keyOptions} display='text' valueProperty='id' selected={selectedKey} onChange={(o) => { 
                        setSelectedKey(o.value); 
                    }} />
                : '' )
        },
        [selectedKey]
    );

    const createHeaderFilter = useCallback(
        (options) =>{
            options.dataSource.load = async (o) => {

                // TBD: in KLI 1.0 and 2.0 the filters seem to have no effect on the service call results ?!?
                var filters = helpers.getFilterBuilder({ filter: options.dataSource.filter });

                var points = [];
                const layer = editableLayers.find(layer => layer.customQueryId === customQueryId);
                if (_.isObject(layer))
                    points = layer.points.map(p => p.id)

                var results = await legacyEndpoints.service({
                    name: "GetBulkEditFormColumnFilter",
                    parameters: {
                        customQueryId: customQueryId,
                        pointIds: points,
                        fieldId: options.fieldId,
                        skip: _.isNumber(o.skip) ? o.skip : 0,
                        take: _.isNumber(o.take) ? o.take : 0,
                        searchValue: o.searchValue,
                        searchOperation: helpers.getFilterOperator(o.searchOperation),
                        selectedKey: selectedKey,
                        filters: filters
                    }
                });

                return {
                    data: results.data,
                    totalCount: results.total
                };
            }
        },
        [customQueryId, selectedKey]
    );

    const createZoomButton = useCallback(
        (o) =>{
            return (
                _.isObject(o.zoom) ?
                    <Button className={'app-bulk-info-zoom'} theme='simple' icon={icons.magnifyingGlass} tooltip={translate('zoom')} 
                        onClick={() => { 
                            map.locate({ location: o.zoom });
                        }} 
                    />
                : '' )
        },
        []
    );
    
    useEffect(() => {

        if (!_.isArray(columns) || columns.length === 0)
            return;

        var newColumns = [...columns];

        var addColumnHandlers = (column) =>{
            // For any columns that have lookup values, don't create a custom filter header.
            // For some reason it would only display (Blanks) and not the returned data values?
            if (column.allowFiltering && !column.lookup)
                column.headerFilterDataSource = (o) => { return createHeaderFilter({ ...o, fieldId: column.name }); };

            if (_.isArray(column.columns) && column.columns.length > 0)
                column.columns.forEach(col => {
                    addColumnHandlers(col);
                });
        };

        newColumns.forEach(col => {
            addColumnHandlers(col);
        });

        newColumns.unshift({
            name: helpers.emptyGuid(),
            caption: "",
            fixed: true,
            fixedPosition: "left",
            allowFiltering: true,
            allowSorting: false,    // TBD: should allow sorting, but data grid sorts when clicking on dropdown
            allowEditing: false,
            width: 180,
            onHeaderRender: (o) => { return createKeyDropDown(o); },
            headerFilterDataSource: (o) => { return createHeaderFilter({ ...o, fieldId: helpers.emptyGuid() }); }
        });

        newColumns.unshift({
            name: "zoom",
            caption: translate('zoom'),
            fixed: true,
            fixedPosition: "left",
            allowFiltering: false,
            allowSorting: false,
            allowEditing: false,
            width: 60,
            onRender: (o) => { return createZoomButton(o); }
        });

        newColumns.unshift({
            name: "pointId",
            caption: "Point Id",
            fixed: true,
            fixedPosition: "left",
            allowFiltering: false,
            allowSorting: false,
            allowEditing: false,
            visible: false
        });

        newColumns.unshift({
            name: "formId",
            caption: "Form Id",
            fixed: true,
            fixedPosition: "left",
            allowFiltering: false,
            allowSorting: false,
            allowEditing: false,
            visible: false
        });
    
        setInfoColumns(newColumns);
    
    }, [columns, createKeyDropDown, createHeaderFilter, createZoomButton]);

    const getKeyValue = (o) => {
        switch (selectedKey) {
            case 0:
                return o.row.id;
            case 1:
                return o.row.mapLabel;
            default:
            case 2:
                return o.row.infoboxTitle;
        }
    };

    const getChangedForms = (changes) =>{

        var newForms = [];

        changes.forEach(row => {
            for (const column in row.data) {

                var field = _.find(form, function (f) { return f.id === column; });
                if (!_.isObject(field))
                    return;

                const sectionId = field.sectionId;
                const componentId = field.componentId;
                const columnName = field.column;
                const source = field.source;

                var updateForm = _.find(newForms, function (f) { return f.PointId === row.key.pointId; });
                if (!_.isObject(updateForm)) {
                    updateForm = {
                        Id: row.key.formId,
                        PointId: row.key.pointId,
                        UpdateSections: []
                    };
                    newForms.push(updateForm);
                }

                var updateSection = _.find(updateForm.UpdateSections, function (s) { return s.Id === sectionId; });
                if (!_.isObject(updateSection)) {
                    updateSection = {
                        Id: sectionId,
                        UpdateFields: []
                    };
                    updateForm.UpdateSections.push(updateSection);
                }

                var updateField = _.find(updateSection.UpdateFields, function (f) { return f.Id === componentId && f.Column === columnName; });
                if (!_.isObject(updateField)) {
                    updateField = {
                        Id: componentId,
                        Column: columnName,
                        Value: row.data[column],
                        Source: source
                    };
                    updateSection.UpdateFields.push(updateField);
                } 
                else {
                    updateField.Value = row.data[column];
                }

            }
        });

        return newForms;
    };

    const processBatchSaving = async (changes, component) => {

        const bulkEditForms = getChangedForms(changes);

        await bulkInfo.submitBulkEditForm({ customQueryId: customQueryId, bulkEditForms: bulkEditForms });

        await component.refresh(true);
        component.cancelEditData();
    };

    const replaceFilterListValues = (filters) => {
        // For any columns that have lookup values, the filter value will contain the lookup key by default,
        // but the service call expects the filter to have the lookup value - so replace it
        filters.forEach(filter => {
            if (filter.field && filter.value) {
                const field = _.find(form, function (f) { return f.id === filter.field; });
                if (_.isObject(field) && _.isArray(field.listValues)) {
                    const value = _.find(field.listValues, function (l) { return l.Key === filter.value; });
                    if (_.isObject(value)) {
                        filter.value = value.Value;
                    }
                }
            }

            if (_.isArray(filter.filters)) {
                replaceFilterListValues(filter.filters);
            }
        });
    };

    const handleEditableLayerChanged = (e) => {

        const layer = editableLayers.find(layer => layer.customQueryId === e.value);
        if (!_.isObject(layer))
            return;

        bulkInfo.populateBulkEdit({ ...layer, editableLayers: editableLayers });

    };

    const onToolbarPreparing = (e) => {
        if (editableLayers.length > 0)
            e.toolbarOptions.items.unshift({
                location: 'before',
                widget: 'dxSelectBox',
                options: {
                    items: editableLayers,
                    displayExpr: 'text',
                    valueExpr: 'customQueryId',
                    value: customQueryId,
                    onValueChanged: handleEditableLayerChanged,
                    placeholder: 'Select an option',
                    inputAttr: { 'aria-label': 'Select an option' }
                }
            });
    };

    return  <div className='app-bulk-info'>
        { !_.isArray(infoColumns) || infoColumns.length === 0 ? translate('invalid_permission_bulk_edit_desc') :
            <DataGrid 
                key='pointId'
                columns={infoColumns}
                remoteOperations={ {groupPaging: true, remoteOperations: true} }
                showHeaderFilter={true}
                showScrollbar='onHover'     // needed to be able to scroll horizontally
                showFilterRow={true}
                editing={{ mode: "batch", allowUpdating: true, selectTextOnEditStart: true, startEditAction: "click" }}
                onToolbarPreparing={onToolbarPreparing}
                onSaving={(e)=>{
                    e.cancel = true;
                    if (e.changes.length > 0) {
                        e.promise = processBatchSaving(e.changes, e.component);
                    }
                }}
                onLoad={async (o)=>{            
                    
                    var filters = helpers.getFilterBuilder({ filter: o.filter });
                    replaceFilterListValues(filters);

                    var sorts = [];
                    if (_.isArray(o.sort))
                        o.sort.forEach(sort => { 
                            sorts.push({ fieldId: o.sort[0].selector, sortOrder: sort.desc ? 1 : 0 });
                        });

                    var points = [];
                    const layer = editableLayers.find(layer => layer.customQueryId === customQueryId);
                    if (_.isObject(layer))
                        points = layer.points.map(p => p.id)
                                    
                    var data = await legacyEndpoints.service({
                        name: 'GetFilteredBulkEditFormData',
                        parameters: {
                            latitude: map.center.lat,   // TBD: use sourcePoint lat/lon if it exists
                            longitude: map.center.lon,
                            customQueryId: customQueryId,
                            pointIds: points,
                            skip: o.skip,
                            take: o.take,
                            selectedKey: selectedKey,
                            filters: filters,
                            sorts: sorts
                        }
                    });

                    var dataSource = {
                        results: []
                    };
            
                    data.results.forEach(row => {
                        var cols = {
                            formId: row.formId,
                            pointId: row.id,
                            zoom: _.isObject(row.location) ? {lat: row.location.lat, lon: row.location.lon} : null
                        };

                        cols[helpers.emptyGuid()] = getKeyValue({ row: row });

                        row.data.forEach(col =>{
                            cols[col.columnId] = col.value;
                        });

                        dataSource.results.push(cols);
                    });

                    return {
                        data: dataSource.results,
                        totalCount: data.total
                    };
                }}
            />
        }
    </div>
}
