// React imports
import { useEffect, useState } from 'react';

// App imports
import { TextBox } from '../../../base/textBox/textBox';
import { TextArea } from '../../../base/textArea/textArea';
import { Button } from '../../../base/button/button';
import { icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { TagBoxDropDown } from '../../../base/dropDown/tagBoxDropDown';
import smartTags from '../../../../modules/smartTags';
import { constants } from '../../../../utils/constants';
import { Loader } from '../../../base/loader/loader';
import { Hideable } from '../../../base/hideable/hideable';

const _ = require("lodash");

export function EditPhotoDetails({entity,photo,onClear,onSave}){
    const [editPhoto, setEditPhoto] = useState({id: '', photoDescription: '', title: ''});
    const [loaded, setLoaded] = useState(false);
    const [areTagsUpdated, setAreTagsUpdated] = useState(false);
    const [placeholders, setPlaceholders] = useState([]);
    const [hashtags, setHashtags] = useState([]);
    const [selectedPlaceholders, setSelectedPlaceholders] = useState([]);
    const [selectedHashtags, setSelectedHashtags] = useState([]);  
    
    useEffect(() => {
        const fetchData = async () => {
            setLoaded(false);
            var tags = await smartTags.GetAvailableTags({
                id: photo.guid, 
                parentObjectId: entity.id, 
                sourceObjectId: entity.layer.metaData.serviceAttributes.DataSourceId, 
                sourceObjectType: constants.tagSourceObjectType.dataSource, 
                type: constants.tagObjectType.photo
            });

            setPlaceholders(tags.availablePlaceholders);
            setSelectedPlaceholders(tags.assignedPlaceholders);
            setHashtags(tags.availableHashtags);
            setSelectedHashtags(tags.assignedHashtags);
            setLoaded(true);
        };

        setEditPhoto({
            id: photo?.id,
            title: photo?.title,
            photoDescription: photo?.photoDescription,
        });
        if (photo && entity.layer.metaData.serviceAttributes.DataSourceId)        
            fetchData();       
        else
            setLoaded(true);
        
    }, [entity, photo]);
    
    
    const saveTags = async () => {
        if (areTagsUpdated) {
            await smartTags.UpdateTags({
                id: photo.guid, 
                parentObjectId: entity.id, 
                sourceObjectId: entity.layer.metaData.serviceAttributes.DataSourceId, 
                sourceObjectType: constants.tagSourceObjectType.dataSource, 
                type: constants.tagObjectType.photo,
                tags: [...selectedPlaceholders, ...selectedHashtags]
            });
            setAreTagsUpdated(false);
        };
    };

    function handleChange(e) {
        setEditPhoto((editPhoto) => ({ ...editPhoto, [e.name]: e.value }));
    }    

	return <>
        <Loader loaded={loaded} theme={'generate'}/>
        <Hideable hide={!loaded}>
            <div id='app-photo-edit-details-id' className='app-photos-edit-details'>
                <TextBox className='app-photos-details-textbox-title' height='47px' label={translate('title')} name='title' value={editPhoto?.title} onChange={ handleChange }  />
                <TextArea className='app-photos-details-textbox-description' minHeight={141} label={translate('description')} name='photoDescription' value={editPhoto?.photoDescription} onChange={ handleChange } />            
                <TagBoxDropDown dropDownContainer={'#app-photo-edit-details-id'} label={translate('hashtag')} values={selectedHashtags} items={hashtags} keyProperty='id' valueProperty='name' onChange={(o) => {setSelectedHashtags(o); setAreTagsUpdated(true)}}/>
                <TagBoxDropDown dropDownContainer={'#app-photo-edit-details-id'} label={translate('placeholder')} values={selectedPlaceholders} items={placeholders} keyProperty='id' valueProperty='name' onChange={(o) => {setSelectedPlaceholders(o); setAreTagsUpdated(true)}}/>          

                <div className='app-photos-edit-details-actions-section'>
                    <Button className='app-photos-edit-details-actions-section-icon' theme='tertiary' tooltip={translate('clear')} size='medium' icon={icons.arrowTurnDownLeft} onClick={()=>{onClear() }} />	                    
                    <Button className='app-photos-edit-details-actions-section-icon' theme='tertiary' tooltip={translate('save')} size='medium'icon={icons.save} onClick={()=>{saveTags(); onSave(editPhoto); }} />	
                </div>
            </div>
        </Hideable>      
    </>
}