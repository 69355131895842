import { useEffect, useState } from 'react';

// App imports
import { TextBox } from '../../../base/textBox/textBox';
import { Button } from '../../../base/button/button';
import { ConfirmButton } from '../../../base/confirmButton/confirmButton';
import { icons } from '../../../base/icon/icon';
import { Icon } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { legacyEndpoints } from '../../../../services/legacyEndpoints';
import { TagBoxDropDown } from '../../../base/dropDown/tagBoxDropDown';
import { constants } from '../../../../utils/constants';
import smartTags from '../../../../modules/smartTags';

const _ = require("lodash")

export function EditPhotoUpload({entity, photo,onDelete,onPhotoEdit,isPhoto, onHandleChange, handleTagChange}){
    const [placeholders, setPlaceholders] = useState([]);
    const [hashtags, setHashtags] = useState([]);
    const [selectedPlaceholders, setSelectedPlaceholders] = useState([]);
    const [selectedHashtags, setSelectedHashtags] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            var tags = await smartTags.GetAvailableTags({
                id: photo.id, 
                parentObjectId: entity.id, 
                sourceObjectId: entity.layer.metaData.serviceAttributes.DataSourceId, 
                sourceObjectType: constants.tagSourceObjectType.dataSource, 
                type: isPhoto ? constants.tagObjectType.photo : constants.tagObjectType.multimedia
            });

  
            setPlaceholders(tags.availablePlaceholders);
            setSelectedPlaceholders(tags.assignedPlaceholders);
            setHashtags(tags.availableHashtags);
            setSelectedHashtags(tags.assignedHashtags);
        };

        if (photo && entity && entity.layer.metaData.serviceAttributes.DataSourceId) {         
            fetchData();       
        }
    }, [entity, photo.id]);

    useEffect(() => {
        if(_.isFunction(handleTagChange))
            handleTagChange(photo.id, [...selectedPlaceholders, ...selectedHashtags] );
    }, [selectedPlaceholders, selectedHashtags]);

    function onInputChange (e) {
        onHandleChange(photo.id, e.name, e.value );
    }           

	return (
            <table>
                <tbody>
                    <tr>
                        <td className='app-photos-edit-photo-upload-photo'>                          
                        { isPhoto ? <img className='app-photos-edit-photo-upload-photo-img' src={legacyEndpoints.handlers.getUploadUrl({ fileId: photo.id, task: 'get' }) +'&dummy=' + crypto.randomUUID()} alt={translate('store_photo')} /> : 
                            <Icon className={'app-photos-edit-photo-upload-edit-details-file'} icon={icons.file} />
                        }
                        </td>
                        <td>
                            <TextBox className='app-photos-edit-photo-upload-edit-details-textbox-title' label={translate('title')} name='title' value={photo.title} onChange={ onInputChange  }  />                              
                            <TextBox className='app-photos-edit-photo-upload-edit-details-textbox-description' label={translate('description')} name='description' value={photo.description} onChange={ onInputChange  } />  
                            {entity ? <TagBoxDropDown label={translate('hashtag')} values={selectedHashtags} items={hashtags} keyProperty='id' valueProperty='name' onChange={(o) => {setSelectedHashtags(o)}}/> : ''}
                            {entity ? <TagBoxDropDown label={translate('placeholder')} values={selectedPlaceholders} items={placeholders} keyProperty='id' valueProperty='name' onChange={(o) => {setSelectedPlaceholders(o)}}/> : ''}
                            
                        </td>
                        <td className='app-photos-edit-photo-upload-edit-details-actions'>
                            { isPhoto ? <Button className='app-photos-edit-photo-upload-edit-details-actions-button' theme='simple' tooltip={translate('edit')} icon={icons.paintBrushFine} onClick={()=>{ onPhotoEdit(photo.id); }} /> : '' }                 
                            <ConfirmButton className='app-photos-edit-photo-upload-edit-details-actions-button' align='right' theme='simple' icon={icons.trash} tooltip={translate('delete')} clearText={translate('delete')} onConfirm={() => { onDelete(photo.id) }} />                            
                        </td>
                    </tr>
                </tbody>
            </table>
    );
}
