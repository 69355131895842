import React, { useEffect, useState} from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import { helpers } from '../../../utils/helpers';
import { translate } from '../../../utils/translation';

export default function SmartTextBox({className, label, value, isRequired, onChange, placeholder, marker = '#', smartTags, valueExpr = 'id', displayExpr = 'display'}) {
    const [dataSource, setDataSource] = useState([]);
    var classes = ['app-smart-text-box'];

    if (className)
        classes.push(className); 

    useEffect(() => {
        var data = [];
        if (smartTags && smartTags.length > 0) {
            smartTags.forEach(tag => {
                data.push({id: tag[valueExpr], display: tag[displayExpr]});
            });
        }
        setDataSource(data);
    }, [smartTags]);

    const renderSuggestion = (_suggestion, _search, highlightedDisplay, _index, focused) => (
        <div className={`mention-dropdown-item ${focused ? 'mention-dropdown-item--active' : ''}`}>
          {highlightedDisplay}
        </div>
      );

    const getDisplayValueById = (id) => {
        const item = dataSource.find(element => element.id == id);
        return item ? item.display : '';
    };

    const renderComponent = ()=>{
        return <div className='app-smart-text-box-container'>
            <label className='app-smart-text-box-label'>{label}</label>
                <MentionsInput 
                    classNames={['app-smart-input']}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder || `${marker} ${translate('smart_text_box_placeholder')}`}
                    singleLine={true}
                >
                    <Mention
                        trigger={marker}
                        data={dataSource}
                        markup={`${marker}{__id__}`}
                        displayTransform={getDisplayValueById}
                        className='app-smart-mention'
                        renderSuggestion={renderSuggestion}
                    />
                </MentionsInput>  
                {isRequired ? helpers.getRequiredFieldIndicator() : ''} 
        </div>
    }

    return <div className={classes.join(' ')}>{renderComponent()}</div>  
};


