// React imports
import { useEffect, useState, useCallback } from 'react';

// App imports
import { Loader } from '../../base/loader/loader';
import { Button } from '../../base/button/button';
import { MenuButton } from '../../base/menuButton/menuButton';
import { ConfirmButton } from '../../base/confirmButton/confirmButton';
import { icons } from '../../base/icon/icon';
import { translate } from '../../../utils/translation';
import { helpers } from '../../../utils/helpers';
import { Description } from '../../base/description/description';
import { DataGrid } from '../../base/dataGrid/dataGrid';
import { multimedia as multimediaModule } from '../../../modules/multimedia';
import { legacyEndpoints } from '../../../services/legacyEndpoints';
import { Hideable } from '../../base/hideable/hideable';
import { UploadPhotos } from '../photos/uploadPhotos/uploadPhotos';
import { UrlEdit } from './urlEdit/urlEdit';
import { DetailsEdit } from './detailsEdit/detailsEdit';

export function Multimedia({entity,hide}){

    const [multimedia, setMultimedia] = useState([]);
    const [hideUpload, setHideUpload] = useState(true);
    const [hideAddURL, setHideAddURL] = useState(true);
    const [hideEditDetails, setHideEditDetails] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [mediaForEdit, setMediaForEdit] = useState(null);
    const [maxNumberOfFiles, setMaxNumberOfFiles] = useState(100);

    var getMultimedia = useCallback(
        async () =>{

            setLoaded(true);
            var sources = [];

            const result = await multimediaModule.getMultimedia({ sourceId : entity.id, dataSourceId: entity.layer.parentId,});
                          
            setMultimedia(result.multimedia);
            setLoaded(true);  
        },
        []
    );

    useEffect(() =>{
        getMultimedia();
    }, [getMultimedia]);
    
    const showUpload = async () =>{ 
        setHideUpload(false);
    }; 

    const closeUpload = async () =>{ 
        setHideUpload(true);
        await getMultimedia();
    }; 

    const addUrl = async () =>{ 
        setHideAddURL(false);
    }; 

    const closeAddUrl = async () =>{ 
        setHideAddURL(true);
        await getMultimedia();
    };

    const deleteMultimedia = async (o) =>{
        await multimediaModule.deleteMultimedia({
            id: o.id,
            sourceId : entity.id,
            dataSourceId: entity.layer.parentId,
            managerType: 0 
        });

        getMultimedia();
    }

    const edit = (o) =>{
        setMediaForEdit(o);
        setHideEditDetails(false)
    }     //setMaxNumberOfFiles

    const closeEdit = async () =>{ 
        setHideEditDetails(true);
        await getMultimedia();
    }; 

    const reupload = (o) =>{
        setMaxNumberOfFiles(1);
        setMediaForEdit(o);
        showUpload();
    } 
    
	return <>

        <Hideable  hide={hideUpload}>  
            <UploadPhotos className={'app-multimedia-photo-upload'} entity={entity} photoCount={0} onClose={closeUpload} 
                maxNumberOfFiles={maxNumberOfFiles} 
                useType={1} 
                allowedExtensions={['jpg','jpeg','png','gif','bmp','svg','tif','dwg','dxf','csv','doc','docx','mpp','onetoc','pdf','ppt','pptx','pub','tsv','txt','vsd','xls','xlsx']}
                instructions=''
            />    
        </Hideable>    

        <Hideable  hide={hideEditDetails}>  
            <DetailsEdit entity={entity} media={mediaForEdit} onClose={closeEdit} />    
        </Hideable>          

        <Hideable  hide={hideAddURL}>  
            <UrlEdit entity={entity} onClose={closeAddUrl} />    
        </Hideable>    

        <Hideable className='app-photos' hide={!hideUpload || !hideAddURL || !hideEditDetails}>  
            <Description className='app-multimedia-instructions' description={translate('multimedia_instructions')} />
            <div>
                <div className='app-multimedia-instructions-note'>
                    {translate("multimedia_upload_note")}
                </div>
                <div className='app-multimedia-actions'>
                    <MenuButton icon={icons.plus} 
                        tooltip={translate('add_media')} 
                        align='right'
                        theme='primary' 
                        size='small'
                        items={[
                            {icon: icons.plus, text: translate("uploader"), onClick: () =>{ showUpload(); }},
                            {icon: icons.plus,  text: translate("add_url"), onClick: () =>{ addUrl(); }}
                        ]}
                    />                    
                </div>
            </div>
            <DataGrid 
                    key='id'
                    height='325px'
                    className='app-multimedia'
                    showFilterRow={true}
                    remoteOperations={'false'}
                    enableMasterDetail={true}
                    detailTemplate={(o)=>{       
                        const data = o.data.data;         
                        return <>
                            <div className='app-multimedia-grid-detail-description'>
                                {data.description}
                            </div>
                            <div>
                                <Button className='app-multimedia-grid-detail-actions' theme='simple' icon={icons.download} tooltip={translate('download')}                             
                                    onClick={()=>{ helpers.navigateToUrl(legacyEndpoints.handlers.getMultimediaUrl({ id: data.id })); }} /> 

                                <Button className='app-multimedia-grid-detail-actions' theme='simple' icon={icons.edit} 
                                    tooltip={translate('edit')} disabled={!data.isEditable} 
                                    onClick={()=>{ edit(data) }} />	                        

                                {/* todo: implement reupload? photos does not                            */}
                                <Button className='app-multimedia-grid-detail-actions' theme='simple' icon={icons.upload} 
                                    tooltip={translate('reupload')} disabled={true}//{!data.isEditable} 
                                    onClick={()=>{ reupload(data) }} />	                                         

                                <span className='app-multimedia-grid-detail-delete-action'>
                                    <ConfirmButton  theme='simple' icon={icons.trash} tooltip={translate('delete')} 
                                        disabled={!data.isDeletable} clearText={translate('delete')}
                                        onConfirm={()=>{ deleteMultimedia(data) }} />                                
                                </span>
                            </div>   
                        </>
                    }}                    
                    columns={[           
                        { name: 'type', alignment:'center', caption: translate('type'), allowFiltering: false,  width:'85px', onRender: (o) => { 
                            return <img className='' src={o.contentType.imageURL} alt={o.contentType.name} />; 
                        }},
                        { name: 'name', caption: translate('file'), width:'250px', onRender: (o) => { 

                            return <>
                                <div className='app-multimedia-file-name '>
                                    {o.name}
                                </div>
                                <div>
                                    {helpers.formatDate({ date: helpers.convertJSONDate(o.dateCreated), format: 'MM/DD/YYYY h:mm a' })}
                                </div>  
                                {o.isURL === true 
                                    ? <><div>{o.contentType.name}</div></>  //todo add v1.0 logic
                                    : <><div>{o.contentType.name}</div><div>{helpers.formatFileSize(o.size)}</div></>
                                }                                                      
                            </>
                        }},
                        { name: 'dateModified', caption: translate('date_modified'), allowFiltering: false, width: '200px', onRender: (o) => { return helpers.formatDate({ date: o.dateModified }); }},                    
                        { name: 'uploader', caption: translate('uploader'), allowFiltering: false, width:'200px', onRender: (o) => 
                        {                        
                            return ( <>
                                <img className='app-multimedia-user-image' src={legacyEndpoints.handlers.getPhotoUrl({ userId: o.userGUID, isUserPhoto: true, width: 150, height: 150 })} alt={translate('user_image')} />
                                <span className='app-multimedia-username'>
                                    {o.userName}
                                </span>
                            </>
                            ) 
                        }},
                    ]}
                    onLoad={async (o)=>{
                        return {
                            data: multimedia,
                            totalCount: multimedia.length
                        };
                    }}
                />     
        </Hideable>  

    </>
}
