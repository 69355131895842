// React imports
import { useState, useEffect, useRef} from 'react';

// App imports
import { PrintMap } from '../../projects/printMap/printMap';
import { PointDetailReportOptions } from './pointDetailReport/pointDetailReportOptions';
import { constants } from '../../../../utils/constants';
import { mapBooks } from '../../../../modules/mapbooks';
import { thematics } from '../../../../modules/thematics';
import { sources } from '../../../../modules/sources';
import { projects } from '../../../../modules/projects';
import { icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { Button } from '../../../base/button/button';
import { TextBox } from '../../../base/textBox/textBox';
import { DualPanel } from '../../../base/dualPanel/dualPanel';
import { DropDown } from '../../../base/dropDown/dropDown';
import { successToast } from '../../../base/toast/toast';
import { List } from 'devextreme-react';
import { ModalCard } from '../../../base/modalCard/modalCard';
import { MapBookError } from './mapBookError/mapBookError';
import { TradeAreas } from '../../tradeAreas/tradeAreas';
import { ItemDragging } from 'devextreme-react/cjs/list';
import { Icon } from '../../../base/icon/icon';
import SmartTextBox from '../../../base/smartTextBox/smartTextBox';
import { helpers } from '../../../../utils/helpers';
import smartTags from '../../../../modules/smartTags';

const _ = require("lodash");

export function MapBookBuilder({text, onClose, mapBookId}) {   
    const [generating, setGenerating] = useState(false);
    const [mapBook, setMapBook] = useState(null);
    const [mapBookMasterBookMarkString, setMapBookMasterBookMarkString] = useState(null);
    const [mapBookLoaded, setMapBookLoaded] = useState(false);
    const [mapBookContentTypes, setMapBookContentTypes] = useState(null);
    const [adHocMapDataMaps, setAdHocMapDataMaps] = useState([]);
    const [pointDetailReportMapContent, setPointDetailReportMapContent] = useState([]);
    const [adHocMapThematics, setAdHocMapThematics] = useState([]);
    const [adHocMapSources, setAdHocMapSources] = useState([]);
    const [mapBookContentTypesLoaded, setMapBookContentTypesLoaded] = useState(false);
    const [showRightPanel, setShowRightPanel] = useState(false);
    const [rightPanelContentType, setRightPanelContentType] = useState(null);
    const [rightPanelWidth, setRightPanelWidth] = useState(620);
    const [rightPanelContent, setRightPanelContent] = useState(null);
    const [validationError, setValidationError] = useState(null);
    const rightPanelOnSave = useRef(null);
    
    const smartTagDataSource = smartTags.GetMapBookTags();

    //load map book
    useEffect(() => {
         async function getMapBook(){
            setMapBookLoaded(false);    
    
            const mapBook = await mapBooks.getMapBookForUser({
                id: mapBookId
            });

            setMapBook(mapBook);
            setMapBookMasterBookMarkString(helpers.convertSmartTagToString(mapBook.masterBookmark)); 
            setMapBookLoaded(true);      
        };

        if(!_.isNull(mapBookId))
            getMapBook();
        else
        {
            const newMapBook = mapBooks.getNewMapBook()

            setMapBook(newMapBook);
            setMapBookMasterBookMarkString(helpers.convertSmartTagToString(newMapBook.masterBookmark)); 
            setMapBookLoaded(true); 
        }
            
    }, [mapBookId])

    //load available map book content types
    useEffect(() => {
        async function getMapBookContentTypes(){
            setMapBookContentTypesLoaded(false);    
   
            var mapBookContentTypes = await mapBooks.getMapBookContentTypesForUser();
            setMapBookContentTypes(mapBookContentTypes);

            const adHocMapContent = _.find(mapBookContentTypes, function (item) {
                return item.type === constants.mapBooks.mapBookPageType.adHocMap;
            });

            if (_.isObject(adHocMapContent) && _.isArray(adHocMapContent.dataMaps))
                setAdHocMapDataMaps(adHocMapContent.dataMaps);

            setAdHocMapThematics(projects.getGroupedItems({ items: await thematics.get() }));
            setAdHocMapSources(projects.getGroupedItems({ items: await sources.get() }));

            const pointDetailReportMapContent = _.find(mapBookContentTypes, function (item) {
                return item.type === constants.mapBooks.mapBookPageType.pointDetailReport;
            });

            if (_.isObject(pointDetailReportMapContent) && _.isArray(pointDetailReportMapContent.pointDetailReportCustomQueryGroups))
                setPointDetailReportMapContent(pointDetailReportMapContent);

            setMapBookContentTypesLoaded(true);      
       };

       getMapBookContentTypes();        
   }, [])

   const rightPanelHeaderDictionary = {
    [constants.mapBooks.mapBookBuilderPanelType.adHocMap] : translate('ad_hoc_map_output_options'),
    [constants.mapBooks.mapBookBuilderPanelType.pdr] : translate('point_detail_report_options'),
    [constants.mapBooks.mapBookBuilderPanelType.tag] : "tag",
    [constants.mapBooks.mapBookBuilderPanelType.tradeArea] : translate('trade_areas'),
   }

   const saveMapBook = async () => {
        setGenerating(true);
        var success = false;

        var isValid = await mapBooks.isMapBookValidForUser({ id: mapBookId, name: mapBook.name });

        if (isValid) {
            mapBook.masterBookmark = helpers.convertStringToSmartTag({id: mapBook.masterBookmark.Id, text: mapBookMasterBookMarkString, words: smartTagDataSource});

            var response = await mapBooks.updateMapBookForUser({ mapBook });
        
            if (response?.ErrorPages?.length === 0 && response?.Errors?.length === 0) {
                successToast(translate('successfully_saved'));
                success = true;  
            }
            else {   
                setValidationError(response);
            }
        }

        setGenerating(false);
        return(success);
   }

   const onSave = async () => {
        var success = await saveMapBook();
        if(success)
            onClose();
   }

   const fillContentDefinitionContainer = (page) => {
    var reportDictionary = {};
    switch(page.type){
            case constants.mapBooks.mapBookPageType.adHocMap:
                return <Button 
                    theme='row'
                    className={'app-map-book-builder-row-button'}  
                    icon={icons.edit} 
                    text={translate('ad_hoc_map_output_options')}
                    onClick={() => { 
                        rightPanelOnSave.current = () => {};
                        buildRightPanelContent({ contentType: constants.mapBooks.mapBookBuilderPanelType.adHocMap, page: page });
                    }}
                />
            case constants.mapBooks.mapBookPageType.demographicReport:           
                return <DropDown className={'app-map-book-builder-panel-stretch'}
                    label={translate('report')} 
                    height={'40px'} 
                    valueProperty={'key'}
                    display={'name'}
                    selected={page.demographicReportId}
                    items={mapBookContentTypes.find(function(o){return o.type === constants.mapBooks.mapBookPageType.demographicReport}).reports.map((report) =>{ reportDictionary[report.ID] = report.Name; return {key: report.ID, name: report.Name}})}
                    onChange={(o) => {page.demographicReportId = o.value; page.demographicReportName = reportDictionary[o.value]; setMapBook(_.cloneDeep(mapBook));}}
                />
            case constants.mapBooks.mapBookPageType.demographicQuadrant:           
                return <DropDown className={'app-map-book-builder-panel-stretch'}
                    label={translate('report')} 
                    height={'40px'} 
                    valueProperty={'key'}
                    display={'name'}
                    selected={page.demographicReportId}
                    items={mapBookContentTypes.find(function(o){return o.type === constants.mapBooks.mapBookPageType.demographicQuadrant}).reports.map((report) =>{ reportDictionary[report.ID] = report.Name; return {key: report.ID, name: report.Name}})}
                    onChange={(o) => {page.demographicReportId = o.value; page.demographicReportName = reportDictionary[o.value];  setMapBook(_.cloneDeep(mapBook));}}
                />
            case constants.mapBooks.mapBookPageType.pointDetailReport:
                return <Button 
                    theme='row'
                    className={'app-map-book-builder-row-button'}  
                    icon={icons.edit} 
                    text={translate('point_detail_report_options')}
                    onClick={() => { 
                        rightPanelOnSave.current = (pdr) => {
                            page.pointDetailReport = pdr;  
                            setMapBook(_.cloneDeep(mapBook));
                            setShowRightPanel(false);
                        };
                        buildRightPanelContent({ contentType: constants.mapBooks.mapBookBuilderPanelType.pdr, page: page }); 
                    }}
                />
            default:
                return <div></div>;
        }
   }

   const deletePage = (page) => {
        mapBook.pages = _.without(mapBook.pages, page);

        _.each(mapBook.pages, function(mapBookPage, i) {
            mapBookPage.sequence = i+1;
        });

        setMapBook(_.cloneDeep(mapBook));
    }

    const setMapBookPageRow = (page) => {
        return <table key={page.sequence} className='app-map-book-builder-pages-table'>
            <tbody>
                <tr>
                    <td>
                        <Icon className='app-map-book-builder-page-move' icon={icons.ellipses} />
                    </td>
                    <td className='app-map-book-builder-panel-stretch'>
                        <div className='app-map-book-builder-panel-row'>
                            <TextBox className={'app-map-book-builder-panel-stretch'} label={translate('bookmark_text')} value={page.bookmark} onChange={(o) => {page.bookmark = o.value; setMapBook(_.cloneDeep(mapBook)); }}/>
                        </div>
                        <div className='app-map-book-builder-panel-row'>
                            <DropDown className={'app-map-book-builder-panel-stretch'}
                                label={translate('content_type')} 
                                placeholder={translate('select_a_report')}
                                height={'40px'} 
                                valueProperty={'key'}
                                display={'name'}
                                selected={page.type}
                                items={mapBookContentTypes.map((contentType) =>{ return {key: contentType.type, name: contentType.description}})}
                                onChange={(o) => {page.type = o.value; setMapBook(_.cloneDeep(mapBook));}}
                            />
                        </div>
                        <div className='app-map-book-builder-panel-row'>
                            <div className='app-map-book-builder-content-defintion-container'>
                                {fillContentDefinitionContainer(page)}
                            </div>
                        </div>
                        <div className='app-map-book-builder-panel-row'>
                            <DropDown className={'app-map-book-builder-panel-stretch'}
                                label={translate('trade_area')}
                                placeholder={translate('select_a_report')} 
                                height={'40px'} 
                                valueProperty={'key'}
                                display={'name'}
                                selected={page.tradeAreaLibraryType}
                                items={[{key: constants.mapBooks.mapBookTradeAreaType.none, name: translate('none')},
                                    {key: constants.mapBooks.mapBookTradeAreaType.default, name: translate('default')},
                                    {key: constants.mapBooks.mapBookTradeAreaType.custom, name: translate('custom')}
                                ]}
                                onChange={(o) => {
                                    page.tradeAreaLibraryType = o.value; 
                                    setMapBook(_.cloneDeep(mapBook));
                                    if (o.value === constants.mapBooks.mapBookTradeAreaType.custom)
                                    {
                                        rightPanelOnSave.current = ({tradeAreas}) => {
                                            page.tradeAreaLibrary = mapBooks.getNewTradeAreaLibraryForMapBook(tradeAreas); 
                                            setMapBook(_.cloneDeep(mapBook));
                                            setShowRightPanel(false);
                                        };
                                        buildRightPanelContent({ contentType: constants.mapBooks.mapBookBuilderPanelType.tradeArea, 
                                            page: page, 
                                            tradeAreas: _.isNull(page.tradeAreaLibrary) ? mapBook.tradeAreaLibrary.tradeAreas : page.tradeAreaLibrary.tradeAreas});
                                    }
                                    else
                                        setShowRightPanel(false); 
                                }}
                            />
                        </div>
                        <div className='app-map-book-builder-panel-row'>
                            <Button theme='row' className={'app-map-book-builder-row-button'} icon={icons.trash} text={translate('delete_page')} tooltip={translate('delete_page')} onClick={() =>{deletePage(page)}}/>
                        </div> 
                    </td>
                </tr>
            </tbody>
        </table> 
    }

    const setLeftPanel = () =>{
        if(_.isNull(mapBook) || _.isUndefined(mapBook))
            return '';
        
        return <>
            <div className='app-map-book-builder-button-container'>
                <Button theme='secondary' className='app-map-book-builder-action-button' size={'small'} tooltip={translate('cancel')} icon={icons.x} onClick={() => {onClose();}}/> 
                <Button theme='primary' className='app-map-book-builder-action-button' size={'small'} tooltip={translate('save')} icon={icons.check} onClick={() => {onSave();}}/>
            </div>
            <div className='app-map-book-container'>
                <div className='app-map-book-builder-panel-container'>
                    <div className='app-map-book-builder-panel-row'>
                        <TextBox className={'app-map-book-builder-panel-stretch'}
                            height='40px' label={translate('name')} placeholder={translate('name')}
                            value={mapBook.name}
                            onChange={(o) => {mapBook.name = o.value; setMapBook(_.cloneDeep(mapBook));}} 
                        />
                    </div>
                    <div className='app-map-book-builder-panel-row'>
                        <TextBox className={'app-map-book-builder-panel-stretch'}
                            height='100px' label={translate('description')} placeholder={translate('description')}
                            value={mapBook.description}
                            onChange={(o) => {mapBook.description = o.value; setMapBook(_.cloneDeep(mapBook));}} 
                        />
                    </div>
                    <div className='app-map-book-builder-panel-row'>
                        <SmartTextBox
							label={translate('master_bookmark')} 
                            value={mapBookMasterBookMarkString}
                            smartTags={smartTagDataSource} 
                            displayExpr={"value"} 
                            valueExpr={"key"} 
                            marker={"#"}
                            onChange={(o) => {setMapBookMasterBookMarkString(o.target.value);}}
                        />
                    </div>
                    <div className='app-map-book-builder-panel-row'>
                        <Button 
                            theme='row'
                            className={'app-map-book-builder-row-button'} 
                            icon={icons.bullseye} 
                            text={translate('define_default_trade_areas')}
                            onClick={() => { 
                                rightPanelOnSave.current = ({tradeAreas}) => {
                                    mapBook.tradeAreaLibrary.tradeAreas = tradeAreas; 
                                    setMapBook(_.cloneDeep(mapBook));
                                    setShowRightPanel(false);
                                };
                                buildRightPanelContent({ contentType: constants.mapBooks.mapBookBuilderPanelType.tradeArea, page: null, tradeAreas: mapBook.tradeAreaLibrary.tradeAreas });
                            }}
                        />
                    </div>
                    <div className='app-map-book-builder-panel-row'>
                        <div className={'app-map-book-pages-header'}>{translate('map_book_pages')}</div>
                    </div>
                    <div className='app-map-book-builder-panel-row'>
                        <Button theme='row' className={'app-map-book-builder-row-button'}  icon={icons.plus} text={translate('add_map_book_item')} onClick={() => {mapBook.pages.push(mapBooks.getNewMapBookPage(mapBook.pages.length+1)); setMapBook(_.cloneDeep(mapBook));}}/>  
                    </div>
                </div>
                <div className={'app-map-book-builder-pages-container'}>  
                    <List
                        keyExpr={'sequence'}
                        dataSource={mapBook.pages}
                        itemRender={setMapBookPageRow}
                        selectionMode={"none"}
                        displayExpr={'name'}
                        selectByClick={false}
                        pageLoadMode={"scrollBottom"}
                        focusStateEnabled={false}
                        activeStateEnabled={false}>
                        <ItemDragging
                            allowReordering={true}
                            handle={'.app-map-book-builder-page-move'}
                            onReorder={onReorder}
                            boundary={'.app-map-book-builder-pages-container'}
                        />
                    </List>
                </div>
                <div className='app-map-book-error-modal'  style={{display: _.isObject(validationError) ? 'flex' : 'none' }}>
                    <ModalCard loaded={true} open={_.isObject(validationError)}>
                        <MapBookError data={validationError} onClose={()=>{ setValidationError(null); }} />
                    </ModalCard>	
                </div>            
            </div>
        </>  
    };

    const buildRightPanelContent = (o) =>{
        setRightPanelContentType(o.contentType);

        switch(o.contentType) {
            case constants.mapBooks.mapBookBuilderPanelType.adHocMap:
                setRightPanelWidth(620);
                setRightPanelContent(<div className='app-map-book-adhoc-map-panel-container'>
                    <PrintMap 
                        isMapBook={true} 
                        adHocMap={o.page.adHocMap} 
                        dataMaps={adHocMapDataMaps} 
                        thematics={adHocMapThematics} 
                        sources={adHocMapSources} 
                        onClose={() =>{ rightPanelOnClose(); }} 
                        onSave={(adHocMap) =>{o.page.adHocMap = adHocMap; setMapBook(_.cloneDeep(mapBook)); rightPanelOnClose();}} 
                    />
                </div>);
                break;
            case constants.mapBooks.mapBookBuilderPanelType.pdr:
                setRightPanelWidth(320);
                setRightPanelContent(<div className='app-map-book-adhoc-map-panel-container'>
                   <PointDetailReportOptions mapBookContentTypes={pointDetailReportMapContent}
                   pdr = {_.isNull(o.page.pointDetailReport) ? {customQueryId: null, customQueryName: null, isGoldOrg: false, reportId: null, reportName: null} : o.page.pointDetailReport} 
                   onClose={() =>{ rightPanelOnClose(); }} 
                   onSave={(pdr) => rightPanelOnSave.current(pdr)} />
                </div>);
                break;
            case constants.mapBooks.mapBookBuilderPanelType.tag:
                setRightPanelContent(<div className='app-map-book-builder-panel-container'>
                    <div className='app-map-book-builder-button-container'>
                        <Button theme='secondary' className='app-map-book-builder-action-button' size={'medium'} icon={icons.x} onClick={() => {rightPanelOnClose(); }}/> 
                        <Button theme='primary' className='app-map-book-builder-action-button' size={'medium'} icon={icons.check} onClick={() => {rightPanelOnSave.current();}}/>
                    </div>
                    <div>Tag</div>
                </div>);
                break;
            case constants.mapBooks.mapBookBuilderPanelType.tradeArea:
                setRightPanelWidth(765);
                setRightPanelContent(<div className='app-map-book-builder-panel-container'>
                    <TradeAreas entity={{id: null, location: {lat: null, lon: null}, layer: {id: null, parentId: null}}} 
                        filteredTypes={['R','D','T']}
                        showZoomToResult = {false}
                        showName = {false}
                        importedTradeAreas={o.tradeAreas}
                        onSave={({tradeAreas, defaultTradeAreas}) => rightPanelOnSave.current({tradeAreas, defaultTradeAreas})}
                    />
                </div>);
                break;
            default:
                setRightPanelContent(<><div></div></>);
                break;
        }

        setShowRightPanel(true); 
    }

    const rightPanelOnClose = () => {
        setShowRightPanel(false);
        setRightPanelContentType(null);
    }

    const onReorder = (o) => {
        if (o.fromIndex === o.toIndex) return;

        var page = mapBook.pages[o.fromIndex];
        mapBook.pages.splice(o.fromIndex, 1);
        mapBook.pages.splice(o.toIndex, 0, page);
        
        _.each(mapBook.pages, function(mapBookPage, i) {
            mapBookPage.sequence = i+1;
        });

        setMapBook(_.cloneDeep(mapBook));
    }

    return <DualPanel 
        leftPanelText={text}
        leftPanelChildren={setLeftPanel()} 
        leftPanelOnClose={() =>{ onClose(); }}
        showRightPanel={showRightPanel}
        rightPanelText={_.isNull(rightPanelContentType) ? '' : rightPanelHeaderDictionary[rightPanelContentType]}
        rightPanelWidth={rightPanelWidth}
        rightPanelChildren={showRightPanel ? rightPanelContent : ''}
        showRightPanelClose={true}
        rightPanelOnClose={() =>{ rightPanelOnClose(); }}
        loaded={mapBookLoaded && mapBookContentTypesLoaded} 
        generating={generating} 
    >
    </DualPanel>
}