import { translate } from "./translation";

export const constants ={
	company: "Kalibrate",
    company_website: "https://www.kalibrate.com/",
    earthRadius: 6378136, // in meters
    appKeys:{
        bingMaps: 0,
        googleMaps: 1,
        googleMapsMapId: 2,
        hereMaps: 3,
        mapBoxMaps: 4,
        mapRegion: 5
    },
    activityHub: {        
        actions: {            
            customActions:{
                types:{
                    button: 1,
                    dropDown: 2
                },
                customReport: 'reportsModule.ui.customReport.generate',
                openStoreReport: 'reportsModule.ui.customReport.openStoreReport',
                analogs: "analogModule.ui",
                cvsCompList: "TASOnline.Custom.CVS.potentialSite.competitorList.addJob",
            },
            information: '8195A670-215B-4240-A1C4-99DDCFF94B9D',
            tradeAreas: '16D2AC85-A08A-4DFB-9AFD-6365B55F3233',
            drivingDirections: '09D2C5E7-9CA8-44A2-964C-B206E585414B',
            edit: '813251DC-008D-44C1-917C-208B56A3ACE9',
            add: '9DA687A1-911C-4880-B06A-21BE1FCFD0C4',
            delete: 'A2890F01-F158-4E92-B909-37C84A8C19E8',
            photos: '04997EB9-5445-43D0-8BC8-F99231F82DD9',
            comments: 'D07F08AC-9BD6-4B64-960F-864491F1494D',
            projections: '37AFCA8B-8FA1-4441-814C-C4519210E5CE',
            trip2Trade: '54FA33C2-5BF1-4108-A7D4-8D029C7BF464',
            multimedia: '0C762919-4D36-4F0F-8B0D-D2CF0E095628',
            geofences: '6FE96763-86E3-4C2A-AD03-985E9142D90F',
            customerMaps: '6FE8844F-2E59-4440-9B16-72E1C086CD77',
            competitiveInsightsDashboards: 'FD2B9092-81F5-41DF-8FCF-47D097F0E665',
            competitiveInsightsPolygonTradeAreas: '0F54F894-C599-4274-A0DC-68305F44F56A',
            competitiveInsightsVisitTradAreas: 'AF8A73AA-2272-4DCD-B40D-9A298C04487D',
            demographics: '9EF53362-EACA-4100-8C3D-12A0DE3B9EC4',
            reshape: 'BA71102B-DD80-482D-B10A-AB4D5A2C4D06',
            move: '3D3D8F70-A26E-4B87-A560-475CE5CA65CB',
            select: '115126AF-AA97-483C-A3F3-5F09BE33B0CB',
            download: 'A07AEE46-9F71-4073-8505-B4F776C3768D',
            mapBooks: 'D6C55A8E-F558-433C-80FF-3C8761634410',
            customActionButton: '8636EA8E-91B8-46C7-98F0-C9AED0DDC696',
            reportIncorrectCompetitiveInsightsObject: 'c77ed08e-b4d1-4e30-aece-2d3be50349ab',
            task: '440C906E-B124-430B-911D-01A3145593CD'
        }
    },
    map: {
        types:{
            road: 0,
            terrain: 1,
            aerial: 2,
            dark: 3,
            gray: 4,
            light: 5,
            birdsEye: 6
        },
        legacyTypes:{
            street: "s",
            road: "r",
            terrain: "t",   // TBD: is there Bing equivalent to Google terrain?
            aerial: "a",
            hybrid: "h",
            dark: "wd",
            gray: "cg",
            light: "wl",
            birdsEye: "b"   // TBD: is there Bing equivalent to Google birdsEye?
        },
        pois:{
            attractions: 0,
            airPorts: 1,
            busTerminals: 2,
            carRentals: 3,
            foodAndDrink: 4,
            gasStations: 5,
            government: 6,
            lodging: 7,
            medical: 8,
            parks: 9,
            placeOfWorship: 10,
            schools: 11,
            shopping: 12,
            sportVenues: 13,            
            railStations: 14,
            other: 15
        }
    },
    forms:{
        types:{
            information: 0,
            edit: 1,
            add: 2
        },
        tabDisplays:
        {
            textOnly: 0,
            imageOnly: 1,
            imageAndText: 2
        },
        components:{
            list: -1,
            text: 1,
            numeric: 2,
            currency: 3,
            percentage: 4,
            date: 5,
            checkbox: 6,
            label: 7,
            photo: 8,
            radio: 9,
            checkList: 10
        },
        componentRequiredBehaviors:{
            invalid: 0,
            warning: 1
        },
        autoFields: {
            none: 0,
            address: 1,
            city: 2,
            state: 3,
            zip: 4,
            latitude: 5,
            longitude: 6
        },
        formConditions: {
            actions: {
                show: 0,
                hide: 1,
                required: 2,
                notRequired: 3,
                enable: 4,
                disable: 5,
                style: 6,
                defaultStyle: 7,
                valid: 8,
                invalid: 9,
                warning: 10,
                clearOnSave: 11,
                doNotClearOnSave: 12
            },
            actionObjects:
            {
                tab: 0,
                section: 1,
                field: 2
            }
        },
    },
    listeners:{
        viewChange: 0,
        click: 1,
        mouseMove: 2,
        mouseIn: 3,
        mouseOut: 4,
        mapTypeChange: 5,
        rightClick: 6,
        drag: 7,
        layerAdd: 8,
        layerRemove: 9,
        panoPositionChange: 10
    },
    entities:{        
        activePoint: 0,
        activeShape: 1,
        focusedPoint: 2,
        focusedShape: 3,
        selectedPoint: 4,
        selectedShape: 5,
        circle: 6,
        point: 7,
        pushpin: 8,
        label: 9,
        polygon: 10,
        polyline: 11,
        tradeArea: 12,        
        rectangle: 13,
        geoJson: 14,
        wkt: 15,
        genericPoint: 16,
        standardGeography: 17,
        cluster: 18,
    },
    polygons:{
        defaults:{
            fillColor: { r: 0, g: 0, b: 0, a: .3 },
            strokeColor: { r: 0, g: 0, b: 0, a: .7 },
            strokeWidth: 2,
            strokeStyle: 'solid'
        },
        outline:{
            fillColor: { r: 0, g: 0, b: 0, a: .2 },
            strokeColor: { r: 0, g: 0, b: 0, a: .5 },
            strokeWidth: 1
        },
        temporary: {
            fillColor: { r: 90, g: 184, b: 141, a: .3  },
            strokeColor: { r: 90, g: 184, b: 141, a: .7 },
            strokeWidth: 2
        },
        pending: {
            fillColor: { r: 43, g: 50, b: 140, a: .6  },
            strokeColor: { r: 43, g: 50, b: 140, a: .8 },
            strokeWidth: 1
        },
        hover:{
            fillColor: { r: 193, g: 212, b: 47, a: .3 },
            strokeColor: { r: 193, g: 212, b: 47, a: .7 }
        },
        selected:{
            fillColor: { r: 245, g: 156, b: 40, a: .3 },
            strokeColor: { r: 245, g: 156, b: 40, a: .7 },
            strokeWidth: 1
        },
        labelColor: { r: 90, g: 184, b: 141, a: .7 }
    },
    // Start layers at 100 so the beginning is reserved for thematics
	layers: {
        groups:{
            other: -1,
            temporary: 0,
            selection: 1,
            cosmetic: 2,
            point: 3,
            tradeArea: 4,
            thematic: 5,
            geoFence: 6,
            customerMap: 7,
            trip2Trade: 8,
            hotSpotAnalysis: 9,
            competitiveInsights: 10,
            poi: 11,
            geoFeeds: 12,
            activeEntity: 13,
            ai: 14
        },
        types: {
            thematics:{
                standardGeography: 0,
                customGrid: 1,
                fixedGrid: 2,
                parcel: 3,
                customGridNoDemos: 4,
                sqlDataLayer: 5,
                trafficMetrix: 6,
                subTypes: {
                    range: 1,
                    dotDensity: 2,
                    polygon: 3
                }
            },
            customerMaps: {
                pin: 100,
                marketShare: 101,
                heat: 102,
                desireLine: 103
            },
            other: 200,
            temporary: 201,
            selection: 202,
            cosmetic: 203,
            point: 204,
            label: 205,    
            tradeArea: 207,
            modelTradeArea: 208,
            competitiveInsightsTradeArea: 209,
            geoFence: 210,
            geoFeeds: 211,
            trip2Trade: 212,
            directions: 213,
            data: 214,
            poi: 215,
            activeEntity: 216
        }        
	},
    navigation:{
		sections:{
            viewer: -1,
			layers: 0,
			analytics: 1,
            ai: 2,
            mobilityData: 3,
			drivingDirections: 4,
			quickReports: 5,
			tools: 6,
			projects: 7,
			reports: 8,
            jobs: 9
		}
    },	
	textFormats:{
		json: 1
	},
    projections:
    {
        interactive: 0,
        oneClick: 1,
        relocation: 2,
        api: {
            internal: 0,
            external: 1
        },
        providers:{
            intalytics: 100,
            generic: 200,
            eSite: 300
        },
        modes: {
            new: 0,
            existing: 1
        },        
        models: {
            disaggregateForecast: 1,
            disaggregateCannibalization: 2,
            disaggregateRecapture: 3,
            disaggregateRelocation: 4,
            aggregateForecast: 5,
            disaggregateDeliveryCannibalization: 6
        },
        views: {
            disaggregateForecastTradeArea: 0,
            disaggregateForecastPoint: 1,
            disaggregateCannibalizationPoint: 2,
            disaggregateRecapturePoint: 3
        },
        disaggregateForecastTradeAreaColumns:{
            remove: "5f040ddf-fb14-4ec6-963f-af598a76033f",
            zoom: "c9e57cf4-1cde-42fb-a8b4-c90c265758bf",
            geography: "fdf15053-4f05-4ee6-b36e-bd827f3e0274",
            distanceMiles: "d8fe6371-b0b8-4c04-945c-9829b50a7b67",
            distanceKilometers: "1366e8ac-43ab-4dab-ac1d-8b478e14c56f"
        },
        disaggregateForecastPointColumns:{
            select: "DCE33FC9-8C17-44A6-9D6A-2F6251B3700C",
            zoom: "52E617B2-2C23-4ED0-AB36-B606C6976863",
            distance: "20000000-0000-0000-0000-000000000000",
            distanceKm: "20000000-0000-0000-0000-000000000000_"
        },
        disaggregateCannibalizationColumns:{
            storeSelect: "c779ef66-01df-4fd4-9867-7566618f512b",
            storeZoom: "c779ef66-01df-4fd4-9867-7566618f512b_",
            storeVolume: "f817232b-5355-4025-ba07-dffd40ea8275",
            storeForecast: "571be42e-4ce2-46cc-8c0d-7db1f9c0dc34",
            storeImpact: "d900a32d-e3fb-4371-8198-f09014776a8f",
            storeTransferred: "e3972334-5ec2-49e7-90a9-400fd91c6562",
            storeDistance: "de9908da-dcba-4c66-b10c-27f9e659d4d5",
            storeDistanceKm: "de9908da-dcba-4c66-b10c-27f9e659d4d5_",
            geographyZoom: "57ff28e7-2681-423c-8075-817c6933b073_",
            geographyId: "57ff28e7-2681-423c-8075-817c6933b073",
            geographyDistance: "e4f3cdd8-5a6f-4d42-8f00-c24a56d7e27f",
            geographyDistanceKm: "e4f3cdd8-5a6f-4d42-8f00-c24a56d7e27f_",
            geographyAzimuth: "670e113e-ea85-41d4-9304-6ec044a88bc5",
            geographyVolume: "f39b87ea-a536-47d1-9bb4-fe6166c2486d",
            geographyForecast: "4bb0399f-380c-4fd6-ba58-3faa0245f926",
            geographyImpact: "97527d69-e070-4f32-999f-4aac7a38a461",
            geographyTransferred: "c49f93d0-bcfa-40d5-9dba-3f32da6162af"
        }
    },
	jobs:
    {
        types:
        {
            demographics: 0,
            closestStore: 2,
			mapBook: 3,
            custom: 5,
            customTypes: 
            {
                managementReport: 5,
                voidAnalysis: 7,
                mobility: 10,
                demographicReport: 10,
                importExport: 14,
                adHocDataSource: 15,
                pointReports: 17,
                submitOfflinePackage: 20,
                analyticsProjection: 21,
                createOfflinePackage: 22,
                trip2Trade: 24,
                ciDashboardReport: 25,
                bulkAnalyticsProjection: 26,
                ciCannibalization: 29,
                externalAnalyticsProjection: 30,
                ciCustomChannels: 31,
                analyticsRelocation: 32,
                ciKeyInsightsReport: 33                
            }
        },
        statuses:
        {
            pending: 0,
            processing: 1,
            completed: 2,
            completedWithErrors: 3,
            failed: 4
        }
	},
    notifications:
    {
        types:
        {
            message: 0,
            job: 1,
            map: 2,
            task: 3,
            offlinePackage: 4,
            geoFeeds: 5,
            siteTours: 6,
            trip2Trade: 7,
            adHocDataSource: 8,
            savedReport: 9,
        }
    },
    quickReports: {
        types:{
            bar: 1,
            pie: 2,
            none: 3,
            table: 4
        }
    },
	tasks:
    {
        statuses:
        {
            notStarted: 0,
            inProgress: 1,
            waiting: 2,
            completed: 3,
            deferred: 4
        },
        types:
        {
            system: 0,
            user: 1
        },
        priorities:
        {
            low: 0,
            medium: 1,
            high: 2,
            critical: 3
        },
        actions:
        {
            none: -1,
            openInfobox: 0,
            openEditForm: 1,
            loadCustomQuery: 2
        },
        objectTypes:
        {
            application: 0,
            point: 1,
            photo: 2,
            multimedia: 3,
            form: 4
        },
        sourceObjectTypes:
        {
            dataSource: 0,
            application: 1
        }
    },
    areaMeasurements:{
        squareInches: 1,
        squareFeet: 2,
        squareYards: 3,
        squareMiles: 4,
        squareMillimeters: 5,
        squareCentimeters: 6,
        sqaureMeters: 7,
        squareKilometers: 8,
        acres: 9
    },
    lengthMeasurements:
    {
        inches: 1,
        feet: 2,
        yards: 3,
        miles: 4,
        millimeters: 5,
        centimeters: 6,
        meters: 7,
        kilometers: 8
    },
    tradeAreas: {
        types:{
            ring: 'R',
            driveTime: 'T',
            driveDistance: 'D',
            geography: 'G',
            userDrawn: 'P',
            savedShape: 'S',
            dataFit: 'F',
            custom: 'C'
        }        
    },
	reportCategories:{
		demographicSummary: '070EB955-73AD-48F4-8A8B-B122687DFC44',
		demographicDetail: '7AC89628-FB7D-47D8-B07E-5F7804537454',
		demographicComparison: '7C49CD5F-3868-4474-8BE9-E9629452D0E7',
		demographicIndex: '31703D81-5DEB-46C5-B391-1B95E4B8DEA2',
		demographicQuadrant: 'AE084658-FE86-41F9-A237-A44DB655B2D0',
		pointDetail: 'F606DDC6-635F-401E-806F-7857A1260917',
		management: '4867B7B8-42BA-4043-9539-223B217EB49C',
		reportPackages: '55F5F649-ECAF-455E-88EA-CFBB1F741FA2',
		uberRetail: 'B12F2FE4-AA3C-4E5F-AB9B-8EE8F3B57D21',
		marketShare: 'BF80F9C8-AF3D-46F9-82CE-3D7B617F7439'
	},
    reportCategoryTypes:{
        demographicSummary: 0,
        demographicDetail: 1,
        demographicComparison: 2,
        demographicIndex: 3,
        demographicQuadrant: 4,
        pointDetail: 5,
        management: 6,
        reportPackages: 7,
        voidAnalysis: 9,
        uberRetail: 10,
        marketShare: 11,
        mapBook: 12,
        analyticsProjection: 13
    },
    drivingDirections: {
        travelModes:{
            driving: 'DRIVING',
            transit: 'TRANSIT',
            walking: 'WALKING',
            cycling: 'BICYCLING'
        },
        units: {
            metric: 0,
            imperial: 1,
        }
    },    
	symbols:{
		types: {
			stock: 0,
            custom: 1,
            shapeStyle: 2
        }
    },
    mapTools:{
        measureDistance: 1,
        measureArea: 2,
        addText: 3,
        addPushpin: 4,
        lineTool: 5,
        drawPolygon: 6,
        drawCircle: 7,
        drawRectangle: 8,
        autoLabel: 9,
        getCoordinates: 10,
        reverseGeocode: 11
    },
    demographicShape:
    {
        encodedString: 0,
        wkt: 1
    },
    encodedString:
    {
        wkt: 0,
        gml: 2,
        google: 3,
        geoJson: 4
    },
    customerDataRenderers:{
        pin: 0,
        range: 1,
        desireLine: 2,
        heat: 3,
        bubble: 4
    },
    sqlDataTypes:
    {
        bigInt: 0,
        binary: 1,
        bit: 2,
        sqlChar: 3,
        dateTime: 4,
        decimal: 5,
        sqlFloat: 6,
        sqlImage: 7,
        sqlInt: 8,
        money: 9,
        nChar: 10, 
        nText: 11, 
        nVarChar: 12, 
        real: 13,
        uniqueIdentifier: 14,
        smallDateTime: 15,
        smallInt: 16,
        smallMoney: 17,
        text: 18,
        timestamp: 19,
        tinyInt: 20,
        varBinary: 21,
        varChar: 22,
        variant: 23,
        xml: 25, 
        udt: 29,
        structured: 30,
        date: 31,
        time: 32,
        dateTime2: 33,
        dateTimeOffset: 34
    },
    columnMappingTypes: {
        none: 0,
        address: 1,
        city: 2,
        state: 3,
        zip: 4,
        latitude: 5,
        longitude: 6,
        spatial: 7
    },
    geocoders: {
        noProduct: -1,
        bingBulkGeocoding: 13
    },
    geocodingBehavior: {
        fillIn: 0,
        overwrite: 1
    },
    getSqlDataTypeNameFromEnum: (num) => {
        switch (num)
        {
            case constants.sqlDataTypes.bigInt:
                return translate("number");
            case constants.sqlDataTypes.binary:
                return translate("special");
            case constants.sqlDataTypes.bit:
                return translate("yes_no");
            case constants.sqlDataTypes.sqlChar:
                return translate("text");
            case constants.sqlDataTypes.dateTime:
                return translate("date_time");
            case constants.sqlDataTypes.decimal:
                return translate("number");
            case constants.sqlDataTypes.sqlFloat:
                return translate("number");
            case constants.sqlDataTypes.sqlImage:
                return translate("special");
            case constants.sqlDataTypes.sqlInt:
                return translate("number");
            case constants.sqlDataTypes.money:
                return translate("currency");
            case constants.sqlDataTypes.nChar:
                return translate("text");
            case constants.sqlDataTypes.nText:
                return translate("text");
            case constants.sqlDataTypes.nVarChar:
                return translate("text");
            case constants.sqlDataTypes.real:
                return translate("number");
            case constants.sqlDataTypes.uniqueIdentifier:
                return translate("text");
            case constants.sqlDataTypes.smallDateTime:
                return translate("date_time");
            case constants.sqlDataTypes.smallInt:
                return translate("number");
            case constants.sqlDataTypes.smallMoney:
                return translate("currency");
            case constants.sqlDataTypes.text:
                return translate("text");
            case constants.sqlDataTypes.timestamp:
                return translate("date_time");
            case constants.sqlDataTypes.tinyInt:
                return translate("number");
            case constants.sqlDataTypes.varBinary:
                return translate("special");
            case constants.sqlDataTypes.varChar:
                return translate("text");
            case constants.sqlDataTypes.variant:
                return translate("special");
            case constants.sqlDataTypes.xml:
                return translate("special");
            case constants.sqlDataTypes.udt:
                return translate("special");
            case constants.sqlDataTypes.structured:
                return translate("special");
            case constants.sqlDataTypes.date:
                return translate("date_time");
            case constants.sqlDataTypes.time:
                return translate("date_time");
            case constants.sqlDataTypes.dateTime2:
                return translate("date_time");
            case constants.sqlDataTypes.dateTimeOffset:
                return translate("date_time");
            default:
                return translate("special");
        }
    },
    getColumnMappingTypeNameFromEnum: (num) => {
        switch(num)
        {
            case constants.columnMappingTypes.address:
                return translate("address");
            case constants.columnMappingTypes.city:
                return translate("city");
            case constants.columnMappingTypes.state:
                return translate("state");
            case constants.columnMappingTypes.zip:
                return translate("zip");
            case constants.columnMappingTypes.latitude:
                return translate("latitude");
            case constants.columnMappingTypes.longitude:
                return translate("longitude");
            case constants.columnMappingTypes.spatial:
                return translate("spatial");
            case constants.columnMappingTypes.none:
            default:
                return translate("none");
        }
    },
    getZoomLevelNameFromEnum: (num) => {
        if (num < 1)
            return num;
        else if (num < 3)
            return num + " (" + translate("world") + ")";
        else if (num < 6)
            return num + " (" + translate("country") + ")";
        else if (num < 11)
            return num + " (" + translate("region") + ")";
        else if (num < 16)
            return num + " (" + translate("city") + ")";
        else if (num <= 20)
            return num + " (" + translate("street") + ")";
        else
            return num;
    },      
    textAlignment: {
        left: 0,
        center: 1,
        right: 2,
        top: 3,
        middle: 4,
        bottom: 5
    },
    labelPosition: {
        topLeft: 1,
        topMiddle: 2,
        topRight: 3,
        middleRight: 4,
        bottomRight: 5,
        bottomMiddle: 6,
        bottomLeft: 7,
        middleLeft: 8,
        middleMiddle: 9
    },
    reports:{
        output:{
            excel: 0,
            pdf: 1
        },
        filters:{
            type:{
                date: 0,
                organizationHierarchy: 1,
                geoSecurity: 2,
                standardGeography: 3,
                user: 4,
                custom: 5
            },
            dataType:{
                date: 0,
                text: 1,
                listFromDB: 2,
                listFromService: 3,
                range: 4
            },
            paramType:{
                min: 0,
                max: 1,
                value: 2,
                range: 3
            }            
        }
    },    
    bulkReports: {
        dataSources: {
            excel: 'Excel',
            datasource: 'Datasource'
        },
        calculationMethods: {
            euclidian: 0,
            driveDistance: 1,
            driveTime: 2
        },
        excelOutputFormat: {
            single: 'Single',
            multiple: 'Multiple'
        }
    },
    dataSetType: {
        XLS: 0,
        XLSX: 1,
        SHAPE: 2,
        TAB: 3,
        KML: 4,
        CSV: 5,
        TSV: 6,
        KMZ: 14
    },
    mimeTypes: {
        XLS: "application/vnd.ms-excel", 
        XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", 
        SHAPE: "application/vnd.ESRI.shp", 
        TAB: "application/vnd.MapInfo.tab", 
        KML: "application/vnd.google-earth.kml+xml", 
        CSV: "text/csv", 
        TSV: "text/tab-separated-values", 
        VRT: "text/plain", 
        SHAPE_PRJ: "application/vnd.ESRI.prj",
        SHAPE_SHX: "application/vnd.ESRI.shx",
        SHAPE_DBF: "application/vnd.ESRI.dbf",
        TAB_DAT: "application/vnd.MapInfo.dat", 
        TAB_ID: "application/vnd.MapInfo.id", 
        TAB_MAP: "application/vnd.MapInfo.map",
        KMZ: "application/vnd.google-earth.kmz",

    },
    dataSourceType: {
        AdminPoint: 0,
        UserAdHocPoint: 1,
        AdminShape: 2,
        UserAdHocShape: 3
    },
    filterTypes:
    {
        specificValue: 0,
        formula: 1
    },
    formulaTypes:
    {
        specificValue: 0,
        field: 1
    },
    operators: {
        contains: 0,
        notContains: 1,
        startsWith: 2,
        endsWith: 3,
        equals: 4,
        notEquals: 5,
        lessThan: 6,
        greaterThan: 7,
        lessThanEquals: 8,
        greaterThanEquals: 9,
        between: 10,
        notBetween: 11,
        notStartsWith: 12,
        notEndsWith: 13,
        isNull: 14,
        isNotNull: 15,
        isEmpty: 16,
        isNotEmpty: 17,
        isAnyOf: 18,
        isNoneOf: 19
    },
    operations: {
        none: 0,
        add: 1,
        subtract: 2,
        divide: 3,
        multiply: 4
    },
    expressions: {
        none: 0,
        and: 1,
        or: 2,
        andNot: 3,
        orNot: 4
    },
    competitiveInsights: {
        tradeAreas: {
            visitsCount: 0,
            visitsHousehold: 1,
            visitsPercent: 2,
            polygonPercent: 3,
            totalVisitsByGeography: 4,
            householdPenetrationByGeography: 5,
            totalVisitsByDesireLines: 6
        },
        dashboards: {
            siteDetail: '3A565B8F-AD92-49D9-B6AE-B6E84B6022ED',
            crossShop: '9763CB51-BC50-4A99-B108-BAADF7456A8B',
            marketShare: '408FC562-08D7-4E39-91FA-A785F7635034',
            demographics: '67B7E2C7-005D-42FF-97E5-5D1F9F9BFD92',
            multiSite: '251953ED-F75D-4025-9FF8-B6AA212E1ECA',
            report: '85C63B4E-7CBB-4880-9131-FA988C59E989'
        },
        chartTypes: {
            bar: 'Bar',
            pie: 'Pie',
            grid: 'Grid',
            line: 'Line'
        },
        marketType: {
            site: 0,
            geography: 1
        },
        indexGrade: {
            chainIndexGrade: 0,
            chainMarketIndexGrade: 1,
            channelIndexGrade: 2,
            channelMarketIndexGrade: 3
        },
        visuals: {
            chain: 0,
            channel: 1,
            chainMarket: 2,
            channelMarket: 3
        },
        reportedObjectTypes: {
            point: 0,
            geoFence: 1
        },
        channelTypes: {
            base: 0,
            user: 1,
            admin: 2
        }
    },
    print:{
        outputTypes:{
            excel: 1,
            pdf: 2,
            png: 3
        },
        legendBehavior:{
            autofit: 0,
            wrap: 1,
            separate: 2
        },
        legendLocation:{
            none: 'None',
            topLeft: 'Top Left',
            topRight: 'Top Right',
            bottomLeft: 'Bottom Left',
            bottomRight: 'Bottom Right'
        },
        orientation:{
            landscape: 0,
            portrait: 1
        },
        contentTypes:{
            customText: '[Custom Text]',
            companyLogo: '[Company Logo]',
            currentDate: '[Date]',
            aerialDate: '[Aerial Date]'
        }
    },    
    geoFeed:
    {
        geoRss: 0,
        kml: 1,
        geoJson: 2,
        wkt: 3,
        gpx: 4
    },    
    themes:
    {
        parcel: -999,
        tmxline: -9999
    },
    mapBooks:
    {
        type: {
            admin: 0,
            user: 1  
        },
        outputFormat: {
            onePDF: 0,
            multiplePDFs: 1
        },
        executionType: {
            selection: 0,
            dataSource: 1
        },
        smartTags:
        {
            pointName: 0,
            latitude: 1,
            longitude: 2,
            address: 3,
            city: 4,
            state: 5,
            zip: 6
        },
        mapBookPointType: {
            point: 0,
            pushpin: 1,
            shape: 2
        },
        mapBookGridFilter: {
            all: 0,
            user: 1,
            admin: 2
        },
        mapBookPageType: {
            adHocMap: 1,
            demographicReport: 2,
            pointDetailReport: 3,
            savedMap: 4,
            fixedSavedMap: 5,
            demographicQuadrant: 6,
            demographicComparison: 7
        },
        mapBookTradeAreaType: {
            none: 0,
            default: 1,
            custom: 2
        },
        mapBookBuilderPanelType: {
            tradeArea: 0,
            adHocMap: 1,
            tag: 2,
            pdr: 3
        },
        adHocMap:{
            titleTypes:{
                aerialDate: 0,
                date: 1,
                logo: 2,
                text: 3
            },
            legendLocation:{
                none: -1,
                topLeft: 0,
                topRight: 1,
                bottomLeft: 2,
                bottomRight: 3
            },
            legendBehavior:{
                none: 0,
                autofit: 1,
                wrap: 2,
                separate: 3
            },
            customerMaps:
            {
                pin: 0,
                marketShare: 1,
                desireLines: 2,
                heatMap: 3,
                marketShareBG: 100,
                marketShareZip: 101
            }
        },
        validation:
        {
            objectTypes:
            {
                demographicReport: 2,
                pointDetailReport: 3,
                savedMap: 4,
                fixedSavedMap: 5,
                demographicQuadrant: 6,
                pointSource: 7,
                rangeThematic: 8,
                dotDensityThematic: 9,
                polygonThematic: 10,
                driveTimeEngine: 11
            },
            errorCodes:
            {
                doesNotExistOrNoAccess: 0
            }
        },        
    },
    selections:{
        types: {
            new: 0,
            append: 1,
            inverse: 2,
            remove: 3
        },
        behaviors:{
            none: -1,
            single: 0,
            contains: 1,
            intersects: 2
        }
    },
    scenarios: {
        types:{
            projections: '37AFCA8B-8FA1-4441-814C-C4519210E5CE'
        }
    },
    sharing: {
        all: -1,
        user: 0,
        shared: 1
    },
    sharingPeerType: {
        all: -1,
        user: 0,
        shared: 1
    },    
    sharingObjectType: {
        adHocDataSource: 0,
        map: 1,
        geoFeed: 2,
        siteTour: 3,
        pointDataSourceFilter: 4,
        SavedReport: 5,
    },       
    geometryCollectionDownloadType: {
        combined: 0,
        separate: 1
    },
    exceptions: {
        tokenExpired: 1000002
    },
    inrixTripType: {
        origin: 1,
        destination: 2,
        either: 3
    },
    trip2TradeFilterLimitType: {
        none: 0,
        distance: 1,
        driveTime: 2
    },
    trip2TradeDriveTimeMeasurement: {
        seconds: 1,
        minutes: 2
    },
    analogTypes: {
        locked: 0,
        saved: 1
    },
    marketOptimization: {
        geographyModes: {
            standard: 0,
            handdrawn: 1
        }   
    },
    tagObjectType: {
        photo: 0,
        comment: 1,
        multimedia: 2,
        savedPolygon: 3,
        formField: 4,
        trip2Trade: 5
    },
    tagSourceObjectType: {
        dataSource: 0
    },
    tagType: {
        placeholder: 0,
        hashtag: 1
    }
};