import { useCallback, useEffect, useState } from 'react';

// Third party imports
import SelectBox from 'devextreme-react/select-box';
import DataSource from 'devextreme/data/data_source';

// App imports
import { validation } from '../validation/validation';
import { Icon, icons } from '../icon/icon';

const _ = require("lodash");

export function DropDown({id, className, items, display, valueProperty, selected, searchEnabled = false, label, disabled, width, height, itemRender, fieldRender, onChange, onSelect, onClick, dropDownContainer, group = 'group', grouped = false, placeholder, inputAttr = {}, validator, isRequired = false}) {

    var classes = ['app-drop-down'];

    if (className)
        classes.push(className);

    var dataSource = items;

    if (grouped)
        dataSource = new DataSource({
            store: {
                type: 'array',
                data: items,
                key: valueProperty,
            },
            group: group,
        });

    const handleFocusOut = () => {
        if(validator) {
            validator.ref.current.instance.option('isDirty', true);
            validator.ref.current?.instance.validate()
        }
    };

    var dropDownOptions = { wrapperAttr: { class: 'app-drop-down-item-disabled' } };

    if (_.isString(dropDownContainer))
        dropDownOptions.container = dropDownContainer;

    return <div className={classes.join(' ')}>
        <div className='app-drop-down-container'>

            <SelectBox dataSource={dataSource}
                wrapItemText={true}
                id={id ? id : null}
                searchEnabled={searchEnabled}
                displayExpr={display}
                valueExpr={valueProperty}
                placeholder={placeholder}
                value={selected}
                label={label}
                stylingMode='outlined'
                inputAttr={inputAttr}
                disabled={disabled}
                grouped={grouped}
                group={grouped ? group : null}
                width={width? width : undefined}
                height={height? height : '30px'}
                dropDownOptions={dropDownOptions}
                itemRender={itemRender? itemRender : null}
                fieldRender={fieldRender? fieldRender : null}
                onFocusOut={handleFocusOut}
                onValueChanged={(e)=>{
                    if (e.value == null || JSON.stringify(e.value) === JSON.stringify(e.previousValue))
                        return

                    if (onChange)
                        onChange({
                            value: e.value,
                            userChanged: e.event !== undefined
                        });
                }}
                onSelectionChanged={(e)=>{
                    if (onSelect)
                        onSelect({
                            value: e.selectedItem
                        });
                }}
                onItemClick={(e)=>{
                    if (onClick)
                        onClick({
                            value: e.itemData
                        });
                }}>
                {validation.getValidator(validator)}              
            </SelectBox>
            {isRequired ? <span className='required'>{isRequired ? <Icon className={'app-form-required'} icon={icons.asterisk}/> : ''}</span> : ''}
        </div>
    </div>    
}