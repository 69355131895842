// App imports
import { map } from '../components/app/map/map'; 
import { layerActions } from '../components/app/layers/layer/layer';
import { constants } from '../utils/constants';
import { translate } from '../utils/translation';
import { mapWindow } from '../components/app/map/mapWindow/mapWindow';
import { Selections } from '../components/app/selections/selections';
import { errorToast } from '../components/base/toast/toast';
import { userPreferences } from '../components/app/app';
import { Button } from '../components/base/button/button';
import { icons } from '../components/base/icon/icon';
import { reports } from '../components/app/reports/reports';
import { competitiveInsights } from '../modules/competitiveInsights';
import { bulkInfo } from '../modules/bulkInfo';
 
const _ = require("lodash");

export const selections = {
	active: false,
	maxSelections: 50,	
	getLayer: () =>{
		return map.layers.find(layer => layer.type === constants.layers.types.selection);
	},	
	getEntities: () =>{
		var layer = selections.getLayer();	

		if (layer.entities.length === 0)
			return [];
		
		return layer.entities.map(x => { return x.metaData.entity; });			
	},		
    create: (o) => {
		
		var toolbars = [];
		var actions = [];	

		if (userPreferences.AllowMapBooks)
			actions.push(
				<Button className={`app-legend-layer-toolbar-action`} theme='simple' icon={icons.bookBookmark} tooltip={translate('map_books')} onClick={()=>{ 
					reports.showMapbook(selections.getEntities());
				}} />
			);

		actions.push(
			<Button theme='simple' icon={icons.edit} tooltip={translate('bulk_edit')} onClick={()=>{ 
				const layer = selections.getLayer();
				bulkInfo.generateBulkEdit({ title: layer.text, customQueryId: layer.id, type: layer.type });
			}} />
		);

		toolbars.push({ actions: actions });		
	
		if (userPreferences.AllowCompetitiveInsights)
			toolbars.push({ actions: [
						{type: constants.competitiveInsights.tradeAreas.visitsCount, tooltip: translate('show_on_market_share_report'), icon: icons.pieChart, action: ()=>{competitiveInsights.marketShareDashboard();}},
						{type: constants.competitiveInsights.tradeAreas.visitsHousehold, tooltip: translate('show_on_customer_profile_report'), icon: icons.peopleGroup, action: ()=>{competitiveInsights.customerProfileDashboard();}},
						{type: constants.competitiveInsights.tradeAreas.visitsPercent, tooltip: translate('show_on_multi_site_report'), icon: icons.mapLocation,  action: ()=>{competitiveInsights.multiSiteDashboard();} },
						{type: constants.competitiveInsights.tradeAreas.totalVisitsByGeography, tooltip: translate('range_map_of_total_visits'), icon: icons.personWalking, action: ()=>{competitiveInsights.totalVisitsGeography(selections.getEntities());} },
						{type: constants.competitiveInsights.tradeAreas.householdPenetrationByGeography, tooltip: translate('range_map_of_hh_pen'), icon: icons.house, action: ()=>{competitiveInsights.householdPenetration(selections.getEntities());} },
						{type: constants.competitiveInsights.tradeAreas.totalVisitsByDesireLines, tooltip: translate('desire_line_of_visits'), icon: icons.bars, action: ()=>{competitiveInsights.totalVisitsDesireLine(selections.getEntities());} },
					].map((ciAction, i) =>{
						return <Button key={i} theme='simple' disabled={ciAction.disabled} icon={ciAction.icon} tooltip={ciAction.tooltip} text={''} className={`app-legend-layer-toolbar-action`}
									onClick={ ciAction.action} 
								/>
				}) });

        var selectionLayer = map.addLayer({
			text: translate('selections'),
			group: constants.layers.groups.selection,
			type: constants.layers.types.selection,
			toolbars: toolbars,
			actions: [{
				id: layerActions.clear,
				onClick: () =>{
					selectionLayer.clear();
				}
			}],
            onChange: (c)=>{
				o.onRefresh({
					layers: [selectionLayer]
				});
			}
		});

        o.onRefresh({
            layers: [selectionLayer]
        });
    },	
    refresh: () => {

        var layer = selections.getLayer();
		
		layer.subText = `${translate('count_currently_select').replace('{count}', layer.entities.length)}`;

        layer.legend = layer.entities.map(entity => {

			var base = {
                text: entity.text,
				subText: entity.subText,
                actions: [{
                    id: layerActions.zoom,
                    onClick: () =>{
						map.locate({ entity: entity.metaData.entity });
                    }
                },
                {
                    id: layerActions.delete,
                    onClick: () =>{
                        entity.dispose();
                        selections.refresh();
                    }
                }]
            };

			switch(entity.type){
				case constants.entities.selectedPoint:
					base.icon = entity.metaData.entity.image;
					break;
				case constants.entities.selectedShape:
					base.color = entity.metaData.entity.fillColor;
					break;
			}     
			
			return base;
        });		
    },
	append: (entities) =>{

		var selectionLayer = selections.getLayer();
		var currentEntities = selectionLayer.entities.map(x => { return x.metaData.entity; });		
		
		entities.forEach(entity =>{
			if (currentEntities.filter(x => x.id === entity.id && x.layer.id === entity.layer.id).length === 0)
				currentEntities.push(entity);
		});

		return currentEntities;
	},
	inverse: (entities) =>{
		
		var selectionLayer = selections.getLayer();

		var resultEntities = [];
		var removedEntities = [];
		var currentEntities = selectionLayer.entities.map(x => { return x.metaData.entity; });		

		// Remove overlap
		currentEntities.forEach(currentEntity =>{
			if (entities.filter(entity => entity.id === currentEntity.id && entity.layer.id === currentEntity.layer.id).length === 0)
				resultEntities.push(currentEntity);
			else
				removedEntities.push(currentEntity);
		});

		// Add in new
		entities.forEach(entity =>{
			if (currentEntities.filter(x => x.id === entity.id && x.layer.id === entity.layer.id).length === 0 &&
				removedEntities.filter(x => x.id === entity.id && x.layer.id === entity.layer.id).length === 0)
				resultEntities.push(entity);
		});

		return resultEntities;
	},
	remove: (entities) =>{

		var selectionLayer = selections.getLayer();

		var resultEntities = [];

		selectionLayer.entities.map(x => { return x.metaData.entity; }).forEach(currentEntity =>{
			if (entities.filter(entity => entity.id === currentEntity.id && entity.layer.id === currentEntity.layer.id).length === 0)
				resultEntities.push(currentEntity);
		});

		return resultEntities;
	},
	setEntities: (entities) =>{

		if (entities.length > selections.maxSelections){
			errorToast(translate('max_selections_error').replace('count', selections.maxSelections));
			return;
		}			

		var selectionLayer = selections.getLayer();
		selectionLayer.clear();
		entities.forEach(entity =>{

			switch(entity.type){
				case constants.entities.point:
				case constants.entities.pushpin:
					entity.metaData.selectionEntity = selectionLayer.addEntity({
						id: `${entity.layer.id}_${entity.id}`,
						type: constants.entities.selectedPoint,
						text: entity.text,
						subText: entity.layer.text,
						location: entity.location,
						image: entity.image,
						anchor: entity.anchor,
						width: entity.width,
						height: entity.height,
						metaData: {								
							entity: entity
						}, 
						listeners: [{
							type: constants.listeners.click,
							action: (e) =>{
								e.entity = entity;
								entity.click(e); 
							}
						}]
					});
					break;
				case constants.entities.polygon:
					entity.metaData.selectionEntity = selectionLayer.addEntity({
						id: `${entity.layer.id}_${entity.id}`,
						type: constants.entities.selectedShape,
						text: entity.text,
						subText: entity.layer.text,
						paths: entity.paths,
						metaData: {								
							entity: entity
						}, 
						listeners: [{
							type: constants.listeners.click,
							action: (e) =>{
								e.entity = entity;
								entity.click(e); 
							}
						}]
					});
					break;
				case constants.entities.polyline:
					return;
			}
			
		});

		selections.refresh();
	},
	selectEntity: (entity) =>{
		selections.setEntities(
			selections.inverse([
				entity
			])	
		);
	},
	select: () =>{

		selections.active = true;

		map.startDrawing({
            type: constants.entities.polygon,
			allowEntityClicking: true,
			onTooltipRender: (result) =>{

                if (result.locations.length < 3)
					return {
						sections: [<div>{translate('add_at_least_3_vertices')}</div>]
					};

				return { sections: [] };
            },
            onFinish: (shape) =>{
				selections.selectionFinish({ shape });				
            }
        });
	},
	selectionFinish: ({ shape }) =>{
		selections.active = false;

		if (!_.isArray(shape.locations) || shape.locations.length === 0)
			return;

		var selectionLayer = selections.getLayer();

		var layersSelected = [];
		var selectedEntities = [];
		var imageWidth = 0;

		map.layers.filter(layer => layer.type === constants.layers.types.point || layer.type === constants.layers.types.cosmetic).forEach(layer =>{
			
			if (selectedEntities.length === selections.maxSelections)
				return;
			
			layer.entities.forEach(entity => {
				if (entity.type !== constants.entities.point && entity.type !== constants.entities.pushpin && 
					entity.type !== constants.entities.polygon && entity.type !== constants.entities.polyline)
					return;
				
				if (entity.visible === false)
					return;

				if (selectedEntities.length === selections.maxSelections)
					return;

				if ((entity.type === constants.entities.point || entity.type === constants.entities.pushpin) && !map.polygonContainsPoint(shape.locations, entity.location))
					return;

				if ((entity.type === constants.entities.polygon || entity.type === constants.entities.polyline) && !map.polygonIntersectsPolygon(entity.paths, shape.locations))
					return;

				if (!layersSelected.find(x => x === layer))
					layersSelected.push(layer);

				selectedEntities.push(entity);

				if (imageWidth <= entity.width)
					imageWidth = entity.width;

			});
		});				
		
		if (layersSelected.length === 1 && selectionLayer.entities.length === 0)
			selections.setEntities(selectedEntities);
		else if (layersSelected.length > 1 || selectionLayer.entities.length > 0)
			mapWindow.show({
				content: <Selections 
					entities={selectedEntities}
					onSelect={(entities)=>{
						selections.setEntities(entities);
						mapWindow.hide();
					}}
				/>,
				title: translate('specify_selection_action'),
				resizable: false
			});
	}
} 