import { useEffect, useMemo, useState } from 'react';


// Third party imports
import ColorBox, { ColorBoxTypes } from 'devextreme-react/color-box';

// App imports
import { DropDown } from '../../base/dropDown/dropDown';
import { TextBox } from '../../base/textBox/textBox';
import { Button } from '../../base/button/button';
import { icons } from '../../base/icon/icon';
import { translate } from '../../../utils/translation';
import { constants } from '../../../utils/constants';
import { TagBoxDropDown } from '../../base/dropDown/tagBoxDropDown';
import { Loader } from '../../base/loader/loader';
import smartTags from '../../../modules/smartTags';
import { tradeAreas } from '../../../modules/tradeAreas';
import { helpers } from '../../../utils/helpers';
import { ConfirmButton } from '../../base/confirmButton/confirmButton';

const _ = require("lodash");

export function ShapeEdit({entity, tradeArea = null, showName = true, isSaveable = false, isSaved = false}) {
    const [name, setName] = useState(entity.text);    
    const [fillColor, setFillColor] = useState(entity.type === constants.entities.polyline ? null : entity.fillColor);
    const [strokeColor, setStrokeColor] = useState(entity.strokeColor);
    const [strokeWidth, setStrokeWidth] = useState(entity.strokeWidth);
    const [loaded, setLoaded] = useState(true);
    const [placeholders, setPlaceholders] = useState([]);
    const [selectedPlaceholders, setSelectedPlaceholders] = useState([]);  
    
    useEffect(() => {
        const fetchData = async () => {
            setLoaded(false);

            var tags = await smartTags.GetAvailableTags({
                id: entity.layer.tradeAreas.find(x => x.id === entity.id).polygon.id, 
                parentObjectId: entity.layer.metaData.pointId, 
                sourceObjectId: entity.layer.metaData.dataSourceId, 
                sourceObjectType: constants.tagSourceObjectType.dataSource, 
                type: constants.tagObjectType.savedPolygon
            });
  
            setPlaceholders(tags.availablePlaceholders);
            setSelectedPlaceholders(tags.assignedPlaceholders);

            setLoaded(true);;
        };

        if (tradeArea && entity.layer.metaData.allowShapeEdit === true && entity.layer.metaData.dataSourceId)         
            fetchData();       
        else
            setLoaded(true);

    }, [entity, tradeArea]);

    const onSave = () =>{
        entity.text = name;
        
        if (entity.type !== constants.entities.polyline)
            entity.fillColor = fillColor;
        
        entity.strokeColor = strokeColor;
        entity.strokeWidth = strokeWidth;

        if (_.isFunction(entity.onEdit))
            entity.onEdit({
                text: name,
                fillColor: fillColor,
                strokeColor: strokeColor,
                strokeWidth: strokeWidth,
                strokeStyle: 'solid'
            });

        if (tradeArea && tradeArea.type === constants.tradeAreas.types.savedShape) {
            saveToDatabase();
        }
    };

    const saveToDatabase = async () => {
        setLoaded(false);

        tradeArea.name = name;
        tradeArea.fillColor = fillColor;
        tradeArea.lineColor = strokeColor;
        tradeArea.lineWidth = strokeWidth;
        tradeArea.strokeStyle = 'solid';
        tradeArea.label.title = name;
        if (tradeArea.savedType == null || tradeArea.savedType == '\u0000')
            tradeArea.savedType = tradeArea.type;

        var result = await tradeAreas.saveTradeArea({
            customQueryId: entity.layer.metaData.customQueryId, 
            dataSourceId: entity.layer.metaData.dataSourceId, 
            pointId: entity.layer.metaData.pointId, 
            tradeArea: tradeArea
        });

        if (_.isArray(result) && result.length > 0) {
            tradeArea.type = constants.tradeAreas.types.savedShape;
            tradeArea.polygon.id = result.find(x => x.isSelected).id;

            if (_.isArray(selectedPlaceholders))
            {
                await smartTags.UpdateTags({
                    id: tradeArea.polygon.id, 
                    parentObjectId: entity.layer.metaData.pointId, 
                    sourceObjectId: entity.layer.metaData.dataSourceId, 
                    sourceObjectType: constants.tagSourceObjectType.dataSource,
                    type: constants.tagObjectType.savedPolygon,
                    tags: selectedPlaceholders
                });
            }      
        }

        setLoaded(true);
    };

    const deleteFromDatabase = async () => {
        setLoaded(false);
        await tradeAreas.deleteTradeArea({
            customQueryId: entity.layer.metaData.customQueryId, 
            dataSourceId: entity.layer.metaData.dataSourceId, 
            pointId: entity.layer.metaData.pointId, 
            tradeAreaId: tradeArea.polygon.id
        });

        tradeArea.type = tradeArea.savedType;
        setLoaded(true);
    };

    return <div className='app-shape-edit-container'>
        <Loader loaded={loaded}/>
        <div className='app-shape-edit-button-container'>                    
            <Button theme='secondary' size='small' icon={icons.x} tooltip={translate('cancel')} onClick={() =>{
                if (_.isFunction(entity.onCancel))
                    entity.onCancel();
            }} />
            {
                tradeArea && tradeArea.type === constants.tradeAreas.types.savedShape ? <ConfirmButton theme='primary' size='small' align='right' icon={icons.check} tooltip={translate('save_to_database')} headerText={translate('save_to_database')} clearText={translate('confirm')} onConfirm={onSave}/>
                : <Button theme='primary' size='small' icon={icons.check} tooltip={translate('save')} onClick={onSave}/>
            } 
            {
                tradeArea && entity.layer.metaData.allowShapeEdit === true && entity.layer.metaData.dataSourceId ? 
                    tradeArea.type === constants.tradeAreas.types.savedShape ? 
                    <ConfirmButton className='delete-button' theme='primary' size='small' align='right' icon={icons.trash} tooltip={translate('delete_from_database')} headerText={translate('delete_from_database')} clearText={translate('confirm')} onConfirm={deleteFromDatabase}/>
                    : <Button theme='primary' size='small' icon={icons.database} tooltip={translate('save_to_database')} onClick={saveToDatabase}/> 
                : ''
            }   
        </div>
        {
            showName ? <TextBox className='app-trade-areas-name' label={translate('name')} value={name} onChange={(o) => {setName(o.value);}}/> : ''
        }
        <DropDown 
            label={translate('line_width')} 
            selected={strokeWidth} 
            fieldRender={(data) => {
                return <>
                        <img alt={translate('line_width')} className='app-shape-edit-line-width-image'/*style={}*/ src={data ? data.image : ''}/>
                        <TextBox readOnly={true} visible={false}/>
                    </>
             }}
            itemRender={(data) => { return <img alt={data.name} src={data.image} />}} 
            valueProperty='value'
            onChange={(o) => { setStrokeWidth(o.value) }}
            items={[1, 2, 3, 4, 5, 6].map((width) => { 
                return {
                    name: `LineWidth_${width}`,
                    value: width,
                    image: `https://tasonline.com/Content/Media/LineStyle/LineWidth_${width}.gif`
                }
            })}
        />
        {entity.type !== constants.entities.polyline ? <ColorBox 
            label={translate('fill_color')} 
            defaultValue={`rgba(${fillColor.r},${fillColor.g},${fillColor.b},${fillColor.a})`} 
            value={`rgba(${fillColor.r},${fillColor.g},${fillColor.b},${fillColor.a})`} 
            editAlphaChannel={true}
            onValueChange={(o) => {
                var colors = o.replace('rgba(','').replace('rgb(','').replace(')','').split(',');                
                setFillColor({ r: parseFloat(colors[0]), g:parseFloat(colors[1]), b: parseFloat(colors[2]), a: parseFloat(colors[3]) });
            }}
        /> : ''}        
        <ColorBox 
            label={translate('line_color')} 
            defaultValue={`rgba(${strokeColor.r},${strokeColor.g},${strokeColor.b},${strokeColor.a})`} 
            value={`rgba(${strokeColor.r},${strokeColor.g},${strokeColor.b},${strokeColor.a})`} 
            editAlphaChannel={true}
            onValueChange={(o) => {
                var colors = o.replace('rgba(','').replace('rgb(','').replace(')','').split(','); 
                setStrokeColor({ r: parseFloat(colors[0]), g:parseFloat(colors[1]), b: parseFloat(colors[2]), a: parseFloat(colors[3]) });
            }} 
        />
        {
            tradeArea && entity.layer.metaData.allowShapeEdit === true && entity.layer.metaData.dataSourceId ? <TagBoxDropDown items={placeholders} values={selectedPlaceholders} keyProperty='id' valueProperty='name' label={translate('placeholder')} onChange={(o) => {setSelectedPlaceholders(o)}}/> : ''
        }
    </div>
}