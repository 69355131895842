// React imports
import { useState, useEffect, useCallback } from 'react'

// App imports
import { Loader } from '../../base/loader/loader';
import { AddStore } from './addStore';
import { RemoveStore } from './removeStore';
import { AddNote } from './analogNotes/analogNotes';
import { ModalCard } from '../../base/modalCard/modalCard';
import { ModifyFilters } from './analogFilters/modifyFilters';
import { Button } from '../../base/button/button';
import { DropDown } from '../../base/dropDown/dropDown';
import { DataGrid } from '../../base/dataGrid/dataGrid';
import { icons } from '../../../components/base/icon/icon';
import { translate } from '../../../utils/translation';
import { helpers } from '../../../utils/helpers';
import { constants } from '../../../utils/constants';
import { errorToast, successToast } from '../../base/toast/toast';
import { legacyEndpoints } from '../../../services/legacyEndpoints';
import { analogs as analogsModule } from '../../../modules/analogs';
import { reports as reportsModule } from '../../../modules/reports';
import { map } from '../map/map';

const _ = require("lodash");

export var analogs

export function Analogs({ data, columns, settings, filters }) {
    
    const [generating, setGenerating] = useState(false);
    const [searchId, setSearchId] = useState(null);
    const [analogData, setAnalogData] = useState(data);
    const [analogColumns, setAnalogColumns] = useState(columns);
    const [analogSettings, setAnalogSettings] = useState(settings);
    const [activeFilters, setActiveFilters] = useState({ dirty: false, filters: filters });
    //const [availableTemplates, setAvailableTemplates] = useState([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState(null);
    const [selectedTemplateName, setSelectedTemplateName] = useState(null);
    const [forcedStores, setForcedStores] = useState({ dirty: false, stores: [] });
    const [removedStores, setRemovedStores] = useState([]);
    const [outputTypes, setOutputTypes] = useState([]);
    const [outputType, setOutputType] = useState(null); 
    const [showModifyFilters, setShowModifyFilters] = useState(false);

    analogs = {
        purgeNotes: () => {
            analogsModule.purgeAnalogNotes({ isSavedAnalog: analogSettings.isSavedAnalog, savedAnalogId: analogSettings.id });
        }
    };

    const initSettings = useCallback(
        async () =>{

            setSearchId(analogSettings.defaultQuerySearch);

            var outputTypesList = reportsModule.getOutputTypes();
            setOutputType(outputTypesList[1]);
            setOutputTypes([...outputTypesList]);            

            var templates = await analogsModule.getAvailableTemplates({ customQueryId: analogSettings.customQueryId, pointId: analogSettings.pointId });

            if (_.isArray(templates) && templates.length > 0) {
                var template = templates.find(x => x.ID === analogSettings.templateId);
                template = _.isObject(template) ? template : templates[0];
                setSelectedTemplateId(template.ID);
                setSelectedTemplateName(template.Name); 
            }

            //setAvailableTemplates(templates);

        },
        [analogSettings]
    );

	useEffect(() =>{	
        initSettings();
	}, [initSettings]);

	useEffect(() =>{	
		
        // keep track of any removed and manually added stores in the analog data
        if (!_.isArray(data) || data.length === 0)
            return;

        var removed = [];
        var added = [];

        data.forEach(row => {
            if (row.metaData.isManualEntry)
                added.push({ Id: row.key, Reason: row.metaData.reasonForManualEntry, IsChecked: true });
            else if (!row.metaData.isChecked)
                removed.push({ Id: row.key, Reason: row.metaData.reasonForUnchecked });
        });

        setRemovedStores(removed);
        setForcedStores({ dirty: false, stores: added });
        
	}, [data]);

	const syncRemovedStores = useCallback(
        (o) => {

            // apply any removed stores to the analog data
            removedStores.forEach(removed => {
                const store = o.data.find(x => x.key === removed.Id);

                if (_.isObject(store)) {
                    store.metaData.isChecked = false;
                    store.metaData.reasonForUnchecked = removed.Reason;
                }
            });

            return [...o.data];

        }, 
        [removedStores]
    );

    const toggleSelect = useCallback(
        (o) => {
            const selectStore = analogData.find(x => x.key === o.key);

            if (_.isObject(selectStore)) {
                selectStore.metaData.isChecked = !selectStore.metaData.isChecked;
                selectStore.metaData.reasonForUnchecked = o.reason ? o.reason : '';
                
                if (selectStore.metaData.isChecked)
                    setRemovedStores(removedStores.filter(x => x.Id !== o.key));
                else
                    setRemovedStores([...removedStores, { Id: o.key, Reason: o.reason }]);

                setAnalogData([...analogData]);
            }
        },
        [analogData, removedStores]
    );

    const createSelectButton = useCallback(
        (options) =>{

            return (
                !options.metaData.isSubjectSite ? // the subject site will not have a key value, and we don't display a select button
                    options.metaData.isManualEntry ?
                        <Button 
                            className={'app-bulk-info-zoom'} 
                            theme='action' 
                            icon={icons.minus} 
                            tooltip={options.metaData.reasonForManualEntry} 
                            disabled={analogSettings.isLocked}
                            onClick={() => { 
                                const addStore = forcedStores.stores.find(x => x.Id === options.key);
                                if (_.isObject(addStore)) {
                                    setForcedStores({ dirty: true, stores: forcedStores.stores.filter(x => x.Id !== addStore.Id) });
                                }
                            }} 
                        />
                    : 
                    <>
                        <Button 
                            id={`app-analogs-remove-store-${options.key}`}
                            className={'app-bulk-info-zoom'} 
                            theme='action' 
                            icon={options.metaData.isChecked ? icons.squareCheck : icons.squareEmpty} 
                            tooltip={options.metaData.isChecked ? translate('exclude') : options.metaData.reasonForUnchecked} 
                            disabled={analogSettings.isLocked}
                            onClick={() => { 
                                // when selecting a store, we don't need to provide a reason, so toggle straight away
                                if (!options.metaData.isChecked)
                                    toggleSelect({ key: options.key });
                            }} 
                        />
                    </>
                    : '' )
        },
        [forcedStores, analogSettings.isLocked, toggleSelect]
    );

    const createActions  = useCallback(
        (o) =>{
            return (
                <>
                    <Button 
                        className={'app-analogs-zoom'} 
                        theme='action' 
                        icon={icons.magnifyingGlass} 
                        tooltip={translate('zoom')} 
                        disabled={!_.isObject(o.metaData.location)}
                        onClick={() => { 
                            if (o.metaData.drawCosmeticPoint) {
                                //TBD
                            }
                            map.locate({ location: o.metaData.location });
                        }} 
                    />
                    <Button 
                        id={`app-analogs-notes-${o.key}`}
                        className={'app-bulk-info-zoom'} 
                        theme='action' 
                        icon={icons.stickyNote} 
                        tooltip={translate('notes')} 
                    />
                </>
            )
        },
        []
    );

    useEffect(() => {

        if (!_.isArray(columns) || columns.length === 0)
            return;

        var newColumns = [...columns];

        newColumns.unshift({
            name: "actions",
            caption: translate('actions'),
            fixed: true,
            fixedPosition: "left",
            alignment: "center",
            allowFiltering: false,
            allowSorting: false,
            width: 81,
            onRender: (o) => { return createActions(o); }
        });

        newColumns.unshift({
            name: "select",
            caption: '',
            fixed: true,
            fixedPosition: "left",
            allowFiltering: false,
            allowSorting: false,
            width: 60,
            onRender: (o) => { return createSelectButton(o); }
        });
    
        setAnalogColumns(newColumns);
    
    }, [columns, createActions, createSelectButton]);

    const refreshAnalog = useCallback(
        async () =>{
    
            if (!forcedStores.dirty && !activeFilters.dirty)
                return;

            setGenerating(true);

            const results = await analogsModule.generateAnalog({
                templateId: selectedTemplateId,
                customQueryId: analogSettings.customQueryId,
                pointId: analogSettings.pointId,
                densityClass: null,
                densityParameters: null,
                filters: activeFilters.filters,
                forcedStores: forcedStores.stores,
                subjectSite: analogSettings.subjectSite,
                savedAnalogId: analogSettings.isSavedAnalog ? analogSettings.id : null
            });

            setAnalogData(syncRemovedStores({ data: results.data }));
            setAnalogSettings(results.settings);
            setForcedStores({ ...forcedStores, dirty: false });
            setActiveFilters({ ...activeFilters, dirty: false });
            setGenerating(false);
        },
        [selectedTemplateId, analogSettings, activeFilters, forcedStores, syncRemovedStores]
    );

	useEffect(() =>{	
        refreshAnalog();
	}, [refreshAnalog]);

    const saveAnalog = async () => {
            
        setGenerating(true);

        const stores = analogData.filter(x => !x.metaData.isSubjectSite && !x.metaData.isManualEntry).map(row => {
            return { Id: row.key, Reason: row.metaData.reasonForUnchecked, IsChecked: row.metaData.isChecked };
        });
        
        const resultId = await analogsModule.saveAnalog({
            id: analogSettings.id,
            templateId: selectedTemplateId,
            filters: activeFilters.filters,
            forcedStores: forcedStores.stores,
            subjectSite: analogSettings.subjectSite,
            taEncodedString: "",    // TBD
            stores: stores,
            reportList: [],
            lock: false
        });
            
        if (_.isString(resultId) && resultId.length > 0) {
            setAnalogSettings({ ...analogSettings, id: resultId, isSavedAnalog: true, templateId: selectedTemplateId });
            successToast(translate('success_saving_analog'));
        }
        else
            errorToast(translate('error_saving_analog'));

        setGenerating(false);
    };

    const finalizeAnalog = async () => {
            
        setGenerating(true);

        const reportOutput = analogsModule.createReportOutput({
            detailRecords: analogSettings.detailRecords,
            removedStores: removedStores,
            forcedStores: forcedStores.stores,
            filters: analogSettings.filters,
            activeFilters: activeFilters.filters
        });
        
        var resultId = await analogsModule.generateAnalogReport({
            id: analogSettings.id,
            templateId: selectedTemplateId,
            reportOutput: reportOutput,
            subjectSite: analogSettings.subjectSite
        });

        if (!_.isString(resultId) || resultId.length == 0) {
            errorToast(translate('error_finalizing_analog'));
            setGenerating(false);
            return;
        }

        const reportIds = [{ Id: resultId, Title: "Report" }, { Id: resultId, Title: "Detail Report" }];

        const stores = analogData.filter(x => !x.metaData.isSubjectSite && !x.metaData.isManualEntry).map(row => {
            return { Id: row.key, Reason: row.metaData.reasonForUnchecked, IsChecked: row.metaData.isChecked };
        });

        resultId = await analogsModule.saveAnalog({
            id: analogSettings.id,
            templateId: selectedTemplateId,
            filters: activeFilters.filters,
            forcedStores: forcedStores.stores,
            subjectSite: analogSettings.subjectSite,
            taEncodedString: "",    // TBD
            stores: stores,
            reportList: reportIds,
            lock: true
        });
            
        if (_.isString(resultId) && resultId.length > 0) {
            setAnalogSettings({ ...analogSettings, isSavedAnalog: true, isLocked: true, templateId: selectedTemplateId, reportList: reportIds });
            successToast(translate('success_finalizing_analog'));
        }
        else
            errorToast(translate('error_finalizing_analog'));

        setGenerating(false);
    };

    const createReport = async () => {
            
        setGenerating(true);

        if (analogSettings.isLocked) {
            const report = analogSettings.reportList.find(x => x.Title === "Report");
            if (_.isObject(report))
                helpers.navigateToUrl(legacyEndpoints.handlers.getFileUrl({ id: report.Id })); 
            else
                errorToast(translate('no_analog_report'));
            
        } else {  

            const reportOutput = analogsModule.createReportOutput({
                detailRecords: analogSettings.detailRecords,
                removedStores: removedStores,
                forcedStores: forcedStores.stores,
                filters: analogSettings.filters,
                activeFilters: activeFilters.filters
            });

            var resultId = await analogsModule.generateAnalogReport({
                id: analogSettings.id,
                templateId: selectedTemplateId,
                reportOutput: reportOutput,
                subjectSite: analogSettings.subjectSite,
                format: outputType.id === constants.reports.output.pdf ? "PDF" : "Excel"
            });
    
            if (_.isString(resultId) && resultId.length > 0)
                helpers.navigateToUrl(legacyEndpoints.handlers.getFileUrl({ id: resultId, isOneTime: true }));
            else
                errorToast(translate('error_creating_analog_report'));

        }   

        setGenerating(false);
    };

    // Decided to just display the current template name instead of allowing user's to select a different template
    // because clients only have one template defined. If the need to select a different template arises, use this
    // dropdown instead of selectedTemplateName in the toolbar.
    /*
        { analogSettings.isLocked ? '' :
            <DropDown
                className='app-projections-dropdown'
                display='Name'
                valueProperty='ID'
                selected={selectedTemplateId} 
                label={translate('template')}
                items={availableTemplates}
                width={'300px'}
                onChange={({ value }) => {
                    setSelectedTemplateId(value); 
                }}
            />
        }
    */

    return <>
        <AddStore parentId={'app-analogs-add-store'} searchId={searchId} 
            onStoreSelected={async (o) => { 

                if (_.isUndefined(o.store) || _.isNull(o.store) || o.store === '') {
                    errorToast(translate('no_stores_found'));
                    return;
                }

                var data = await legacyEndpoints.service({
                    name: 'FindQueryPoint',
                    parameters: { 
                        aSearchID: searchId,
                        aSearchText: o.store
                    }
                });

                if (!_.isArray(data) || data.length === 0)
                    errorToast(translate('no_stores_found'));
                else if (data.length > 1) {
                    errorToast(translate('too_many_stores_found'));
                } else {

                    var addStore = forcedStores.stores.find(o => o.Id === data[0].PointID);

                    if (!_.isObject(addStore)) {
                        addStore = analogData.find(o => o.key === data[0].PointID);

                        if (!_.isObject(addStore))
                            setForcedStores({ dirty: true, stores: [ ...forcedStores.stores, { Id: data[0].PointID, Reason: o.reason, IsChecked: true } ] });
                        else
                            errorToast(`${data[0].PointID} ${translate('added_to_analog_automatically')}`);
                    } else
                        errorToast(`${data[0].PointID} ${translate('store_already_forced')}`);

                }
            }}
        />
        { 
            analogData.map(row => {
                if (!row.metaData.isSubjectSite && row.metaData.isChecked)
                    return <RemoveStore key={row.key} parentId={`app-analogs-remove-store-${row.key}`}
                        onStoreRemoved={async (o) => { 
                            toggleSelect({ key: row.key, reason: o.reason });
                        }}
                    />
                else
                    return '';
            })
        }
        <AddNote parentId={'app-analogs-notes'} analogSettings={analogSettings}/>       
        {
            analogData.map(row => {
                return <AddNote key={row.key} parentId={`app-analogs-notes-${row.key}`} analogSettings={analogSettings} pointId={row.key}/>
            })
        }
        <div className='app-analogs'>
            <Loader loaded={!generating} />
            <div className='app-projections-toolbar app-analogs-toolbar'>
                <div className='app-analogs-template'>
                    {selectedTemplateName}
                </div>
                <div className='app-analogs-inputs'>
                    { analogSettings.isLocked || !analogSettings.allowManualStore ? '' :
                        <Button 
                            id='app-analogs-add-store'
                            className='app-projections-toolbar-action'
                            theme='action' 
                            size='small' 
                            tooltip={translate('add_store')} 
                            icon={icons.addCircle} 
                        />
                    }
                    {
                        analogSettings.allowPrimaryFilterUpdate ? 
                        <Button 
                            id='app-analogs-filters'
                            className='app-projections-toolbar-action'
                            theme='action' 
                            size='small' 
                            tooltip={analogSettings.isLocked ? translate('view_filters') : translate('modify_filters')}
                            icon={icons.filter} 
                            onClick={() => { setShowModifyFilters(!showModifyFilters); }}
                        />
                        : ''
                    }     
                    <Button 
                        id='app-analogs-notes'
                        className='app-projections-toolbar-action'
                        theme='action' 
                        size='small' 
                        tooltip={translate('notes')} 
                        icon={icons.stickyNote} 
                    />

                </div>
                <div className='app-analogs-outputs'>
                    { analogSettings.isLocked ? '' :
                    <>
                        <Button 
                            className='app-projections-toolbar-action'
                            theme='action' 
                            size='small' 
                            tooltip={translate('save')} 
                            icon={icons.save} 
                            onClick={() => { saveAnalog(); }}
                        />
                        <Button 
                            className='app-projections-toolbar-action'
                            theme='action' 
                            size='small' 
                            tooltip={translate('finalize')} 
                            icon={icons.check} 
                            onClick={() => { finalizeAnalog(); }}
                        />
                    </>
                    }
                    <Button 
                        className='app-projections-toolbar-action'
                        theme='action' 
                        size='small' 
                        tooltip={analogSettings.isLocked ? translate('view_report') : translate('create_report')}
                        icon={icons.fileLines} 
                        onClick={() => { createReport(); }}
                    />
                </div>
                { analogSettings.isLocked ? '' :
                    <div className='app-analogs-format'>
                        <DropDown className='app-analogs-format-dropdown'
                            items={outputTypes}
                            selected={outputType}
                            label={translate('format')} 
                            display='name' 
                            onSelect={(o) => {
                                setOutputType(o.value);
                            }}                       
                        />                        
                    </div>
                }
            </div>
            <div className={'app-projections-content'}>
                <DataGrid 
                    key={'key'}
                    columns={analogColumns}
                    showScrollbar='onHover'     // needed to be able to scroll horizontally
                    disableLoadPanel={true}
                    defaultPageSize={10}
                    onCellPrepared={(e)=>{
                        if (e.rowType === "data") {
                            if (_.isObject(e.data['metaData']) && e.data['metaData'].isSubjectSite)
                                e.cellElement.classList.add("focused");
                            else if (_.isObject(e.data['metaData']) && e.data['metaData'].isManualEntry)
                                e.cellElement.classList.add("highlight"); 
                        }                                
                    }}
                    onLoad={async (o)=>{            
                        return {
                            data: analogData,
                            totalCount: analogData.total
                        };
                    }}
                />
            </div> 
            { !showModifyFilters ? '' :
                <ModalCard className='app-modal-card-default' open={true}>
                    <ModifyFilters 
                        analogSettings={analogSettings} 
                        analogFilters={activeFilters.filters} 
                        onClose={()=>{ setShowModifyFilters(false); }}
                        onRecalculate={(o)=>{ 
                            setShowModifyFilters(false); 
                            setActiveFilters({ dirty: true, filters: o });
                        }}
                    />
                </ModalCard>
            }
        </div>
    </>
}