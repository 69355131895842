import { useCallback, useRef, useState, createRef } from 'react';

// Third party imports
import { TagBox } from 'devextreme-react';

const _ = require("lodash");

export function TagBoxDropDown({className, label, values, items, keyProperty, valueProperty, dropDownContainer, searchEnabled = true, multiLine = false, onChange}) {
    var classes = ['app-drop-down'];

    if (className)
        classes.push(className);

    const handleSelectionChanged = (e) => {
        if (e.addedItems.length == 0 && e.removedItems.length == 0)
            return;

        let newValues = _.isArray(values) ? [...values] : [];

        e.removedItems.forEach(item => {
            newValues = newValues.filter(value => value !== item[keyProperty]);
        });

        e.addedItems.forEach(item => {
            newValues.push(item[keyProperty]);
        });
        
        if(_.isFunction(onChange))
            onChange(newValues);
    };

    var dropDownOptions = {};

    if (_.isString(dropDownContainer))
        dropDownOptions.container = dropDownContainer;

    return <div className={classes.join(' ')}>
        <TagBox
            dataSource={items}
            displayExpr={valueProperty}
            valueExpr={keyProperty}
            value={values}
            label={label}
            searchEnabled={searchEnabled}
            searchExpr={valueProperty}
            showDropDownButton={true}
            showSelectionControls={true}
            dropDownOptions={dropDownOptions}
            showClearButton={true}
            multiline={multiLine}
            onSelectionChanged={handleSelectionChanged}
        />  
    </div>  
}



    
