// React imports
import { useEffect, useState } from 'react';

// Third party imports
import ImageGallery from "react-image-gallery";


// App imports
import { TextBox } from '../../base/textBox/textBox';
import { Hideable } from '../../base/hideable/hideable';
import { Button } from '../../base/button/button';
import { ConfirmButton } from "../../base/confirmButton/confirmButton";
import { icons } from '../../base/icon/icon';
import { translate } from '../../../utils/translation';
import { Loader } from '../../base/loader/loader';
import { legacyEndpoints } from "../../../services/legacyEndpoints";
import { ThumbNailPhoto } from './thumbNailPhoto/thumbNailPhoto';
import { EditPhotoDetails } from './editPhotoDetails/editPhotoDetails';
import { helpers } from '../../../utils/helpers';
import { photos as photosModule } from '../../../modules/photos';
import { EditPhoto } from './editPhoto/editPhoto';
import { UploadPhotos } from './uploadPhotos/uploadPhotos';
import { DataGrid } from '../../base/dataGrid/dataGrid'

export function Photos({entity}){
    const [hideGallery, setHideGallery] = useState(true);
    const [hideEditPhoto, setHideEditPhoto] = useState([true]);
    const [hideEditForm, setHideEditForm] = useState(true);
    const [hideUploadPhotos, setHideUploadPhotos] = useState(true);
    const [hideGridView, setHideGridView] = useState(true);
    const [photos, setPhotos] = useState([]);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [titleFilter, setTitleFilter] = useState('');

    var imageGallery = null; 
    
    useEffect(()=>{
        getPhotos(); 
    },[]);

    var getPhotos = async () =>{
        setLoaded(false);      
        var data = await photosModule.get({ aServiceID: entity.layer.id, sPointID:  entity.id })

        if (data)
            setPhotos([...data]);

        setLoaded(true);
    };     

    var savePhoto = async (editPhoto) =>{   
        const currentPhoto = photos.find(s => s.id === editPhoto.id);

        var data = await photosModule.save({
                id: currentPhoto.id,
                photoDescription: editPhoto.photoDescription,
                date: currentPhoto.date,
                title: editPhoto.title                
            })

        if (data) {
            setHideEditForm(true); 
            setPhotos(photos.map(photo => {
                if (photo.id === editPhoto.id) {
                  return { ...photo, photoDescription: editPhoto.photoDescription, title: editPhoto.title };
                } else {
                  return photo;
                }
            }));

            imageGallery?.slideToIndex(0)            
        }
    };         

    var deletePhotoFromGallery = async  (id) => {
        var result = await deletePhoto(id);

        if (result) {
            if (photos.length > 1) {      
                if (photoIndex > 0)
                    setPhotoIndex(photoIndex - 1);
            } else {
                closeFullScreen();
            }           
        }
    };

    var deletePhotoFromList = async  (id) => {
        deletePhoto(id);
    };    

    var deletePhoto = async  (id) => {
        var result = await photosModule.delete({ id: id });
        if (result) {
            setPhotos(photos.filter(x => x.id !== id))           
        }
        return result;
    }; 
    
    var editPhoto = async () =>{   
        setHideEditPhoto(false);
    };     

    var closeUpload = () =>{ 
        getPhotos();
        setHideUploadPhotos(true); 
    };        
    
    var clearEditForm = () =>{ 
        setHideEditForm(true); 
    };    

    var closeFullScreen = () =>{ 
        setHideEditForm(true); 
        toggleFullScreen();
    };

    const onSlide = () =>{
        if (imageGallery===null) return;

        clearEditForm();
        setPhotoIndex(imageGallery.getCurrentIndex());
    };    

    var toggleFullScreen = (id) => {   
        var index = photos.findIndex(obj => obj.id === id);
        
        if (imageGallery !== null){
            imageGallery.slideToIndex(index)
            imageGallery.toggleFullScreen();
            setHideGallery(false);
        }
    };   

    const saveEditedImage =  async (image) => {
        var base64ContentArray = image.split(",");
        var mimeType = base64ContentArray[0].match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0];

        closePhotoEditor();
  
        await photosModule.updatePhoto({ 
          photoId: photos[photoIndex].id, 
          contentType: mimeType,
          image: base64ContentArray[1]
        });       

        await getPhotos();
    };     
    
    const closePhotoEditor = () => {
        setHideEditPhoto(true)    
    }; 

    const photoDetailsComponent = () => {
       return photos[photoIndex] && <>
            <Hideable hide={hideEditPhoto}>
                <div id='photo_edit_photo_container'>
                    {!hideEditPhoto && <EditPhoto photo={photos[photoIndex]} imageURL={legacyEndpoints.handlers.getPhotoUrl({ id: photos[photoIndex]?.id, isUserPhoto: false })} 
                            onSaveEditedImage={saveEditedImage} onClose={closePhotoEditor}/>
                    }
                </div>
            </Hideable>   
            <Hideable hide={!hideEditPhoto}>    
                <span className='app-photos-actions-container'>
                    <span className='app-photos-actions-container-group'>
                        <ConfirmButton className='app-photos-actions-container-group-icon' icon={icons.trash} theme='map' tooltip={translate('delete')} clearText={translate('delete')} onConfirm={() => { deletePhotoFromGallery(photos[photoIndex]?.id); }} />                    
                        {/* <Button className='app-photos-actions-container-group-icon' icon={icons.trash} theme='map' tooltip={translate('delete')}  onClick={() => { deletePhotoFromGallery(photos[photoIndex]?.id); }} />                     */}
                        <Button className='app-photos-actions-container-group-icon' theme='map' tooltip={translate('edit')} size='medium'icon={icons.paintBrushFine} onClick={()=>{ editPhoto(); }} />	
                        <Button className='app-photos-actions-container-group-icon' theme='map' tooltip={translate('edit')} size='medium'icon={icons.edit} onClick={()=>{ setHideEditForm(false); }} />	
                        <Button className='app-photos-actions-container-group-icon' theme='map' tooltip={translate('close')} size='medium' icon={icons.x} onClick={()=>{closeFullScreen() }} />	
                    </span>
                </span>
                {
                    hideEditForm ? <></> : <EditPhotoDetails entity={entity} photo={photos[photoIndex]} onSave={(o) => { savePhoto(o); }} onClear={()=>{clearEditForm() }} />
                }
                <div className='app-photos-details'>
                    <Hideable hide={!hideEditForm}>
                        <div>
                            <div className='app-photos-details-title'>{photos[photoIndex]?.title}</div>
                            <div className='app-photos-details-description'>{photos[photoIndex]?.photoDescription}</div>                
                        </div>
                    </Hideable>  
                    <Hideable hide={hideEditForm}>
                        <div>
                            <div className='app-photos-details-title'></div>
                            <div className='app-photos-details-description'></div>                
                        </div>
                    </Hideable>                                                 
                    <div className='app-photos-details-user-section'>
                        <img className='app-photos-details-user-image' src={legacyEndpoints.handlers.getPhotoUrl({ userId: photos[photoIndex]?.userId, isUserPhoto: true, width: 150, height: 150 })} alt={translate('user_image')} />
                        <div className='app-photos-details-user-section-name'>{photos[photoIndex]?.userName}</div>
                    </div>
                    <div className='app-photos-details-user-section-date'>{helpers.formatDate({ date: photos[photoIndex]?.date, format: 'MM/DD/YYYY'})}</div>
                </div>
            </Hideable>   
        </>
    };    

	return <>
            <Hideable className='app-photos' hide={hideUploadPhotos}>  
                 <UploadPhotos 
                        className={'app-photos-upload'} 
                        entity={entity} 
                        photoCount={photos.length} 
                        onClose={closeUpload} maxNumberOfFiles={100} useType={0} 
                        allowedExtensions={['jpg','jpeg','png','gif']} 
                        instructions={translate('photo_upload_help')}
                        />
            </Hideable>
            <Hideable className='app-photos' hide={!hideUploadPhotos}>                 
                <Loader loaded={loaded} />
                <div className='app-photos-header'>
                    <Hideable hide={!hideGridView}>
                        <div className='app-photos-search'>
                            <TextBox className='app-photos-search-input' value={titleFilter} valueChangeEvent='keyup' onChange={e => setTitleFilter(e.value)} 
                                actions = {[{ //todo replace with icon component          
                                    icon: titleFilter.length > 0 ? '<svg viewBox="0 0 512 512"><path d="' + icons.circleX.icon[4] + '"/></svg>' :        
                                                                        '<svg viewBox="0 0 512 512"><path d="' + icons.magnifyingGlass.icon[4] + '"/></svg>',
                                    onClick: () => { setTitleFilter(''); },
                                }]}
                                                            
                            />
                        </div> 
                    </Hideable>
                    <div className='app-photos-actions'>
                        <Button theme='secondary' size='small' icon={icons.plus} tooltip={translate('add')} onClick={() =>{ setHideUploadPhotos(false); }}/>
                        <Button theme='secondary' size='small' icon={hideGridView ? icons.list : icons.grid} tooltip={translate('list_view')} onClick={() =>{ setHideGridView(!hideGridView); }}/>
                    </div>    
                </div>
                {
                    photos.length === 0 ? <div className='app-photos-empty'>{translate('no_photos')}</div> :
                    <div>
                        <Hideable hide={hideGridView}>
                            <DataGrid 
                                key='id'
                                height='360px'
                                className={'app-photos-list-view-column'}
                                showFilterRow={true}
                                remoteOperations={'false'}
                                columns={[
                                    { name: 'thumbnail', caption: '', alignment:'center', allowFiltering: false, onRender: (o) => { 
                                        return <>
                                            <img className='app-photos-thumb-nail-photo' src={o.thumbnail} alt={translate('store_photo')} /> 
                                        </>  
                                    }},                
                                    { name: 'title', caption: translate('name'), width:'125px', className:'app-photos-list-view-column-text', onRender: (o) => { return o.title; }},
                                    { name: 'photoDescription', caption: translate('description'), width:'250px', className:'app-photos-list-view-column-text', onRender: (o) => { return o.photoDescription; }},
                                    { name: 'userName', caption: translate('addedBy'), width:'145px', className:'app-photos-list-view-column-text', onRender: (o) => { return o.userName; }},
                                    { name: 'actions', caption: '', alignment:'center', width:'95px', className:'app-photos-list-view-column-text', allowFiltering: false, onRender: (o) => { 
                                        return <div className='app-photos-list-view-actions'>
                                            <div className='app-photos-list-view-actions-buttons'>
                                                <Button className='app-photos-list-view-actions-button'  theme='simple' icon={icons.edit} tooltip={translate('edit')} onClick={()=>{ toggleFullScreen(o.id); }} />	                                    
                                                <ConfirmButton className='app-photos-list-view-actions-button' align='right' theme='simple' icon={icons.trash} tooltip={translate('delete')} clearText={translate('delete')} onConfirm={()=>{ deletePhotoFromList(o.id); }} />
                                            </div>
                                        </div> 
                                    }},     
                                ]}
                                onLoad={async (o)=>{
                                    return {
                                        data: photos,
                                        totalCount: photos.length
                                    };
                                }}
                            />
                        </Hideable>
                        <Hideable hide={!hideGridView}>
                            <div className='app-photos-body'>
                                <div className='app-photos-container'>
                                    {                              
                                        photos.filter(p => p.title.toLowerCase().includes(titleFilter.toLowerCase()) || titleFilter === '').map((photo, i) => {
                                            return (
                                                <ThumbNailPhoto key={photo.id} photo={photo} onPhotoClick={() => {toggleFullScreen(photo.id)} } />
                                            );
                                        })                    
                                    }
                                </div>
                            </div>                  
                        </Hideable>
                    </div>
                }
                <Hideable hide={hideGallery}>
                    <div className='app-photos-gallery'>
                        <ImageGallery 
                            ref={(i) => (imageGallery = i)}
                            items={photos} 
                            originalHeight={'50px'}
                            renderCustomControls={photoDetailsComponent}
                            onSlide={onSlide} 
                            showPlayButton={false}
                            onScreenChange={(value)=>{setHideGallery(!value)}}
                        />
                    </div>   
                </Hideable>        
            </Hideable>            
    </>
}
