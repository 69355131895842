// React imports
import { useState, useEffect, useMemo } from 'react';

// App imports
import { Card } from "../../../base/card/card";
import { DropDown } from '../../../base/dropDown/dropDown';
import { Button } from '../../../base/button/button';
import { translate } from "../../../../utils/translation"
import { icons } from '../../../base/icon/icon';
import { constants } from '../../../../utils/constants';
import smartTags from '../../../../modules/smartTags';
import SmartTextBox from '../../../base/smartTextBox/smartTextBox';
import { helpers } from '../../../../utils/helpers';


const _ = require("lodash");

export function PrintMapAdornment({ active, data, fontFamilies, onClose, onSave }) {

    const [contentType, setContentType] = useState(constants.print.contentTypes.customText);
    const [contentText, setContentText] = useState("");
    const [textDisabled, setTextDisabled] = useState(false);
    const [fontSize, setFontSize] = useState(10);
    const [fontDisabled, setFontDisabled] = useState(false);
    const [fontFamily, setFontFamily] = useState('Arial');
    const [saveDisabled, setSaveDisabled] = useState(true);

    const contentTypes = useMemo(() => [
        { key: constants.print.contentTypes.customText, text: translate('custom_text') }, 
        { key: constants.print.contentTypes.companyLogo, text: translate('company_logo') },
        { key: constants.print.contentTypes.currentDate, text: translate('current_date') }
        //{ key: constants.print.contentTypes.aerialDate, text: translate('aerial_date') }      // TBD: support aerial date
    ], []);

    useEffect(() =>{
        setContentType(_.isObject(data) ? data.type : constants.print.contentTypes.customText);
        setContentText(_.isObject(data) ? helpers.convertSmartTagToString(data.text) : "");
        setFontSize(_.isObject(data) && _.isObject(data.fontStyle) ? data.fontStyle.FontSize : 10);
        setFontFamily(_.isObject(data) && _.isObject(data.fontStyle) ? data.fontStyle.FontFamily : 'Arial');

    }, [data]);

    useEffect(() =>{

        setTextDisabled(contentType !== constants.print.contentTypes.customText);
        setFontDisabled(contentType === constants.print.contentTypes.companyLogo);
        setSaveDisabled(contentType === constants.print.contentTypes.customText && contentText.trim().length === 0);

    }, [contentType, contentText]);

    return <Card open={active}>
        { !_.isObject(data) ? '' :
            <div className='app-projects-print-cols'>
                <div className='app-projects-print-labels'>{data.title}</div>
                <div className='app-projects-print-rows'>
                    <DropDown className={'app-projects-print-input app-projects-print-stretch'} items={contentTypes} label={translate('type')} height={'40px'}
                        display='text' valueProperty='key' selected={contentType} onChange={(o) => { setContentType(o.value); }} 
                    />
                </div>
                <div className='app-projects-print-rows'>
                    <DropDown className={'app-projects-print-input'} items={data.fontSizes} label={translate('size')} height={'40px'} width={'78px'}
                        selected={fontSize} disabled={fontDisabled} onChange={(o) => { setFontSize(o.value); }} 
                    />
                    <DropDown className={'app-projects-print-input app-projects-print-stretch'} items={fontFamilies} label={translate('font')} height={'40px'}
                        selected={fontFamily} disabled={fontDisabled} onChange={(o) => { setFontFamily(o.value); }} 
                        itemRender={(e) => { return <div style={{fontFamily: e }}>{e}</div> }}
                    />
                </div>
                <div className='app-projects-print-rows'>
                    <SmartTextBox
                        className={'app-projects-print-input app-projects-print-stretch'} 
						label={translate('text')} 
                        value={contentText}
                        smartTags={smartTags.GetMapBookTags()} 
                        displayExpr={"value"} 
                        valueExpr={"key"} 
                        marker={"#"}
                        height={'40px'}
                        disabled={textDisabled}
                        onChange={(o) => {setContentText(o.target.value);}}
                    />
                </div>
                <div className='app-projects-print-adornment-actions-rows'>
                    <Button className='app-projects-print-button' theme='secondary' tooltip={translate('cancel')} size='small' icon={icons.x} onClick={()=>{ onClose(); }} />	                    
                    <Button className='app-projects-print-button' theme='primary' tooltip={translate('save')} size='small' icon={icons.check} disabled={saveDisabled} onClick={()=>{ 
                        onSave({ index: data.index, type: contentType, text: helpers.convertStringToSmartTag({id: helpers.newGuid(), text: contentText, words: smartTags.GetMapBookTags()}), fontSize: fontSize, fontFamily: fontFamily }); 
                    }} />	
                </div>
            </div>
        }       
    </Card>	
}