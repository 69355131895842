// React imports
import { useState, useEffect, useCallback } from 'react';

// Third party imports
import List from 'devextreme-react/list';
import DropDownBox from 'devextreme-react/drop-down-box';

// App imports
import { translate } from '../../../utils/translation';
import { constants } from '../../../utils/constants';
import { map } from '../../app/map/map';
import { helpers } from '../../../utils/helpers';

const _ = require('lodash');

export function TradeAreaDropDown({className,onChange,types,showCurentView=false,maxSelections,height}) {

    const [dropDownBoxValue, setDropDownBoxValue] = useState(['']);
    const [tradeAreasLibraries, setTradeAreasLibraries] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    var classes = ['app-trade-area-drop-down'];

    if (className)
        classes.push(className);

    var getLibraries = useCallback(
        async () =>{ 
          var libraries = [];

          if (showCurentView)
            libraries.push({ 
                id: helpers.newGuid(),
                name: translate('current_view'), 
                tradeAreas: [{
                    name: translate('current_view'),
                    libraryName: translate('current_view'),
                    Type: constants.tradeAreas.types.userDrawn,
                    CenterLat: map.center.lat,
                    CenterLon: map.center.lon,
                    EncodedPoints: helpers.encodedLocations(helpers.createRectangle({ topLeft: map.bounds.northEast, bottomRight: map.bounds.southWest})),
                    GeographyIds: [],
                    GeographyVintageId: -1,
                    Interval: 0,
                    LengthMeasurement: constants.lengthMeasurements.miles,
                    PointFormat: 0,
                    isTradeArea: false                  
                }]
            });

            libraries.push(
            ...map.layers.filter(layer => layer.type === constants.layers.types.tradeArea && layer.tradeAreas.filter((x) => types === null || types?.includes(x.type)).length > 0)
                .map(layer =>{
                return { 
                    id: layer.id,                
                    name: layer.text, 
                    tradeAreas: layer.tradeAreas.filter((x) => types === null || types?.includes(x.type)).map((tradeArea) =>{
                        var centroid = _.isObject(tradeArea.polygon.centroid) ? tradeArea.polygon.centroid : {lat:0,lon:0};
                        return {
                                name: tradeArea.name,
                                libraryName: layer.text,
                                combinedName: `${layer.text} ${tradeArea.name}`,
                                id: helpers.newGuid(),
                                Type: tradeArea.type === constants.tradeAreas.types.savedShape ? tradeArea.savedType ?? tradeArea.type : tradeArea.type,
                                CenterLat: centroid.lat,
                                CenterLon: centroid.lon,
                                EncodedPoints: tradeArea.polygon.polygonString || "",
                                GeographyIds: tradeArea.geographyIds,
                                GeographyVintageId: tradeArea.geographyVintage,
                                Interval: tradeArea.type === "P" ? 0 : tradeArea.distance,
                                LengthMeasurement: constants.lengthMeasurements.miles, 
                                PointFormat: tradeArea.polygon.polygonType === constants.encodedString.google ? constants.demographicShape.encodedString : constants.demographicShape.wkt,
                                isTradeArea: true 
                        }
                    })
                };
            }));

            var tradeAreasForList = libraries.map(lib => { 
                return {key: lib.id, name: lib.name, items: lib.tradeAreas}
            }); 

            setTradeAreasLibraries(tradeAreasForList); 
        },
        [types, showCurentView]
    );        

    useEffect(()=>{           
        getLibraries();
    },[getLibraries]);        
    
    const syncDropDownBoxValue = useCallback(
      (o) => {
        const value = o.map(x => x.key);

        setDropDownBoxValue(value);
      },
      [],
    );  

    const handleSelectionChange = useCallback(
      (libraries) => {
        setSelectedItems(libraries);
        syncDropDownBoxValue(libraries);

        const isAcrossLibraries = libraries.filter(cl => cl.items.some(c => c.isTradeArea === true))?.length > 1;

        var result = [];
        if (onChange) {
          libraries.forEach(library => {
            library.items.forEach(item => {
              var returnTradeArea = _.cloneDeep(item);
              returnTradeArea.name = isAcrossLibraries ? (returnTradeArea.combinedName ?? returnTradeArea.name) : returnTradeArea.name
              result.push(returnTradeArea)
            })
          });
          onChange(result);
        }
      
      },
      [syncDropDownBoxValue,onChange]
    );           
    
    const onSelectedItemKeysChange = useCallback(
      (e) => {	
        const selectedItems = e.component.option("selectedItems");

        if (maxSelections && _.flatten(_.map(selectedItems, 'items')).length > maxSelections) return; //todo find another way, not a greate UI experience

        handleSelectionChange(selectedItems);
      },
      [handleSelectionChange,maxSelections],
    );  	

    const onSelectAllValueChanged = useCallback(
      (e) => {
        var selectedItems;

        if (e.value === true) {
          selectedItems = tradeAreasLibraries;
        } else {
          selectedItems = [];
        }
        
        handleSelectionChange(selectedItems);
      },
      [handleSelectionChange, tradeAreasLibraries],
	  ); 

    const groupTemplate = (item) => <div>{item.name}</div>;
    
    const listViewRender = useCallback(      
        () => (
          <List
              keyExpr={'id'}
              dataSource={tradeAreasLibraries}
              height={'400px'}
              showSelectionControls={true}
              selectionMode={'multiple'}
              selectAllMode={'allPages'}
              displayExpr={'name'}
              grouped={true}
              groupRender={groupTemplate} 
              collapsibleGroups={true}
              searchEnabled={false}
              searchExpr={"name"}
              onSelectionChanged={onSelectedItemKeysChange}
              onSelectAllValueChanged={onSelectAllValueChanged}
              selectedItems={selectedItems}
              selectByClick={false} 
            />          
        ),
        [onSelectedItemKeysChange, selectedItems, onSelectAllValueChanged, tradeAreasLibraries]
      );     
    
    return <div className={classes.join(' ')}>
            <DropDownBox
                value={dropDownBoxValue}
                height={height} 
                valueExpr="key"
                label={translate('trade_areas')}
                displayExpr="name"
                placeholder={translate('select_a_trade_area')}
                showClearButton={false}
                dataSource={tradeAreasLibraries}
                contentRender={listViewRender}
            /> 
   </div>    
}