// React imports
import React, { useState } from 'react';

import { Hideable } from "../../../base/hideable/hideable";
import _ from 'lodash';
import { Button } from '../../../base/button/button';
import { icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
export var mapEntityTooltip;

var onHide = () => {}; //TODO: Fix this

export const MapEntityTooltip = () => {
    
    const [hide, setHide] = useState(true);
    const [title, setTitle] = useState('');
    const [pixel, setPixel] = useState({ x: -1, y: -1 });
    const [text, setText] = useState('');
    const [top, setTop] = useState(false);
    const [classes, setClasses] = useState([]);

    mapEntityTooltip = {
        show: (o) =>{
            
            setHide(false);     
            let classes = [];          
            if (o.className)
                classes.push(o.className); 

            setClasses(classes);

            var width = 225;
            var height = 125;
            var yOffset = 25;

            // some orgs have more than 3 lines of text, so we need to adjust the height
            // assumptions: 
            //      app-map-entity-tooltip-title has height 37px
            //      app-map-entity-tooltip-section has padding 14px
            //      and each line of text will have height 19px
            // 37 + 14 = 51
            if (_.isString(o.text)) {
                const lines = o.text.replace(/(\r\n|\n|\r)/g, '\r').split('\r');
                if (51 + (lines.length * 19) > height)
                    height = 51 + (lines.length * 19);
            }

            if (o.pixel.y - yOffset <= 0)
            {
                setPixel({ x: o.pixel.x - (width / 2), y: o.pixel.y + yOffset });
                setTop(false);
            }
            else
            {
                setPixel({ x: o.pixel.x - (width / 2), y: o.pixel.y - (height + yOffset) });
                setTop(true);
            }
            
            if (_.isString(o.text))
                setText(o.text);

            if (_.isString(o.title))
                setTitle(o.title);
            
            onHide = _.isFunction(o.onHide) ? o.onHide : () => {};
        },
        hide: () =>{
            setHide(true);
        }
    };  

    return <Hideable className='app-map-entity-tooltip' hide={hide} style={{top: pixel.y + 'px', left: pixel.x + 'px'}}>        
        {top === false ? <div className='app-map-entity-tooltip-arrow-top' /> : '' }
        <div className={classes.join(' ')}>
            <div className='app-map-entity-tooltip-title'>
                {title}
            </div>                
            <div className={'app-map-entity-tooltip-section app-selectable-text'}>
                {text.replace(/(\r\n|\n|\r)/g, '\r').split('\r').map((line, index) => 
                    <div key={index} className={'app-map-entity-tooltip-section-new-line'}>{line}</div>)
                }
            </div>
        </div>
        {top === true ? <div className='app-map-entity-tooltip-arrow-bottom' /> : '' }        
    </Hideable>
        
}