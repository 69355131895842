// React imports
import { useCallback, useEffect, useRef, useState } from 'react';

import { DropDown } from '../../base/dropDown/dropDown';
import { Button } from '../../base/button/button';
import { OptionSelector } from '../../base/optionSelector/optionSelector';
import { RadioButtonSet } from '../../base/radioButtonSet/radioButtonSet';
import { Popover } from '../../base/popover/popover';
import { TextBox } from '../../base/textBox/textBox';
import { Loader } from '../../base/loader/loader';
import { Hideable } from '../../base/hideable/hideable';
import { ConfirmButton } from '../../base/confirmButton/confirmButton';
import { Window } from '../../base/window/window';
import { ProjectionEditForm } from './projectionEditForm';
import { ProjectionTemporaryAddForm } from './projectionTemporaryAddForm';
import { ProjectionSavedShape } from './projectionSavedShape';
import { ProjectionLinkSitesForm } from './projectionsLinkSitesForm';
import { ModalCard } from '../../base/modalCard/modalCard';
import { PointScenarios } from '../pointScenarios/pointScenarios';
import { MenuButton } from '../../base/menuButton/menuButton';
import { TextArea } from '../../base/textArea/textArea';

import { helpers } from '../../../utils/helpers';
import { constants } from '../../../utils/constants';
import { projections } from '../../../modules/projections';
import { icons } from '../../base/icon/icon';
import { translate } from '../../../utils/translation';
import { errorToast, infoToast, successToast } from '../../base/toast/toast';
import { userPreferences } from '../app';
import { navigation } from '../navigation/navigation';
import { ProjectionPolygon } from './projectionPolygon';

const _ = require("lodash");

export function Projections({ projectionResults, projectionElements, scenario, jobName, onClose, orginalParameters }) {
    
    const [generating, setGenerating] = useState(false);
    const [elementClasses, setElementClasses] = useState('app-projections-content');
    const [modelId, setModelId] = useState(projectionResults.modelResults[0].model.id);
    const [modelViewId, setModelViewId] = useState(projectionElements.find(x => x.id === projectionResults.modelResults[0].model.id).selectedViewId);
    const [modelDetailsId, setModelDetailsId] = useState(projectionElements.find(x => x.id === projectionResults.modelResults[0].model.id).selectedDetailId);
    const [modelDetailsTitle, setModelDetailsTitle] = useState(translate('details'));
    const [modelOverlayEnabled, setModelOverlayEnabled] = useState(false);
    const [modelSumEnabled, setModelSumEnabled] = useState(false);
    const [selectionTool, setSelectionTool] = useState(constants.selections.behaviors.none);
    const [selectionListenerId, setSelectionListenerId] = useState(helpers.emptyGuid());
    const [temporarySiteListenerId, setTemporarySiteListenerId] = useState(helpers.emptyGuid());
    const [temporarySiteForm, setTemporarySiteForm] = useState(null);
    const [executeJobName, setExecuteJobName] = useState(_.isString(jobName) ? jobName : `${(_.isString(projectionResults.pointName) ? `${projectionResults.pointName}-` : '')}${projectionResults.name} ${helpers.formatDate({ date: new Date() })}`);    
    const [scenariosActive, setScenariosActive] = useState(false);
    const [currentScenario, setCurrentScenario] = useState(scenario ?? null);
    const [saveScenarioName, setSaveScenarioName] = useState('');
    const [saveScenarioDescription, setSaveScenarioDescription] = useState('');
    const [completedForecasts, setJobsCompletedForecasts] = useState([]);
    const [debugJson, setDebugJson] = useState('');

    const jobWindow = useRef(null);
    const editWindow = useRef(null);
    const scenarioWindow = useRef(null);
    const saveShapeWindow = useRef(null);
    const linkSiteWindow = useRef(null);
    const polygonWindow = useRef(null);

    const getCurrentModelResult = useCallback(() => {
        return projectionResults.modelResults.find(({model}) => model.id === modelId);
    }, [modelId]);

    const getCurrentModelElements = useCallback(() => {
        return projectionElements.find(x => x.id === getCurrentModelResult()?.model.id);
    }, [modelId]);

    const getCurrentModelViews = useCallback(() => {        
        return getCurrentModelElements()?.views;
    }, [modelId]);

    const getCurrentModelView = useCallback(() => {
        return getCurrentModelViews()?.find(view => view.id === modelViewId);
    }, [modelId, modelViewId]);

    const getCurrentModelDetails = useCallback(() => {
        return getCurrentModelElements()?.details;
    }, [modelId]);

    const getCurrentModelDetail = useCallback(() => {
        return getCurrentModelDetails()?.find(detail => detail.id === modelDetailsId);
    }, [modelId, modelDetailsId]);

    useEffect(() => {

        var currentModelResult = getCurrentModelResult();
        var currentModelElements = getCurrentModelElements();

        currentModelElements.refreshGridOnDetailChange = false;

        setModelViewId(currentModelElements.selectedViewId);
        setModelDetailsId(currentModelElements.selectedDetailId);
        setModelOverlayEnabled(_.isObject(currentModelElements.layers.overlay) && currentModelElements.overlayEnabled);
        setModelSumEnabled(currentModelElements.sumEnabled);
        setSelectionTool(constants.selections.behaviors.none);
        setSelectionListenerId(helpers.emptyGuid());
        setTemporarySiteListenerId(helpers.emptyGuid());
        projections.stopSelection({ selectionListenerId: selectionListenerId });
        projections.stopSelection({ selectionListenerId: temporarySiteListenerId });

        switch(currentModelResult.model.type){
            case constants.projections.models.disaggregateForecast:
                setModelDetailsTitle(translate('report'));
                break;
            case constants.projections.models.disaggregateRecapture:
            case constants.projections.models.disaggregateCannibalization:  
            case constants.projections.models.disaggregateDeliveryCannibalization:      
                setModelDetailsTitle(translate('focused_store'));
                break;
        }

        projectionResults.modelResults.forEach(modelResult => {

            var modelElements = projectionElements.find(x => x.id === modelResult.model.id);

            if (_.isObject(modelElements.layers.tradeArea))
            {
                modelElements.layers.tradeArea.visible = modelResult.model.id === currentModelResult.model.id;

                if (modelResult.model.type === constants.projections.models.disaggregateForecast)
                {
                    var fillColor = helpers.randomColor();
                    var strokeColor = _.cloneDeep(fillColor);
                    
                    fillColor.a = 0.3;
                    strokeColor.a = 0.7;

                    modelElements.layers.tradeArea.legend = [{
                        text: translate('trade_area'),
                        color: modelElements.layers.tradeArea.visible ? constants.polygons.selected.fillColor : fillColor
                    }];
    
                    modelElements.layers.tradeArea.entities.forEach(entity => {
                        entity.fillColor = modelElements.layers.tradeArea.visible ? constants.polygons.selected.fillColor : fillColor;
                        entity.strokeColor = modelElements.layers.tradeArea.visible ? constants.polygons.selected.strokeColor : strokeColor;
                    });
                }
            }

            if (_.isObject(modelElements.layers.overlay))
                modelElements.layers.overlay.visible = modelResult.model.id === currentModelResult.model.id && modelElements.overlayEnabled;

            if (_.isObject(modelElements.layers.points))
                modelElements.layers.points.visible = modelResult.model.id === currentModelResult.model.id;
        });

    }, [modelId]);

    useEffect(() => {

        getCurrentModelElements().selectedViewId = modelViewId;

    }, [modelId, modelViewId]);

    useEffect(() => {

        var modelResult = getCurrentModelResult();
        var modelElements = getCurrentModelElements();

        modelElements.selectedDetailId = modelDetailsId;
        
        if (modelElements.refreshGridOnDetailChange)
            (async() => {
                setGenerating(true);
                await projections.changeDetail({ modelResult: modelResult, modelElements: modelElements });
                setGenerating(false);
            })();

        modelElements.refreshGridOnDetailChange = true;

    }, [modelDetailsId]);

    useEffect(() => {

        getCurrentModelElements().selectedViewId = modelViewId;

    }, [modelId, modelViewId]);

    useEffect(() => {

        var currentElements = getCurrentModelElements();

        if (_.isObject(currentElements.layers.overlay))
            currentElements.layers.overlay.visible = modelOverlayEnabled;

        currentElements.overlayEnabled = modelOverlayEnabled;

    }, [modelOverlayEnabled]);

    useEffect(() => {
        
        var currentElements = getCurrentModelElements();

        currentElements.sumEnabled = modelSumEnabled;

        if (getCurrentModelView()?.type === constants.projections.views.disaggregateForecastTradeArea)
            projections.refreshDisaggregateForecastTradeAreaGrid({ modelElements: currentElements });

    }, [modelSumEnabled]);

    return <>
        {
            debugJson.length > 0 ?
             <Window 
                title={translate('debug')}
                visible={true}
                setVisible={()=>{setDebugJson('')}}
                width={"595px"} 
                height={"575px"} 
                resizable={true}                
                modal={false}
                showCloseButton={true}
            >
                <textarea className='app-projections-debug-content' value={debugJson} />
            </Window> : null
        }
        {
            temporarySiteForm !== null ?
             <Window 
                title={translate('temporary_site')}
                visible={true}
                setVisible={()=>{setTemporarySiteForm(null)}}
                width={"595px"} 
                height={"575px"} 
                resizable={true}                
                modal={false}
                showCloseButton={true}
            >
                {temporarySiteForm}                
            </Window> : null
        }
        {
            projectionResults.layer.editFormId === helpers.emptyGuid() ? '' : 
            <Popover ref={editWindow} className='app-projections-edit-window' parentId='#app-projections-edit' title={translate('site_characteristics')} height={350} width={450}>
                <ProjectionEditForm 
                    saveButtonSize='tiny'
                    projection={projectionResults}
                    onGenerate={({ updateForm })=>{
                        projectionResults = projections.updateSiteCharacteristics({ updateForm, projectionResults: projectionResults });
                        editWindow.current.instance.hide();
                    }}
                />
            </Popover>
        }
        {
            projectionResults.tradeAreas.filter(x => x.tradeArea.type === constants.tradeAreas.types.userDrawn).length > 0 ? 
                <Popover ref={polygonWindow} className='app-projections-edit-window' parentId='#app-projections-shape-draw-polygon' title={translate('polygon')} height={350} width={450}>
                    <ProjectionPolygon 
                        projectionResults={projectionResults} 
                        onClose={()=>{polygonWindow.current.instance.hide();}}
                        onSave={({tradeAreas})=>{
                            projections.addTradeAreasToMap({
                                projectionResults: projectionResults,
                                tradeAreas: tradeAreas
                            });
                        }}
                    />
                </Popover>
            : null
        }
        {
            getCurrentModelView()?.type === constants.projections.views.disaggregateForecastTradeArea ||
            getCurrentModelView()?.type === constants.projections.views.disaggregateForecastPoint ? 
            <Popover ref={saveShapeWindow} className='app-projections-save-shape-window' parentId='#app-projections-save-shape' title={translate('save_trade_area_to_site')} height={470} width={300}>
                <ProjectionSavedShape projection={projectionResults} model={getCurrentModelResult()} onSave={()=>{saveShapeWindow.current.instance.hide();}}/>
            </Popover> : ''
        }
        {
            getCurrentModelView()?.type === constants.projections.views.disaggregateForecastPoint ||
            getCurrentModelView()?.type === constants.projections.views.disaggregateCannibalizationPoint ? 
            <Popover ref={linkSiteWindow} className='app-projections-link-sites' parentId='#app-projections-link-sites' title={translate('linked_models')} height={325} width={400}>
                <ProjectionLinkSitesForm 
                    projectionResults={projectionResults}
                    projectionElements={projectionElements}
                    onSelect={(o)=>{
                        projections.linkModels({
                            viewIds: o,
                            projectionResults: projectionResults,
                            projectionElements: projectionElements
                        })
                    }}
                />
            </Popover> : ''
        }
        <Popover ref={scenarioWindow} parentId='#app-projections-scenario' title={translate('save_scenario')}height={335} width={400}>
            <div className='app-projections-scenario-save'>
                {currentScenario === null ? '' :
                    <Button  theme='primary' text={translate('copy')} icon={icons.files} onClick={async ()=>{
                        scenarioWindow.current.instance.hide();
                        setGenerating(true);            
                        setCurrentScenario(
                            await projections.copyScenario({
                                projectionResults: projectionResults,
                                scenario: currentScenario,
                                copyName: saveScenarioName,
                                copyDescription: saveScenarioDescription                    
                            })
                        );
                        setGenerating(false);
                    }}/>
                }            
                <Button theme='primary' text={translate('save')} icon={icons.save} disabled={saveScenarioName.length === 0 || saveScenarioDescription.length === 0} onClick={async ()=>{                
                    scenarioWindow.current.instance.hide();

                    setGenerating(true);
                    setCurrentScenario(
                        await projections.saveScenario({
                            projectionResults: projectionResults,
                            scenario: currentScenario,
                            name: saveScenarioName,
                            description: saveScenarioDescription
                        })
                    );
                    setGenerating(false);

                }}/>   
            </div>
            <TextBox 
                label={translate('name')}
                value={saveScenarioName} 
                onChange={(o) => {
                    setSaveScenarioName(o.value)
                }} 
            />
            <TextArea 
                label={translate('description')} 
                minHeight='150px' 
                value={saveScenarioDescription}
                onChange={(o) =>{
                    setSaveScenarioDescription(o.value);
                }}
            />                 
        </Popover>
        <Popover ref={jobWindow} parentId='#app-projections-execute' title={translate('job_name')} height={165} width={400}>
            <div className='app-projections-execute-job' >
                <Button theme='primary' size='tiny' icon={icons.check} onClick={async ()=>{

                    if (projectionResults.tradeAreas.filter(x => x.tradeArea.type === constants.tradeAreas.types.userDrawn && !_.isString(x.tradeArea.polygon.polygonString)).length > 0)
                    {
                        errorToast(translate('please_define_a_trade_area'));
                        return;
                    }                

                    jobWindow.current.instance.hide();
                    setGenerating(true);

                    var modelsToGenerate = projectionResults.modelResults.filter(x => x.model.type === constants.projections.models.disaggregateDeliveryCannibalization);

                    if (modelsToGenerate.length > 0){
                        const promises = [];

                        modelsToGenerate.forEach(modelResult => {
                            promises.push(
                                new Promise(async (resolve) => {
                                    await projections.calculateDeliveryAreaCannibalization({
                                        projectionResults: projectionResults,
                                        modelResult: projectionResults.modelResults.find(({model}) => model.id === modelResult.model.id),
                                        modelElements: projectionElements.find(x => x.id === modelResult.model.id)
                                    });
                                    resolve();
                                })
                            );
                        });

                        await Promise.all(promises);

                        setElementClasses('app-projections-content app-projections-polygon-generated');
                    }

                    var jobId =  await projections.execute({
                        name: executeJobName,
                        projectionResults: projectionResults,
                        callback: ()=>{ successToast(translate('successfully_submitted')); }
                    });

                    setGenerating(false);
                    
                    if (projectionElements.filter(x => x.allowThematic).length > 0)
                        navigation.subscribeToNotifications({
                            id: jobId,
                            action: async  (notifications) =>{

                                var job = notifications.Jobs.find(x => x.Id === jobId);

                                if (!_.isObject(job))
                                    return;

                                if (job.Status !== constants.jobs.statuses.pending  && job.Status !== constants.jobs.statuses.processing)
                                    navigation.unsubscribeFromNotifications({id: jobId});

                                if (job.Status !== constants.jobs.statuses.completed)
                                    return;

                                var results = await projections.getProjectionResults({ jobId: job.Id });                            

                                if (!results.valid)
                                    return;

                                setJobsCompletedForecasts([{ 
                                    id: job.Id, 
                                    text: job.Name,
                                    onClick: async () =>{

                                        setGenerating(true);

                                        projectionResults = projections.processJobResults({
                                            jobResults: results.projectionResults,
                                            projectionResults: projectionResults
                                        });

                                        await projections.changeDetail({ modelResult: getCurrentModelResult() });

                                        setGenerating(false);
                                    }
                                }, ...completedForecasts]);
                                infoToast(translate('forecast_results_available'));
                            }
                        });
                }}/>
            </div>            
            <TextBox value={executeJobName} onChange={(o) => {setExecuteJobName(o.value)}} />            
        </Popover>
        <div className='app-projections'>
            <div className='app-projections-toolbar'>
                <ConfirmButton 
                    className='app-projections-toolbar-action'
                    theme='action' 
                    size='small'
                    icon={icons.rotateLeft} 
                    tooltip={translate('reload')} 
                    clearText={translate('reload')}
                    onConfirm={() =>
                    {
                        onClose();
                        projections.generate(orginalParameters);
                    }} 
                />
                <ConfirmButton
                    className='app-projections-toolbar-action'
                    theme='action' 
                    size='small'
                    icon={icons.filterCircleXmark}
                    tooltip={translate('clear_filters')} 
                    clearText={translate('clear')}
                    onConfirm={() =>
                    {                        
                        projections.clearGridFilters({
                            view: getCurrentModelView()
                        });
                    }} 
                />
                <Button 
                    className='app-projections-toolbar-action'
                    theme='action' 
                    size='small'
                    tooltip={translate('refresh')} 
                    icon={icons.refresh} 
                    onClick={()=>{
                        projections.refreshGrid({
                            view: getCurrentModelView()
                        });
                    }} 
                />
                <DropDown
                    className='app-projections-dropdown'
                    display='name'
                    valueProperty='id'
                    selected={getCurrentModelResult()?.model?.id} 
                    label={translate('model')}
                    items={projectionResults.modelResults.map(({ model }) => { return { id: model.id, name: model.name } })}
                    onChange={({ value }) => {
                        setModelId(value);
                    }}
                />
                <DropDown 
                    className='app-projections-dropdown'
                    display='name'
                    valueProperty='id'
                    selected={getCurrentModelView()?.id} 
                    label={translate('view')}
                    items={getCurrentModelViews().map(({ id, name }) => { return { id, name } })}
                    onChange={({ value }) => {                        
                        setModelViewId(value);                        
                    }}
                />
                {
                    getCurrentModelView()?.type === constants.projections.views.disaggregateForecastTradeArea ||
                    getCurrentModelView()?.type === constants.projections.views.disaggregateCannibalizationPoint ||
                    getCurrentModelView()?.type === constants.projections.views.disaggregateRecapturePoint ? 
                    <>
                        <DropDown 
                            className='app-projections-dropdown'
                            display='name'
                            valueProperty='id'
                            selected={getCurrentModelDetail()?.id} 
                            label={modelDetailsTitle}
                            items={getCurrentModelDetails().map(({ id, name }) => { return { id, name } })}
                            onChange={({ value }) => {
                                setModelDetailsId(value);
                            }}
                        />
                        
                    </> : null
                }
                {
                    getCurrentModelView()?.type === constants.projections.views.disaggregateRecapturePoint ?
                        <Button theme='primary' size='tiny' tooltip={translate('generate')} icon={icons.file} onClick={async ()=>{
                            setGenerating(true);
                            await projections.generateRecapture({ projectionResults: projectionResults });
                            setGenerating(false);
                        }} />
                    : 
                    <>                        
                        {
                            getCurrentModelView()?.type === constants.projections.views.disaggregateForecastTradeArea ?
                            <>
                                <OptionSelector
                                    theme='small' 
                                    text={translate('sum')} 
                                    active={modelSumEnabled} 
                                    onClick={()=>{ 
                                        setModelSumEnabled(!modelSumEnabled);
                                    }}
                                />
                            </> : null
                        }
                        {
                            getCurrentModelView()?.type === constants.projections.views.disaggregateForecastTradeArea || 
                            getCurrentModelView()?.type === constants.projections.views.disaggregateForecastPoint ?
                            <>
                                <OptionSelector
                                    theme='small' 
                                    text={translate('overlay')} 
                                    active={modelOverlayEnabled} 
                                    onClick={()=>{ 
                                        setModelOverlayEnabled(!modelOverlayEnabled);
                                    }} 
                                />
                                <RadioButtonSet className='app-projections-selection-tools'>
                                {
                                    [
                                        { id: constants.selections.behaviors.single, icon: icons.crosshair, tooltip: translate('single') },
                                        { id: constants.selections.behaviors.contains, icon: icons.dotCircle, tooltip: translate('contains') },
                                        { id: constants.selections.behaviors.intersects, icon: icons.objectUngroup, tooltip: translate('intersects') },
                                        { id: constants.selections.behaviors.none, icon: icons.grab, tooltip: translate('pan') },
                                    ].map((o, i) => {
                                        return <Button 
                                            key={i} 
                                            theme='action' 
                                            size='small' 
                                            icon={o.icon} 
                                            tooltip={o.tooltip} 
                                            active={selectionTool === o.id} 
                                            onClick={()=>{
                                                
                                                setSelectionTool(o.id);
                                                setSelectionListenerId(helpers.emptyGuid());
                                                projections.stopSelection({ selectionListenerId: selectionListenerId });
        
                                                var currentModelResult = getCurrentModelResult();
                                                var currentElements = getCurrentModelElements();
        
                                                switch(o.id){
                                                    default:
                                                        projections.selectMultiple({
                                                            behavior: o.id,
                                                            modelResult: currentModelResult,
                                                            modelElements: currentElements,
                                                            onFinish: () => {
                                                                setSelectionTool(constants.selections.behaviors.none);
                                                            }
                                                        });
                                                        break;
                                                    case constants.selections.behaviors.single:
                                                        setSelectionListenerId(
                                                            projections.selectSingle({
                                                                modelResult: currentModelResult,
                                                                modelElements: currentElements
                                                            })
                                                        );
                                                        break;
                                                    case constants.selections.behaviors.none:
                                                        setSelectionListenerId(helpers.emptyGuid());
                                                        projections.stopSelection({ selectionListenerId: selectionListenerId });
                                                        break;
                                                }
                                            }} 
                                        />
                                    })
                                }
                                </RadioButtonSet>
                                {
                                    getCurrentModelViews().filter(x => x.type === constants.projections.views.disaggregateForecastPoint && x.layer.addFormId !== helpers.emptyGuid()).length > 0 ?
                                    <MenuButton 
                                        className='app-projections-toolbar-action app-projections-toolbar-action-dropdown'
                                        tooltip={translate('add_temporary_point')} 
                                        theme='action'
                                        active={temporarySiteListenerId !== helpers.emptyGuid()}
                                        icon={icons.add} 
                                        items={getCurrentModelViews().filter(x => x.type === constants.projections.views.disaggregateForecastPoint && x.layer.addFormId !== helpers.emptyGuid()).map(view => { 
                                            return {
                                                text: view.name,
                                                onClick: ()=>{
                                                    setTemporarySiteListenerId(
                                                        projections.selectTemporarySite({
                                                            onAdd: (e) =>{
                                                                setTemporarySiteListenerId(helpers.emptyGuid());
                                                                setTemporarySiteForm(
                                                                    <ProjectionTemporaryAddForm 
                                                                        form={projectionResults.addForms.find(x => x.Key === view.layer.addFormId).Value}
                                                                        entity={{
                                                                            location: e.location
                                                                        }}
                                                                        onGenerate={({ updateForm })=>{
                                                                            setTemporarySiteForm(null);
                                                                            projections.addTemporarySite({
                                                                                subjectSiteLocation: projectionResults.location,
                                                                                modelElements: getCurrentModelElements(),
                                                                                view: view,
                                                                                updateForm: updateForm
                                                                            });
                                                                        }}
                                                                    />
                                                                );
                                                            }
                                                        })
                                                    );
                                                }
                                            }; 
                                        })} 
                                    />
                                    : null
                                }                                
                                <ConfirmButton 
                                    className='app-projections-toolbar-action'
                                    theme='action' 
                                    size='small'
                                    icon={icons.rectanglesMixed} 
                                    tooltip={translate('redefine')} 
                                    clearText={translate('redefine')}
                                    onConfirm={async () =>
                                    {
                                        setGenerating(true);
                                        
                                        await projections.redefineDisaggregateForecastTradeArea({
                                            location: projectionResults.location,
                                            modelResult: getCurrentModelResult(),
                                            modelElements: getCurrentModelElements()
                                        });
        
                                        setGenerating(false);
                                    }} 
                                />
                                <Button 
                                    id="app-projections-save-shape"
                                    className='app-projections-toolbar-action'
                                    theme='action' 
                                    size='small' 
                                    tooltip={translate('save_trade_area_to_site')} 
                                    icon={icons.save} 
                                />
                            </> : null                    
                        }
                        {
                            getCurrentModelView()?.type === constants.projections.views.disaggregateForecastPoint ||
                            getCurrentModelView()?.type === constants.projections.views.disaggregateCannibalizationPoint ? 
                            <>
                                <Button 
                                    id='app-projections-link-sites'
                                    className='app-projections-toolbar-action'
                                    theme='action' 
                                    size='small' 
                                    tooltip={translate('link_sites_across_models')} 
                                    icon={icons.link} 
                                    onClick={async ()=>{}} 
                                />
                            </> : null
                        }                
                        {
                            projectionResults.layer.editFormId !== helpers.emptyGuid() ? 
                                <Button 
                                    id='app-projections-edit'
                                    className='app-projections-toolbar-action'
                                    theme='action' 
                                    size='small' 
                                    tooltip={translate('site_characteristics')} 
                                    icon={icons.edit} 
                                />
                            : null
                        }
                        {
                            projectionResults.tradeAreas.filter(x => x.tradeArea.type === constants.tradeAreas.types.userDrawn).length > 0 ?
                            <>
                                <Button 
                                    id='app-projections-shape-draw-polygon'
                                    className='app-projections-toolbar-action'
                                    theme='action' 
                                    size='small' 
                                    tooltip={translate('polygon')} 
                                    icon={icons.hexagon} 
                                />
                                {
                                    getCurrentModelResult().model.type === constants.projections.models.disaggregateDeliveryCannibalization ?
                                        <Button 
                                            className='app-projections-toolbar-action'
                                            theme='action' 
                                            size='small'
                                            tooltip={translate('calculate')} 
                                            icon={icons.calculator} 
                                            onClick={async ()=>{
                                                setGenerating(true);
                                                await projections.calculateDeliveryAreaCannibalization({
                                                    projectionResults: projectionResults,
                                                    modelResult: getCurrentModelResult(),
                                                    modelElements: getCurrentModelElements(),
                                                    viewId: getCurrentModelView()?.id
                                                });
                                                setGenerating(false);
                                                setElementClasses('app-projections-content app-projections-polygon-generated');
                                            }} 
                                        /> : null
                                }
                            </>
                            : null
                        } 
                        <Button 
                            id='app-projections-execute' 
                            className='app-projections-toolbar-execute'
                            theme='primary' 
                            size='tiny' 
                            tooltip={translate('execute')} 
                            icon={icons.check} 
                        />
                        <MenuButton 
                            className='app-projections-toolbar-execute-load'
                            tooltip={translate('load_forecast_results')} 
                            theme='primary'
                            disabled={completedForecasts.length === 0}                            
                            items={completedForecasts}
                        />
                        {
                            userPreferences.IsTASAdmin ? 
                            <Button 
                                className='app-projections-toolbar-action'
                                theme='action' 
                                size='small' 
                                tooltip={translate('debug')} 
                                icon={icons.bug}
                                onClick={async () => {
                                    setGenerating(true);
                                    setDebugJson(await projections.debug({ projectionResults: projectionResults }));
                                    setGenerating(false);
                                }} 
                            /> : null
                        } 
                    </>
                }                                
            </div>
            <Loader theme='generate' loaded={!generating} />
            <div className='app-projections-scenarios'>                
                <Button theme='action' icon={icons.folderOpen} tooltip={translate('open_scenario')} onClick={()=>{setScenariosActive(true)}} />
                <Button id='app-projections-scenario' theme='action' icon={icons.save} tooltip={translate('save_scenario')} />
                <Button theme='action' icon={icons.x} tooltip={translate('close_scenario')} disabled={currentScenario === null} onClick={()=>{
                    setCurrentScenario(null);
                    setSaveScenarioName(translate('new_scenario'));
                }} />
                <div className='app-projections-scenario-name'>
                    {currentScenario === null ? `${translate('unsaved_scenario')}*` : currentScenario.Name }
                </div>
            </div>
            {                
                projectionElements.map((model) => {
                    return model.views.map(view => {
                        return <Hideable key={view.id}  className={elementClasses} hide={view.id !== getCurrentModelView()?.id}>
                            {view.grid.element}
                        </Hideable> 
                    });
                })
            }            
            {
                scenariosActive ?
                <ModalCard className='app-modal-card-default' open={true}>
                    <PointScenarios 
                        projection={projectionResults} 
                        onLoad={({scenario, data})=>{
                            
                            onClose();

                            projections.loadScenario({
                                projectionResults: data,
                                jobName: jobName,
                                scenario: scenario,
                                orginalParameters: orginalParameters
                            });                          

                        }} 
                        close={()=>{setScenariosActive(false);}} />
                </ModalCard> : ''
            }
        </div>
    </>
}