// React imports
import { useState, useEffect } from 'react'


// App imports
import { Button } from '../../../base/button/button';
import { icons } from '../../../base/icon/icon';
import { translate } from '../../../../utils/translation';
import { TextBox } from '../../../base/textBox/textBox';
import { TextArea } from '../../../base/textArea/textArea';
import { multimedia as multimediaModule } from '../../../../modules/multimedia';
import { TagBoxDropDown } from '../../../base/dropDown/tagBoxDropDown';
import smartTags from '../../../../modules/smartTags';
import { constants } from '../../../../utils/constants';
import { Loader } from '../../../base/loader/loader';

const _ = require("lodash");

export var saveMap;

export function DetailsEdit({entity,media,onClose}) {
    const [loaded, setLoaded] = useState(false);
    const [updatedMedia, setUpdatedMedia] = useState(null);
    const [areTagsUpdated, setAreTagsUpdated] = useState(false);
    const [placeholders, setPlaceholders] = useState([]);
    const [hashtags, setHashtags] = useState([]);
    const [selectedPlaceholders, setSelectedPlaceholders] = useState([]);
    const [selectedHashtags, setSelectedHashtags] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoaded(false);

            var tags = await smartTags.GetAvailableTags({
                id: media.id, 
                parentObjectId: entity.id, 
                sourceObjectId: entity.layer.metaData.serviceAttributes.DataSourceId, 
                sourceObjectType: constants.tagSourceObjectType.dataSource, 
                type: constants.tagObjectType.multimedia
            });

            setPlaceholders(tags.availablePlaceholders);
            setSelectedPlaceholders(tags.assignedPlaceholders);
            setHashtags(tags.availableHashtags);
            setSelectedHashtags(tags.assignedHashtags);

            setLoaded(true);
        };

        setUpdatedMedia(media);
        
        if (media && entity.layer.metaData.serviceAttributes.DataSourceId)        
            fetchData();       
        else
            setLoaded(true);
        
    }, [entity, media]);

    const onSave = async (o) =>{
        const updates = {
            id: updatedMedia.id,
            name: updatedMedia.name,
            description: updatedMedia.description         
        }        
        await multimediaModule.updateMultimedia(updates);

        if(areTagsUpdated){
            await smartTags.UpdateTags({
                id: updatedMedia.id,
                parentObjectId: entity.id, 
                sourceObjectId: entity.layer.metaData.serviceAttributes.DataSourceId, 
                sourceObjectType: constants.tagSourceObjectType.dataSource, 
                type: constants.tagObjectType.multimedia,
                tags: [...selectedPlaceholders, ...selectedHashtags]
            });
        };

        onClose();
    }    

    function close() {
        onClose();
    };

    return <>
    { !updatedMedia ? '' :
        <>
            <Loader loaded={loaded} />
            <div className='app-multimedia-details-edit'>
                <div className='app-multimedia-details-edit-actions-rows app-multimedia-header'>
                    <Button className='app-multimedia-details-edit-button' theme='secondary' tooltip={translate('close')} size='small' icon={icons.arrowTurnDownLeft} onClick={()=>{close() }} />	                    
                    <Button className='app-multimedia-details-edit-button' theme='primary' tooltip={translate('save')} size='small' icon={icons.save} onClick={()=>{onSave() }} />	
                </div>
                <div className='app-multimedia-details-edit-body'>
                    <TextBox height='47px' label={translate('name')} value={updatedMedia.name} onChange={(o) => { setUpdatedMedia({ ...updatedMedia, name: o.value }); }}  /> 
                    <TextArea minHeight={150} maxHeight={150} label={translate('description')} value={updatedMedia.description} onChange={(o) => { setUpdatedMedia({ ...updatedMedia, description: o.value }); }}  />                                                                        
                    <TagBoxDropDown label={translate('hashtag')} values={selectedHashtags} items={hashtags} keyProperty='id' valueProperty='name' onChange={(o) => {setSelectedHashtags(o); setAreTagsUpdated(true)}}/>
                    <TagBoxDropDown label={translate('placeholder')} values={selectedPlaceholders} items={placeholders} keyProperty='id' valueProperty='name' onChange={(o) => {setSelectedPlaceholders(o); setAreTagsUpdated(true)}}/>
                </div>                  
            </div>
        </>
    }
    </>
}