// React imports
import { useState, useEffect, useRef } from 'react';

// 3rd party
import FileUploader from 'devextreme-react/file-uploader';

// App imports
import { translate } from '../../../../utils/translation';
import { Description } from '../../../base/description/description';
import { Button } from '../../../base/button/button';
import { icons } from '../../../base/icon/icon';
import { EditPhotoUpload } from '../editPhotoUpload/editPhotoUpload';
import { Hideable } from '../../../base/hideable/hideable';
import { photos as photosModule } from '../../../../modules/photos';
import { EditPhoto } from '../editPhoto/editPhoto';
import { legacyEndpoints } from '../../../../services/legacyEndpoints';
import { helpers } from '../../../../utils/helpers';
import { upload } from '../../../../modules/upload';
import smartTags from '../../../../modules/smartTags';
import { constants } from '../../../../utils/constants';

const _ = require("lodash")

export function UploadPhotos({className,entity,photoCount,onClose,useType,maxNumberOfFiles,allowedExtensions,instructions=''}){  //todo useType temp solution
    const [uploadQueueId, setUploadQueueId] = useState(crypto.randomUUID())
    const [photos, setPhotos] = useState([])
    const sequence = useRef(0);
    const [hideEditPhoto, setHideEditPhoto] = useState([true]);
    const [editPhoto, setEditPhoto] = useState(null);

    const dropzoneExternal = useRef(null);

    const useTypes = {
        photo: 0,
        multimedia: 1,
        geoFeed: 2
    }

    useEffect(()=>{
        dropzoneExternal.current = "dropzone-external" + helpers.newGuid();
    },[]);

    const closeUpload = (o) =>{ 
        sequence.current = 0;
        setUploadQueueId(crypto.randomUUID());
        setPhotos([]);      
        onClose(o);
    }; 

    const back = () =>{ 
        photosModule.clearUploadQueue({
          uploadQueueId: uploadQueueId,
        });         

        closeUpload();
    }; 

    const save = async () =>{         
        if ((photoCount + photos.length) > maxNumberOfFiles) {
            alert(translate('maximum_number_of_photos'));
            return false;
        }

        var data;

        if (useType === useTypes.multimedia) {
            data = [];
            var additionalParams = [];
            additionalParams.push({ Key: "ManagerType", Value: 0 }); //todo fix

            // if (dataId != null)
            //     additionalParams.push({ Key: "OriginalFileId", Value: dataId });
            
            data.push({ Key: "additionalParams", Value: additionalParams });

            data.push(
                ...photos.map((x) => { 
                    return {
                        Key: x.id, 
                        Value: [{Key:'name', Value: x.title}, {Key:'description', Value: x.description}]
                    }
                  })
            );
        } else if (useType === useTypes.photo) {
            data = photos.map((x) => { 
                return {
                    Key: x.id, 
                    Value: [{Key:'date', Value: x.date}, {Key:'description', Value: x.description}, {Key:'title', Value: x.title}]
                }
              }) 
        } else if (useType === useTypes.geoFeed) {
            data = [];
        }             

        const result = await photosModule.publish({
            uploadQueueId: uploadQueueId,
            data: data,               
        });

        _.forEach(photos, (photo) => {
            if (_.isArray(photo.tags) && photo.tags.length > 0) {
                const uploadId = result.items.find(x => x.queueId === photo.id).resultId;

                smartTags.UpdateTags({
                    id: uploadId,
                    parentObjectId: entity.id, 
                    sourceObjectId: entity.layer.metaData.serviceAttributes.DataSourceId, 
                    sourceObjectType: constants.tagSourceObjectType.dataSource, 
                    type: useType === useTypes.photo ? constants.tagObjectType.photo : constants.tagObjectType.multimedia,
                    tags: photo.tags
                });
            }
        });

        closeUpload(result);

        return;
    }; 

    const uploadFileChunk = async (file, type) =>{
        var fileId = crypto.randomUUID();
        sequence.current = sequence.current + 1;

        await upload.uploadFileChunk(file, {
            uploadQueueId: uploadQueueId,
            fileId: fileId
        });  

        return fileId;
    };

    const uploadFile = async (file) =>{ 
        var regExpresson = new RegExp(".(" + allowedExtensions.join("|") + ")$", "i");
        if (!(regExpresson).exec(file.name)) {
            alert(`${translate('file_type_not_allowed')}: .${file.name.split(".")[1]}`);
            return false;
        }

        if (file.size > 10000000){
            alert(translate('file_size_too_large'));
            return false;
        }      

        var fileId = await uploadFileChunk(file, file.type);
        
        var queueId;
        if (useType === 1) {
            queueId = await photosModule.addMultimediaFileToQueueFromTempDirectory({
                    uploadQueueId: uploadQueueId,  
                    originalFileId: null,//o.originalFileId, implement if reupload is added
                    fileId: fileId,
                    sourceId: entity.id,
                    dataSourceId: entity.layer.id,
                    fileName: file.name,
                    managerType: 0//file.type,
            }); 
        } else if (useType === 0) {
            queueId = await photosModule.addPhotoToQueueFromTempDirectory({
                uploadQueueId: uploadQueueId,
                serviceId: entity.layer.id,
                pointId: entity.id,
                fileId: fileId,
                contentType: file.type,
                sequence: sequence.current
            }); 
        } else if (useType === 2) {
            queueId = await photosModule.addFileToQueueFromTempDirectory({
                uploadQueueId: uploadQueueId,
                fileId: fileId,
                contentType: 'application/rss+xml',
                fileName: file.name
            }); 
        }

        var photo = { 
                id: queueId, 
                name: file.name, 
                title: file.name,
                description:'',
                tags:'',
                size: file.size,
                type: file.type, 
                lastModifiedDate: file.lastModifiedDate };

        setPhotos(prevState => ([
        ...prevState, photo
        ]));          

        return fileId;
    };    

    const deletePhoto = async (id) =>{   
        setPhotos(photos.filter(x => x.id !== id)) 
    };     

    const showPhotoEditor = async (id) =>{   
        const photo = photos.find(x => x.id === id);

        setEditPhoto(photo);
        setHideEditPhoto(false);   
        toggleFullScreen();             
    };   
      
    const closePhotoEditor = () => {
        toggleFullScreen(); 
        setEditPhoto(null);
        setHideEditPhoto(true);    
    }; 

    const toggleFullScreen = ()=> {
        if (document.fullscreenElement) {
            document
              .exitFullscreen()
            //   .then(() => console.log("Document Exited from Full screen mode"))
              .catch((err) => console.error(err));
          } else {
            let elem = document.querySelector("#edit_photo_container");
            elem.requestFullscreen();            
          }
    }  
     
    const handleUploadedPhotoChange = (id, property, value) => {
        setPhotos(photos.map((photo) => {
                if (photo.id === id) {
                    return { ...photo, [property]: value }
                } else {
                    return photo;
                }
        }));      
    };

    const handleTagChange = (id, tags) => {
        setPhotos(photos.map((photo) => {
            if (photo.id === id) {
                return { ...photo, tags: tags }
            }
            return photo;
        }));
    };

    const saveEditedImage = async (image) => {
        var file = convertToFile(image, editPhoto.name);

        var fileId = await uploadFileChunk(file.file, file.type);

        photosModule.updateQueueFromTempDirectory({
            uploadQueueId: uploadQueueId,
            fileId: editPhoto.id, 
            tempFileId: fileId,
            contentType: editPhoto.type,
        }); 

        closePhotoEditor();
    };    

    const convertToFile = (image, name) => {
        var arr = image.split(','),
        mimeType = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
          while(n--){
              u8arr[n] = bstr.charCodeAt(n);
          }
  
        return { file: new File([u8arr], name, {type:mimeType}), type: mimeType};
    };    

    return (
        <div>  
            <Hideable hide={!hideEditPhoto} >        
                <div>
                    <Description className='app-photos-upload-photos-description' description={`${instructions} ${translate('allowed_file_types')} . ${allowedExtensions.join(", ")}`} />        
                    <div className='app-photos-upload-photos-actions'>
                        <Button theme='secondary' size='small' className={'fa-flip-vertical'} icon={icons.arrowTurnDownLeft} tooltip={translate('go_back')} onClick={() =>{ back(); }}/>
                        <Button theme='primary' size='small' disabled={photos.length === 0}  icon={icons.save} tooltip={translate('save')} onClick={() =>{ save(); }}/>
                    </div>  
                </div>          
                <div className='app-photos-upload-photos'>
                    <Hideable hide={photos.length <= 0} >
                        <div className='app-photos-upload-photos-body'>
                            <div className='app-photos-upload-photos-content'>
                            {             
                                photos?.map((photo, i) => 
                                {
                                        const isPhoto = photo.type.includes('image/'); //todo make component generic and not just for photos
                                        return <div className='app-photos-edit-photo-upload-photo-row' key={photo.id}>
                                                <EditPhotoUpload entity={entity} photo={photo} onDelete={deletePhoto} onPhotoEdit={showPhotoEditor} isPhoto={isPhoto} onHandleChange={handleUploadedPhotoChange} handleTagChange={handleTagChange} />
                                            </div>
                                    }
                                )
                            }
                            </div>
                        </div>                     
                    </Hideable>
                </div>
                { (photoCount + photos.length) < maxNumberOfFiles ?
                    <div>
                                <div className={dropzoneExternal.current}>    
                                    <div className={className}>
                                        <div className={photos?.length <= 0 ? "dropzone-text-full" : "dropzone-text"}>
                                            {translate('photo_upload_message')}
                                        </div>
                                    </div>                                        
                                </div>            
                                <FileUploader
                                        id={"file-uploader" + helpers.newGuid()}
                                        multiple={true}
                                        accept={'*'}
                                        uploadMode={'instantly'}
                                        uploadFile={uploadFile}
                                        dialogTrigger= {'.' + dropzoneExternal.current}
                                        dropZone={'.' + dropzoneExternal.current}
                                        visible={false}
                                />     
                    </div>   : ''
                }
            </Hideable>
            <Hideable hide={hideEditPhoto}>
                <div id='edit_photo_container'>
                    {!hideEditPhoto && <EditPhoto photo={editPhoto} imageURL={legacyEndpoints.handlers.getUploadUrl({ fileId: editPhoto?.id, task: 'get' })}  
                                                    onSaveEditedImage={saveEditedImage} onClose={closePhotoEditor} />
                    }
                </div>
            </Hideable>             
        </div>     
    );
}

