import { legacyEndpoints } from '../services/legacyEndpoints';
import { constants } from '../utils/constants';
import { translate } from '../utils/translation';

const _ = require("lodash");

const smartTags = {
    GetAvailableTags: async (o) =>{
        const tags = await legacyEndpoints.service({
            name: 'GetAvailableTags',
            parameters: {
                tagObjectRequest: {
                    id: o.id,
                    parentObjectId: o.parentObjectId,
                    sourceObjectId: o.sourceObjectId,
                    sourceObjectType: o.sourceObjectType,
                    type: o.type
                }
            }
        });

        if (tags) {
            const availablePlaceholders = [...(_.isArray(tags.available) ? tags.available.filter(x => x.type === constants.tagType.placeholder): []), ...(_.isArray(tags.assigned) ? tags.assigned.filter(x => x.type === constants.tagType.placeholder) : [])];
            const availableHashtags = [...(_.isArray(tags.available) ? tags.available.filter(x => x.type === constants.tagType.hashtag) : []), ...(_.isArray(tags.assigned) ? tags.assigned.filter(x => x.type === constants.tagType.hashtag) : [])];
            const assignedPlaceholders = _.isArray(tags.assigned) ? tags.assigned.filter(x => x.type === constants.tagType.placeholder).map(x => x.id) ?? [] : [];
            const assignedHashtags = _.isArray(tags.assigned) ? tags.assigned.filter(x => x.type === constants.tagType.hashtag).map(x => x.id) ?? [] : [];

            return {
                availablePlaceholders: availablePlaceholders,
                availableHashtags: availableHashtags,
                assignedPlaceholders: assignedPlaceholders,
                assignedHashtags: assignedHashtags
            };
        }
        else {
            return {
                availablePlaceholders: [],
                availableHashtags: [],
                assignedPlaceholders: [],
                assignedHashtags: []
            };
        }
    },
    UpdateTags: async (o) =>{
        return await legacyEndpoints.service({
            name: 'UpdateTags',
            parameters: {
                tagObjectRequest: {
                    id: o.id,
                    parentObjectId: o.parentObjectId,
                    sourceObjectId: o.sourceObjectId,
                    sourceObjectType: o.sourceObjectType,
                    type: o.type
                },
                tags: o.tags
            }
        });
    },
    GetMapBookTags: () =>{
        return [
            {
                key: constants.mapBooks.smartTags.pointName,
                value: translate('point_name')
            },
            {
                key: constants.mapBooks.smartTags.latitude,
                value: translate('latitude')
            },
            {
                key: constants.mapBooks.smartTags.longitude,
                value: translate('longitude')
            }
        ];
    }
}; 

export default smartTags;