// React imports
import { useState, useEffect, useMemo } from 'react';

// App imports
import { Button } from '../../../base/button/button';
import { DropDown } from '../../../base/dropDown/dropDown';
import { Bar } from '../../../base/bar/bar';
import { translate } from "../../../../utils/translation"
import { Icon, icons } from '../../../base/icon/icon';
import { constants } from '../../../../utils/constants';

const _ = require("lodash");

export function PrintMapOptions({ isMapBook, autoRefresh, mapOptions, onApply }) {

    const [_mapOptions, setMapOptions] = useState(null);

    const mapTypes = useMemo(() => [
        { key: constants.map.types.road, text: translate('road') }, 
        { key: constants.map.types.aerial, text: translate('aerial') }, 
        { key: constants.map.types.gray, text: translate('gray') }, 
        { key: constants.map.types.dark, text: translate('dark') },
        { key: constants.map.types.light, text: translate('light') }
    ], []);

    const zoomLevels = useMemo(() => [
        { key: 3, text: translate('zoom_level_3') }, 
        { key: 4, text: translate('zoom_level_4') }, 
        { key: 5, text: translate('zoom_level_5') }, 
        { key: 6, text: translate('zoom_level_6') }, 
        { key: 7, text: translate('zoom_level_7') }, 
        { key: 8, text: translate('zoom_level_8') }, 
        { key: 9, text: translate('zoom_level_9') }, 
        { key: 10, text: translate('zoom_level_10') }, 
        { key: 11, text: translate('zoom_level_11') }, 
        { key: 12, text: translate('zoom_level_12') }, 
        { key: 13, text: translate('zoom_level_13') }, 
        { key: 14, text: translate('zoom_level_14') }, 
        { key: 15, text: translate('zoom_level_15') }, 
        { key: 16, text: translate('zoom_level_16') }, 
        { key: 17, text: translate('zoom_level_17') }, 
        { key: 18, text: translate('zoom_level_18') }, 
        { key: 19, text: translate('zoom_level_19') },
        { key: 20, text: translate('zoom_level_20') }
    ], []);

    const orientations = useMemo(() => [
        { key: constants.print.orientation.landscape, text: translate('landscape') }, 
        { key: constants.print.orientation.portrait, text: translate('portrait') }
    ], []);

    const legendBehaviors = useMemo(() => [
        { key: constants.print.legendBehavior.autofit, text: translate('auto_fit') }, 
        { key: constants.print.legendBehavior.wrap, text: translate('wrap') },
        { key: constants.print.legendBehavior.separate, text: translate('separate_page') }
    ], []);

    const legendLocations = useMemo(() => [
        { key: constants.print.legendLocation.none, text: translate('none') }, 
        { key: constants.print.legendLocation.topLeft, text: translate('upper_left') }, 
        { key: constants.print.legendLocation.topRight, text: translate('upper_right') },
        { key: constants.print.legendLocation.bottomLeft, text: translate('lower_left') },
        { key: constants.print.legendLocation.bottomRight, text: translate('lower_right') }
    ], []);

    useEffect(() =>{

        if (!_.isObject(mapOptions))
            return;

        setMapOptions(_.cloneDeep(mapOptions));

    }, [mapOptions]);

    return <>
        { _mapOptions === null ? '' :
        <div className='app-projects-print-cols'>
            <div className='app-projects-print-rows'>
                <Button className='app-projects-print-apply-button app-projects-print-align-right' theme='secondary' tooltip={translate('reset')} size='small' icon={icons.clockRotateLeft} disabled={autoRefresh} onClick={()=>{ setMapOptions(_.cloneDeep(mapOptions)); }} />
                <Button className='app-projects-print-apply-button' theme='primary' tooltip={translate('apply_changes')} size='small' icon={icons.check} disabled={autoRefresh} onClick={()=>{ onApply(_mapOptions); }} />
            </div>
            <div className='app-projects-print-rows app-projects-print-centered-rows'>
                <div className='app-projects-print-cols'>
                    <DropDown className={'app-projects-print-input'} items={mapTypes} label={translate('map_types')} width={'160px'} height={'40px'} 
                        display='text' valueProperty='key' selected={_mapOptions.mapType} 
                        onChange={(o) => { 
                            if (autoRefresh)
                                onApply({ ..._mapOptions, mapType: o.value });
                            else
                                setMapOptions({ ..._mapOptions, mapType: o.value }); 
                        }} 
                    />
                    { !isMapBook ? '' :
                        <DropDown className={'app-projects-print-input'} items={zoomLevels} label={translate('zoom_levels')} width={'160px'} height={'40px'} 
                            display='text' valueProperty='key' selected={_mapOptions.zoom} 
                            onChange={(o) => { 
                                if (autoRefresh)
                                    onApply({ ..._mapOptions, zoom: o.value });
                                else
                                    setMapOptions({ ..._mapOptions, zoom: o.value }); 
                            }} 
                        />
                    }
                </div>
                <div className='app-projects-print-zoom-cols'>
                    <div className='app-option-selector'>
                        {_mapOptions.showLabels === true && _mapOptions.mapType === constants.map.types.aerial ? 
                        <div className='app-option-selector-check'>
                            <Icon icon={icons.check} />
                        </div> : ''}
                        <Bar className='app-projects-print-zoom-bar'
                            icon={icons.tag}
                            tooltip={translate('toggle_labels')}
                            active={_mapOptions.showLabels === true && _mapOptions.mapType === constants.map.types.aerial} 
                            disabled={_mapOptions.mapType !== constants.map.types.aerial}
                            onClick={() => { 
                                if (autoRefresh)
                                    onApply({ ..._mapOptions, showLabels: !_mapOptions.showLabels });
                                else
                                    setMapOptions({ ..._mapOptions, showLabels: !_mapOptions.showLabels }); 
                            }} 
                        /> 
                    </div>
                    { !isMapBook ? '' :
                        <div className='app-option-selector'>
                            {_mapOptions.zoomToTradeAreas === true ? 
                            <div className='app-option-selector-check'>
                                <Icon icon={icons.check} />
                            </div> : ''}
                            <Bar className='app-projects-print-zoom-bar'
                                icon={icons.bullseyeArrow}
                                tooltip={translate('override_zoom')}
                                active={_mapOptions.zoomToTradeAreas === true} 
                                onClick={() => { 
                                    if (autoRefresh)
                                        onApply({ ..._mapOptions, zoomToTradeAreas: !_mapOptions.zoomToTradeAreas });
                                    else
                                        setMapOptions({ ..._mapOptions, zoomToTradeAreas: !_mapOptions.zoomToTradeAreas }); 
                                }} 
                            /> 
                        </div>
                    }
                </div>
                <div className='app-projects-print-cols'>
                    <DropDown className={'app-projects-print-input'} items={_mapOptions.printTemplateSizes} label={translate('page_size')} width={'160px'} height={'40px'} 
                        display='Label' valueProperty='PrintTemplateSizeGUID' selected={_mapOptions.printTemplateGUID} 
                        onChange={(o) => { 
                            if (autoRefresh)
                                onApply({ ..._mapOptions, printTemplateGUID: o.value });
                            else
                                setMapOptions({ ..._mapOptions, printTemplateGUID: o.value }); 
                        }} 
                    />
                    <DropDown className={'app-projects-print-input'} items={orientations} label={translate('page_orientation')} width={'160px'} height={'40px'} 
                        display='text' valueProperty='key' selected={_mapOptions.orientation} 
                        onChange={(o) => { 
                            if (autoRefresh)
                                onApply({ ..._mapOptions, orientation: o.value });
                            else
                                setMapOptions({ ..._mapOptions, orientation: o.value }); 
                        }} 
                    />
                </div>
                <div className='app-projects-print-cols app-projects-print-stretch'>
                    <DropDown className={'app-projects-print-input'} items={legendLocations} label={translate('legend_location')} width={'160px'} height={'40px'} 
                        display='text' valueProperty='key' selected={_mapOptions.legendLocation} 
                        onChange={(o) => { 
                            if (autoRefresh)
                                onApply({ ..._mapOptions, legendLocation: o.value });
                            else
                                setMapOptions({ ..._mapOptions, legendLocation: o.value }); 
                        }} 
                    />
                    <DropDown className={'app-projects-print-input'} items={legendBehaviors} label={translate('legend_behavior')} width={'160px'} height={'40px'} 
                        display='text' valueProperty='key' selected={_mapOptions.legendBehavior} 
                        onChange={(o) => { 
                            if (autoRefresh)
                                onApply({ ..._mapOptions, legendBehavior: o.value });
                            else
                                setMapOptions({ ..._mapOptions, legendBehavior: o.value }); 
                        }} 
                    />
                </div>
            </div>
        </div>
        }
    </>	
}