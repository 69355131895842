// Third party imports
import validator from 'validator';

// App imports
import { UserProfile } from './userProfile/userProfile';
import { UserMap } from './userMap/userMap';
import { UserSecurity } from './userSecurity/userSecurity';
import { UserSearch } from './userSearch/userSearch';
import { UserBulkDriveTime } from './userBulkDriveTime/userBulkDriveTime';
import { TabbedContent }  from '../../base/tabbedContent/tabbedContent';
import { Icon, icons } from '../../base/icon/icon';
import { translate } from "../../../utils/translation";
import { legacyEndpoints } from "../../../services/legacyEndpoints";
import { userPreferences } from '../app';

var _ = require("lodash");

export var userPreferencesHelpers;

export function UserPreferences({userImage, onNameChange, onBack }) {    

    userPreferencesHelpers = {
        saveUserPreferences: (o) => {

            var valid = true;

            o.columns.forEach((column, i) => {
                if (column === 'GPSRefresh')
                    if (validator.isNumeric(o.values[i], { no_symbols: true }))
                        o.values[i] = parseInt(o.values[i]);
                    else if (_.isFunction(o.callback)) {
                        valid = false;
                        o.callback({ success: false, error: translate('user_preferences_error_gps_refresh') });
                    }
            });

            if (valid)
                legacyEndpoints.service({
                    name: 'SaveUserPreferences',
                    parameters: {
                        Columns: o.columns,
                        Values: o.values
                    },
                    success: function(r) {
                        if (o.updatePreferences)
                            o.columns.forEach((column, i) => {
                                if (userPreferences.hasOwnProperty(column))
                                    userPreferences[column] = o.values.length > i ? o.values[i] : userPreferences[column];
                            });
        
                        if (_.isFunction(o.callback)) o.callback({ success: true });
                    },
                    error: function(e) {
                        if (_.isFunction(o.callback)) o.callback({ success: false, error: e });
                    }
                });
        },
        changePassword: async ({ password, newPassword }) => {
            return await legacyEndpoints.service({
                name: 'ChangePassword',
                parameters: {
                    password: legacyEndpoints.encrypt(password),
                    newPassword: legacyEndpoints.encrypt(newPassword)
                }});
        },
        validatePassword: async ({ password }) => {
            return await legacyEndpoints.service({
                name: 'ValidatePassword',
                parameters: {
                    password: legacyEndpoints.encrypt(password)
                }});
        },
    };

    var items = [
		{ text: translate('user_profile'), icon: icons.user, loaded: true, children: <UserProfile userImage={userImage} onNameChange={(o)=>{ if (onNameChange) onNameChange(o); }} /> },
		{ text: translate('map'), icon: icons.map, loaded: true, children: <UserMap /> },
		{ text: translate('security'), icon: icons.lock, loaded: true, children: <UserSecurity />, disabled: userPreferences.IsSSO },
		//{ text: translate('search'), icon: icons.magnifyingGlass, loaded: true, children: <UserSearch /> },
		{ text: translate('bulk_drive_time'), icon: icons.carMirrors, loaded: true, children: <UserBulkDriveTime /> }
	];

    return <div className='app-user-preferences'>
        <div className='app-user-preferences-title'>
            {translate("preferences")}
        </div>
        <div className='app-user-preferences-actions'>
            <div className='app-user-preferences-back'>
                <Icon className='app-large-panel-back-icon' icon={icons.caret}/>
                <span className='app-large-panel-back-link' onClick={() => { if (onBack) onBack(); }}>{translate('go_back')}</span>
            </div>
        </div>
        <TabbedContent items={items} />
    </div>
}